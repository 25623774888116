import React from 'react'
import Footer from './Footer'
import {Link} from  'react-router-dom'
import { comingsoon } from '../assets/images'

export const ComingSoon = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
    <div className="flex flex-col items-center bg-gray-100 my-20">
    {/* <img src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5" alt="Logo" class="object-cover w-50 h-50 mb-8 rounded-full"/> */}
    <img src={comingsoon} alt="Logo" class="object-cover w-40 h-40 mb-8 rounded-full"/>
    <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
    <p className="text-lg mb-8 px-4 md:px-0">We're working hard to bring you something awesome. Stay tuned!</p>
    <div className="flex justify-center items-center space-x-4">
        {/* <a href="#" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md">Learn More</a> */}
        {/* <a href="#" className="bg-blue-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md">Do you have any suggestions?, Contact Us</a> */}
        <Link to="/contact" className="bg-blue-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md">Do you have any suggestions?, Contact Us</Link>
    </div>
</div>
    <Footer/>
</div>
  )
}
