import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as sessionActions from "./store/session";
import { Route, Routes } from "react-router-dom";
import Navigation from './components/Navigation.jsx';
import {LoginPage} from './components/Auth/LoginPage.jsx';
import {SignupPage} from './components/Auth/SignupPage.jsx';
import Home from './components/Home/Home.jsx';
import { Catagories } from "./components/Catagories.jsx";
import { Dashboard } from "./components/Admin/Dashboard.jsx";
import AddCatagory from "./components/Admin/AddCatagory.jsx";
import { BusinessList } from "./components/Businesses/BusinessList.jsx";
import Vendors from './components/Vendors/Vendors.jsx'
import { AddVendor } from "./components/Vendors/AddVendor.jsx";
import {VendorDetails} from "./components/Vendors/VendorDetails.jsx";
import {AddReview} from "./components/ProfilePage/Review/AddReview.jsx"
import Services from "./components/Services/Services.jsx";
import {Events} from "./components/Events/Events.jsx";
import {EventDetails} from "./components/Events/EventDetails.jsx";
import {AddEvent} from "./components/Events/AddEvent.jsx";
import { BusinessCatagory } from "./components/Businesses/BusinessCatagory.jsx";
import {Sale} from "./components/Sale/Sale.jsx"
import { SaleDetails } from "./components/Sale/SaleDetails.jsx";
import {AddSale} from "./components/Sale/AddSale.jsx";
import { BusinessDetails } from "./components/Businesses/BusinessDetails.jsx";
import { ProfilePage } from "./components/ProfilePage/ProfilePage.jsx";
import { MyReviews } from "./components/ProfilePage/MyReviews.jsx";
import { MyEvents } from "./components/ProfilePage/MyEvents.jsx";
import { EditEvent } from "./components/ProfilePage/EditEvent.jsx";
import { ForgotPass } from "./components/Auth/ForgotPass.jsx";
import { Otp } from "./components/Auth/Otp.jsx";
import { ResetPassword } from "./components/Auth/ResetPassword.jsx";
import { ChangePasswordSuccess } from "./components/Auth/ChangePasswordSuccess.jsx";
import {NewCatagoryEmail} from "./components/EmailResponse/NewCatagoryEmail.jsx"
import { ComingSoon } from "./components/ComingSoon.jsx";
import { Thankyou } from "./components/Contact/Thankyou.jsx";
import { Contact } from "./components/Contact/Contact.jsx";
import { TermsOfUse } from "./components/Legal/TermsOfUse.jsx";
import { PrivacyPolicy } from "./components/Legal/PrivacyPolicy.jsx";
import { AboutUs } from "./components/Legal/AboutUs.jsx";
import { Protected } from "./components/Protected.jsx";
import { ViewCatagory } from "./components/Admin/ViewCatagory.jsx";
import { Userlist } from "./components/Admin/Userlist.jsx";
import { AddSkills } from "./components/Vendors/AddSkills.jsx";
import { ViewVendors } from "./components/Admin/ViewVendors.jsx";
import {VerifySignup} from "./components/Auth/VerifySignup.jsx";
import { MyAds } from "./components/ProfilePage/MyAds.jsx";
import { Productdetails } from "./components/ProfilePage/Productdetails.jsx";
import { Cart } from "./components/ProfilePage/Cart.jsx";
import { SubServices } from "./components/Services/SubServices.jsx";
import { ViewVendors1 } from "./components/Admin/ViewVendors1.jsx";
import { EditReviewSuccessPage } from "./components/ProfilePage/Review/EditReviewSuccessPage.jsx";
import { EditVendor } from "./components/Admin/EditVendor.jsx";
import { DisplayNameChangeSuccess } from "./components/ProfilePage/DisplayNameChangeSuccess.jsx";
import { ProfileImageChangeSuccess } from "./components/ProfilePage/ProfileImageChangeSuccess.jsx";
import { Missing } from "./components/Missing.jsx";
import { Pending } from "./components/Admin/Pending.jsx";
import { Inactive } from "./components/Admin/Inactive.jsx";
import { Search } from "./components/Search.jsx";
// import DateRangePicker from "./components/Test.jsx";


const userRoles = {
  admin:'admin',
  owner:'owner',
  tenant:'tenant'
}

const userStatus = {
  active:'active',
  inactive:'inactive',
  pending:'pending'
}

function App() {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    dispatch(sessionActions.restoreUser()).then(() => setIsLoaded(true));
  }, [dispatch]);


    
  return (
    <>
    <Navigation isLoaded={isLoaded} />
    {isLoaded && (
      <div>
    <Routes>


    {/* <Route path='/' element={<Protected Component={Home} Type={'admin'|| 'owner' || 'tenant'}/>}></Route> */}
    <Route path ='/' element = {<Home/>}></Route>
   <Route path='/catagories' element={<Catagories/>}></Route>
   <Route path ='/login' element={<LoginPage/>}></Route>
   <Route path ='/signup' element={<SignupPage/>}></Route> 
   <Route path='/services' element={<Services/>}></Route>
   <Route path='/services/subservices/:id' element={<SubServices/>}></Route>
   <Route path='/businesscatagory' element={<ComingSoon/>}></Route>
   <Route path='/businesslist' element={<ComingSoon/>}></Route>
    <Route path='/vendordetails/:id' element={<VendorDetails/>}></Route>
   <Route path='/vendors/:Id' element={<Vendors/>}></Route>
    <Route path='/contact' element={<Contact/>}></Route>
    <Route path='/search' element={<Search/>}></Route>
    <Route path='/contact/thankyou' element={<Thankyou/>}></Route>
    <Route path='/legal/aboutus' element={<AboutUs/>}></Route>
    <Route path='/privacypolicy' element={<PrivacyPolicy/>}></Route>
   <Route path='/events' element={<ComingSoon/>}></Route>
    <Route path='/sale' element={<ComingSoon/>}></Route>
    <Route path='/termsandconditions' element={<TermsOfUse/>}></Route>
    <Route path='/comingsoon' element={<ComingSoon/>}></Route>
    <Route path='/forgotpassword' element={<ForgotPass/>}></Route>
    <Route path='/resetpassword' element={<ResetPassword/>}></Route>
    <Route path='/otp' element={<Otp/>}></Route>
    <Route path='/changepasswordsucess' element ={<ChangePasswordSuccess/>}></Route>
    <Route path='*' element={<Missing/>}></Route>

    {/* <Route path='/test' element={<DateRangePicker/>}></Route> */}

    {/* <Route path='/vendordetails/:id' element={<VendorDetails/>}></Route> */}
    {/* <Route path='/vendordetails/:id' element={<Protected Component={VendorDetails} Type={'admin'}/>}></Route> */}
   
   {/* <Route path='/vendordetails/:id' element={<Protected Component={VendorDetails} Type={'tenant'}/>}></Route> */}

    <Route path='/catagories/newcatagoryemail' element={<Protected Component={NewCatagoryEmail} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/myevents/edit/:id' element={<Protected Component={EditEvent} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/businessdetails/:id' element={<Protected Component={BusinessDetails} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/forsale/addsale' element={<Protected Component={AddSale} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/forsale/saledetails/:id' element={<Protected Component={SaleDetails} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/events/addevents/:id' element={<Protected Component={AddEvent} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/events/eventdetails/:id' element={<Protected Component={EventDetails} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/profilepage/myevents' element={<Protected Component={MyEvents} Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/>}></Route>
    <Route path='/profilepage/myads' element={<Protected Component={MyAds}Type={'admin'|| 'owner' || 'tenant'} Verified={'active'}/> }></Route>


    <Route path ='verifysignup' element={<Protected Component={VerifySignup} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.pending]}/>}></Route>


   {/* loged in  users */}
    <Route path='/profilepage/myreviews' element={<Protected Component={MyReviews} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/profilepage/myreviews1' element={<Protected Component={MyReviews} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='addskills' element={<Protected Component={AddSkills} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/addvendor/:id' element={<Protected Component={AddVendor} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/addreview/:id' element={<Protected Component={AddReview} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    {/* <Route path='/vendordetails/:id' element={<Protected Component={VendorDetails} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route> */}
    <Route path='/profilepage' element={<Protected Component={ProfilePage}  Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active, userStatus.pending]}/>}></Route>
    <Route path='/profilepage/productdetails' element={<Protected Component={Productdetails}  Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/profilepage/cart/:id' element={<Protected Component={Cart} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/profilepage/editreviewsuccess/:id' element={<Protected Component={EditReviewSuccessPage} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route> 
    <Route path='/profilepage/editdisplaysuccess' element={<Protected Component={ DisplayNameChangeSuccess}Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/profilepage/editprofilesuccess' element={<Protected Component={ ProfileImageChangeSuccess}Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.active]}/>}></Route>
    <Route path='/pending' element={<Protected Component={Pending} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.pending]}/>}></Route>
    <Route path='/inactive' element={<Protected Component={Inactive} Type={[userRoles.admin, userRoles.owner, userRoles.tenant]} Verified={[userStatus.inactive]}/>}></Route>
    
   
    {/* admin only */}
   <Route path='/admin/dashboard' element={<Protected Component={Dashboard} Type={[userRoles.admin]} Verified={[userStatus.active]}/>}></Route>
   <Route path='/admin/addcatagory' element={<Protected Component={AddCatagory} Type={[userRoles.admin]} Verified={[userStatus.active]}/>}></Route>
   <Route path='/admin/viewcatagory' element={<Protected Component={ViewCatagory} Type={[userRoles.admin]} Verified={[userStatus.active]} />}></Route>
   <Route path='/admin/viewvendors' element={<Protected Component={ViewVendors} Type={[userRoles.admin]} Verified={[userStatus.active]}/>}></Route>
   <Route path='/admin/viewvendors1' element={<Protected Component={ViewVendors1} Type={[userRoles.admin]} Verified={[userStatus.active]}/>}></Route>
   <Route path='/admin/userlist' element={<Protected Component={Userlist} Type={[userRoles.admin]} Verified={[userStatus.active]}/>}></Route>
   <Route path='/admin/vendor/:id' element={<Protected Component={EditVendor} Type={[userRoles.admin]} Verified={[userStatus.active]}/>}></Route>

    </Routes>
    </div>
  )
}
  </>
  )
}

export default App;
