import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Footer from '../../Footer'
import { fetchAllReviews } from '../../../store/review'

export const EditReviewSuccessPage = () => {
    const {id} = useParams()
    const myReview = useSelector(state => state.review[id])
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(fetchAllReviews())
      }, [dispatch])

    function datefield(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date1 = date.split('T')[0]
        const date2 = date1.split('-')

        return date2[2] + " " + months[date[1]] + " " + date2[0]
        // return date.split('T')[0]

    }

    function starRating(star) {
        if (star === 1) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 2) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 3) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 4) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 5) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i></div>)
        }
    }

    if(!myReview) return null;
   
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-between">
            <div className="max-container1">
                <div class="py-12 px-4 md:px-6 2xl:px-0 2xl:container 2xl:mx-auto flex justify-center items-center">
                    <div class="flex flex-col justify-start items-start w-full space-y-8">
                        <div class="flex justify-start items-start">
                            <h1 class="text-2xl lg:text-3xl font-semibold leading-7 lg:leading-9 text-gray-800">Review was Successfully Updated</h1>
                        </div>
                                <div className="w-full border-b-2 pb-4">
                                    <div class="mt-6 flex justify-start items-center flex-row space-x-2.5 pb-5 "> 
                                        {/* <div>
                                 <img className="" src="https://myaaprojects.s3.us-east-2.amazonaws.com/profile-circle.png" alt="girl-avatar" width="38" height="38" />
                             </div> */}
                                         <div class="flex flex-col justify-start items-start space-y-2">
                                            <p  class="text-sm font-medium leading-none text-gray-800"> Review for {myReview.businesscontact && myReview.businesscontact}, {myReview.businessname && myReview.businessname} </p>
                                            <p class="text-sm leading-none text-gray-800"> Reviewed on {datefield(myReview.createdAt)}</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row justify-between w-full">

                                        <div class="flex flex-row justify-between items-start">

                                            <p class="text-xl md:text-xl font-medium leading-normal text-gray-800 break-all">{myReview.title}</p>

                                        </div>

                                        <div>{starRating(myReview.rating)}</div>
                                    </div>
                                    <div id="menu" class="md:block">
                                        <p class="mt-3 text-sm leading-normal text-gray-800 w-full md:w-9/12 xl:w-5/6 break-all">{myReview.review}</p>
                                        <div className="flex justify-start gap-3">
                                    <div>Return to My Reviews</div>
                             
                                    </div>
                                   </div>
                                </div>
                            
                             
                            </div>
                            
                          
                    </div>
                </div>
           
            <Footer />
        </div>
  )
}
