import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import { signup } from "../../store/session";
import ReCAPTCHA from 'react-google-recaptcha';
// import Footer from '../Footer'

export const SignupPage = () => {
    const dispatch = useDispatch();
  const sessionUser = useSelector((state) => state.session.user);
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [resident_code, setResident_code] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
//   const [validationErrors, setValidationErrors] = useState({})
  const [hasSubmitted, setHasSubmitted] = useState(false);

    const captchaRef = useRef(null);
    const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;

  useEffect(()=> {
    let newError = {};
    if (!(email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g))) newError.email = 'Not a valid email address'
    if(email.length > 50) newError.email = 'email must be less than 50 characters'
    if(password.length < 8) newError.password = 'password must be atleast 8 characters'
    if(password.length > 30) newError.password = 'password must be less than 30 characters'
    if(first_name.length > 20) newError.first_name = 'first name must be less than 20 characters'
    if(last_name.length > 20) newError.last_name = 'last name must be less than 20 characters'
    if(username.length > 30) newError.username = 'username must be less than 30 characters'
    if(!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) newError.password = 'password must contain one lowercase, one uppercase, one number, and one special character'

    setErrors(newError)
  }, [email, password, first_name, last_name, username])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const captchaValue = await captchaRef.current.executeAsync()
    captchaRef.current.reset()

    setHasSubmitted(true);
    if(Object.keys(errors).length > 0) return;
  
    if(password === confirmPassword) {
        return dispatch(signup({first_name, last_name, username, resident_code, email, password, captchaValue}))
        .catch(async (res) => {
            const data = await res.json();
            if (data && data.errors) {
              setErrors(data.errors)
            }
          });
       
    } else {
        setErrors({cnf:'Confirm Password must be the same as the Password'});
    }
  }

  if (sessionUser) return <Navigate to="/verifysignup" />;

//   

  return (
    <div className="sm:mt-26">
    <div className="py-16" >
        <div className="flex bg-white rounded-lg shadow-xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
            <div className="hidden lg:block lg:w-1/2 bg-cover bg-[url('https://source.unsplash.com/zvWxlTd3IP0')]">
            </div>
            <div className="w-full p-8 lg:w-1/2">
                <h2 className="text-2xl font-semibold text-gray-700 text-center">Signup</h2>
                <p className="text-xl text-gray-600 text-center">Welcome back!</p>
                
                <div className="mt-4 flex items-center justify-between">
                
                </div>
                <form onSubmit={handleSubmit}>
                <ul>
                       {errors.length > 0 && errors.map((error, idx)=> (
                            <li style={{color:'black', listStyle:"none"}} key={idx}>{error}</li>
                        ))}
                    </ul>
                    

                    <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2"><span>First Name </span><span className="text-coral-red">{hasSubmitted && errors.first_name && errors.first_name}</span></label>
                    <input 
                    value={first_name}
                    onChange={(e)=> setFirst_name(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2"><span>Last Name </span><span className="text-coral-red">{hasSubmitted && errors.last_name && errors.last_name}</span></label>
                    <input 
                    value={last_name}
                    onChange={(e)=> setLast_name(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2"><span>Username </span><span className="text-coral-red">{hasSubmitted && errors.username && errors.username}</span></label>
                    <input 
                    value={username}
                    onChange={(e)=> setUsername(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2"><span>Email Address</span><span className="text-coral-red"> {hasSubmitted && errors.email && errors.email}</span></label>
                    <input 
                    value={email}
                    onChange={(e)=> setEmail(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="email" />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2"><span>Resident Code</span><span className="text-coral-red"> {hasSubmitted && errors.resident_code && errors.resident_code}</span></label>
                    <input 
                    value={resident_code}
                    onChange={(e)=> setResident_code(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>
                <div className="mt-4">
                    <div className="flex justify-between">
                        <label className="block text-gray-700 text-sm font-bold mb-2"><span>Password</span><span className="text-coral-red"> {hasSubmitted && errors.password && errors.password}</span></label>
                    </div>
                    <input 
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" 
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    />
                </div>
                <div className="mt-4">
                    <div className="flex justify-between">
                        <label className="block text-gray-700 text-sm font-bold mb-2"><span>Confirm Password</span><span className="text-coral-red"> {hasSubmitted && errors.cnf && errors.cnf}</span></label>
                    </div>
                    <input 
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" 
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    />
                </div>
                <ReCAPTCHA
              className="recaptcha"
              sitekey={SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
                <div className="mt-8">
                    <button className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600" type="submit">Signup</button>
                </div>
                </form>
                <div className="mt-4 flex items-center justify-between">
                    <span className="border-b w-1/6 md:w-1/5"></span>
                    <Link to={'/login'}><a href="#" className="text-xs text-gray-500 uppercase text-center">Already have an account? Login</a></Link>
                    <span className="border-b w-1/6 md:w-1/5"></span>
                </div>
            </div>
        </div>
    </div>
    {/* <Footer/> */}
    </div>
  )
}
