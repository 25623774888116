import { csrfFetch } from "./csrf";

const VIEWUSERS = "userlist/VIEWUSER"
const EDITUSER = "userlist/EDITUSER"

// view users
const viewusers = payload => ({
    type: VIEWUSERS,
    payload
})

// update user
const edituser = payload => ({
    type: EDITUSER,
    payload
})

// thunk
// view users
export const getAllUsers = () => async dispatch => {
    const response = await fetch(`/api/userlist`);
    if (response.ok){
        const payload = await response.json();
        dispatch(viewusers(payload));
    }
}

export const fetchUpdateUserList = (updateuserform, userId) => async dispatch => {
    
    const response = await csrfFetch(`/api/userlist/update/${userId}`, {
     method:"PUT",
     headers: { "Content-Type": "application/json",
 },
     body: JSON.stringify(updateuserform)
    })
    if (response.ok) {
     const payload = await response.json()
     dispatch(edituser(payload))
    }
}

const initialState = {}

export default function userListReducer(state = initialState, action){
    switch(action.type){

        case VIEWUSERS:
            const allUsers = {}
            action.payload.forEach(ele => allUsers[ele.id] = ele)
            return {...state, ...allUsers}

        case EDITUSER:
            return {...state, [action.payload.id]: action.payload}    
           
        default: 
            return state;
    }
}