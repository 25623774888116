import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUpdateBusiness, getAllBusinesses } from "../../store/businesses";
import { getAllCatagories } from "../../store/catagories";
import { fetchAddBusinessCatagory, fetchDeleteBusinessCatagory, getAllBusiCats } from '../../store/businesscatagory';



export default function EditVendorModal({id, renderDispatch}) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const vendor_id = id

  const myVendor= useSelector((state) => state.business[vendor_id])
  const sessionUser = useSelector((state)=> state.session.user)
  let allCatagories = useSelector((state => Object.values(state.catagory)))
  let allBusinessCat = useSelector((state => Object.values(state.businessCatagory)))

  const filteredBusinessCat = allBusinessCat.filter(ele => ele.business_id == vendor_id)

  const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
 
  useEffect(()=> {
    dispatch(getAllBusinesses())
}, [dispatch])

useEffect(()=> {
  dispatch(getAllCatagories())
}, [dispatch])

useEffect(()=> {
  dispatch(getAllBusiCats())
}, [dispatch])
 
// const allCat = [];
// const myCat = [];
  // allCatagories && allCatagories.forEach(ele => allCat.push(ele.catagory))
  let allCat = allCatagories.map(({catagory}) => catagory)

  // myVendor && myVendor.Catagories.forEach(ele => myCat.push(ele.catagory))
  let myCat = myVendor.Catagories && myVendor.Catagories.map(({catagory}) => catagory)

  const [name, setName] = useState(myVendor && myVendor.name);
  const [contact, setContact] = useState(myVendor && myVendor.contact)
  const [email, setEmail] = useState(myVendor && myVendor.email)
  const [phone1, setPhone1] = useState(myVendor && myVendor.phone1)
  const [phone2, setPhone2] = useState(myVendor && myVendor.phone2)
  const [website, setWebsite] = useState(myVendor && myVendor.website)
  const [facebook, setFacebook] = useState(myVendor && myVendor.facebook)
  const [userId, setuserId] = useState(myVendor && myVendor.user_id)
  const [status, setStatus] = useState(myVendor && myVendor.status);
  const [type, setType] = useState(myVendor && myVendor.type);
  const [catagories, setCatagories] = useState(myCat)
  const [isresident, setIsResident] = useState(myVendor && myVendor.isresident)
  const [inriverstone, setInRiverstone] = useState(myVendor && myVendor.inriverstone)
  const [validationErrors, setValidationErrors] = useState({});
//   const [imageLoading, setImageLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    let errors = {};
   
    if (status.length ===  0) {
      errors.title = "title is required"
    }
    if (type.length ===  0) {
        errors.title = "type is required"
      }

    setValidationErrors(errors);
  }, [status, type])

  

  let userType;
  if(sessionUser){
    userType=sessionUser.type
  }
 
  const changeHandler = (e) => {
    if (catagories.includes(e.target.value)) {
      let x = e.target.value;
      setCatagories(catagories.filter(items => items !== x))
    } else {
      setCatagories([...catagories, e.target.value])
    }
  }



  const onSubmit = async (e) => {
    e.preventDefault();
 
    const updateVendor = {
        type,
        status,
        isresident,
        inriverstone
    }
    
    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;
    
    setValidationErrors({});

    let editVendor = dispatch(fetchUpdateBusiness(updateVendor, vendor_id))


    // if picked catagory is not in my catagories, add it to my catagories
    for(let i = 0; i < catagories.length; i++){
      let uploadCatagory = allCatagories.filter(ele => ele.catagory == catagories[i])
        const [uploadCatagory1] = uploadCatagory;
        if(!myCat.includes(catagories[i])){
          await dispatch(fetchAddBusinessCatagory({ business_id: vendor_id, catagory_id: uploadCatagory1.id })).then(dispatch(getAllBusinesses)).then(results => {
            return results;
          })
            .catch(error => {
              setValidationErrors(error)
            });
        }
      } 
    
    // if my catagories has items that is not in catagories array, delete them.
    for(let i = 0; i < myCat.length; i++){
      if(!catagories.includes(myCat[i])){
        
        const deleteCat = myCat[i];
        let myCatid = allCatagories.filter(ele => ele.catagory === deleteCat);
        const [myCat1] = myCatid;
        const CatBusDelId = filteredBusinessCat.filter(ele => ele.catagory_id == myCat1.id)
       const [catBusDelId1] = CatBusDelId
        dispatch(fetchDeleteBusinessCatagory({catId:catBusDelId1.catagory_id, vendor_id})).then(dispatch(getAllBusiCats())).then(dispatch(getAllBusinesses()))
       
      }
    }
   
        setStatus("")
        setType("")
        setInRiverstone("")
        setIsResident("")
        // setImageLoading(false);
        dispatch(getAllBusinesses())
        setShowModal(false)
  }

  if(!sessionUser) navigate('/')
  if(userType !== 'admin') navigate('/')
  if (!myVendor) return null
  return (
    <>
      <button
        className="bg-gray-900 text-white active:bg-coral-red font-regular text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Edit Catagories
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">
                   Edit Vendor / Business
                  </h3>
          
                  <button
                    type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                     X
                    </span>
                  </button>
                 
                </div>
                {/*body*/}
                <form onSubmit={onSubmit}>
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}
                 <div className="grid gap-4 md:grid-cols-8 sm:gap-6 my-3 leading-relaxed">
                 {/* <div className="sm:col-span-4">
                      <div><label>Company:</label></div>
                    <input name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={name}  required onChange={(e) => setName(e.target.value)}>
                </input>
                    </div>
                    <div className="sm:col-span-4">
                      <div><label>Contact:</label></div>
                    <input name="contact" id="contact" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={contact}  required onChange={(e) => setContact(e.target.value)}>
                </input>
                    </div>
                    <div className="sm:col-span-2">
                      <div><label>Phone1:</label></div>
                    <input name="phone1" id="phone1" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={phone1}  required onChange={(e) => setPhone1(e.target.value)}>
                </input>
                    </div>
                    <div className="sm:col-span-2">
                      <div><label>Phone2:</label></div>
                    <input name="phone2" id="phone2" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={phone2}  required onChange={(e) => setPhone2(e.target.value)}>
                </input>
                    </div>
                    <div className="sm:col-span-4">
                      <div><label>email:</label></div>
                    <input name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={email}  required onChange={(e) => setEmail(e.target.value)}>
                </input>
                    </div>
                    <div className="sm:col-span-4">
                      <div><label>website:</label></div>
                    <input name="website" id="website" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={website}  required onChange={(e) => setWebsite(e.target.value)}>
                </input>
                    </div>
                    <div className="sm:col-span-4">
                      <div><label>facebook:</label></div>
                    <input name="facebook" id="facebook" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={facebook}  required onChange={(e) => setFacebook(e.target.value)}>
                </input>
                    </div>*/}
                    <div className="sm:col-span-3">
                      <div><label>User id:</label></div>
                    <input name="user_id" id="user_id" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={userId}  required onChange={(e) => setuserId(e.target.value)}>
                </input>
                    </div> 
                    <div className="sm:col-span-3">
                      <div><label>Status:</label></div>
                    <select name="filterStatus" id="filterStatus" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={status}  required onChange={(e) => setStatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">Pending</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
                    </div>
                    <div className="sm:col-span-2">
                    <div><label>Type:</label></div>
                    <select name="filterType" id="filterType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={type}  required onChange={(e) => setType(e.target.value)}>
                  <option value="vendor">Vendor</option>
                  <option value="service">Service</option>
                  <option value="business">Business</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
                    </div>
                    <div className="sm:col-span-4">
                    <div><label>Is Resident?:</label></div>
                    <select name="filterResidency" id="filterResidency" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={isresident}  required onChange={(e) => setIsResident(e.target.value)}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
                    </div>

                    <div className="sm:col-span-4">
                    <div><label>In Riverstone?:</label></div>
                    <select name="filterRRiverstone" id="filterRiverstone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={inriverstone}  required onChange={(e) => setInRiverstone(e.target.value)}>
                  <option value="true">True</option>
                  <option value="false">False</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
                    </div>
                 
                    {/* <div className="sm:col-span-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload Image<img src={img} alt="" style={{width:'50px'}}></img></label><span className='text-coral-red'></span>
                <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" required onChange={(e) => setImg(e.target.files[0])} />
              </div> */}
                    
                </div>
                </div>
                <div className="grid lg:grid-cols-4 border rounded bg-gray-50" >
                {allCat.map((cata, idx) => (
                  <div className="" >
                    <label>
                    <input type="checkbox"
                    key={cata.id}
                      value={cata}
                      className="mx-3"
                      checked={catagories && catagories.some(cat => cat == cata)}
                      onChange={changeHandler}
                    />
                    {cata}
                  </label>
                  </div>
                ))}
              </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    // onClick={() => setShowModal(false)}
                  >
                    Edit Vendor1
                  </button>
                </div>
                  </form>
              </div>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </>
      ) : null}
    </>
  );
}
