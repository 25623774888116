import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import * as sessionActions from "../../store/session"
import { useDispatch, useSelector } from 'react-redux';
import { avatars } from '../../constants'

export const EditProfileImageModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { handleShow1, handleClose1, show1 } = props


  const sessionUser = useSelector((state) => state.session.user)

  const [avatar, setAvatar] = useState(sessionUser.avatar)
  const [validationErrors, setValidationErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    let errors = {};
    setValidationErrors(errors);
  }, [avatar])

  const onSubmit = async (e) => {
    e.preventDefault();

    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;

    const username = {
      avatar: avatar,
      email: sessionUser.email
    }

    const data = await dispatch(sessionActions.updateProfile1(username))

    if (data && data.errors) {
      setValidationErrors(data.errors)
    }
    setValidationErrors({});
    handleClose1();
   
    sessionActions.restoreUser()
    // navigate('/profilepage/editprofilesuccess')
  }

  if (!sessionUser) navigate('/')

  return (
    <>
      <button
        className=""
        type="button"
        onClick={() => {
          handleShow1()
        }}>
        Change profile image

      </button>
      {show1 ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">
                    Change profile image
                  </h3>


                  <button
                    type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleClose1()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>

                </div>
                {/*body*/}
                <form onSubmit={onSubmit}>
                  <div className="relative p-6 flex-auto">

                    <div className="my-3 leading-relaxed">


                      <div className="flex flex-row gap-5 justify-between">
                        {avatars.map((ele) => (
                          <div className="flex flex-row gap-3" key={ele.id}>
                            <label htmlFor="sad">
                              <input
                                type="radio" name="emotion"
                                checked={ele.name=== avatar}
                                id="sad" className="absolute" value={ele.name} onChange={(e) => setAvatar(e.target.value)} />
                            
                              <img
                              cla
                                src={ele.img} width={'100px'}
                                alt="I'm sad" />
                            {ele.name}</label>
                          </div>
                        ))}

                      </div>

                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleClose1()}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    // onClick={() => setShowModal(false)}
                    >
                      Change
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
