import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllReviews, fetchUpdateReview } from '../../store/review';
import StarRatingInput from "./Review/starAnimate";
import './Review/addreview.css'


export default function EditReviewModal(props) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const myReview = useSelector((state) => state.review[props.modalData && props.modalData.id])
  const sessionUser = useSelector((state)=> state.session.user)

  useEffect(()=> {
    dispatch(fetchAllReviews())
  }, [dispatch])

  let user_id;
  if(sessionUser){
    user_id=sessionUser.id
  }
 
  // const [title, setTitle] = useState(props.modalData.title);
  const [review, setReview] = useState(myReview && myReview.review);
  const [rating, setRating] = useState(myReview && myReview.rating);
  const [validationErrors, setValidationErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);


  // useEffect(()=> {
  //   setTitle(modalData.title || null)
  //   setReview(props.modalData.review || null)
  //   setRating(props.modalData.rating || null)
  // }, [props.modalData.title, props.modalData.review, props.modalData.rating])

  useEffect(() => {
    let errors = {};
    if (review && review.length < 10) {
      errors.review = "Review must be 10 Character or more"
    }
    if (review && review.length > 5000) {
      errors.review = "Review must be less than 5000 characters"
    }
    if (rating < 1 || !rating || rating === 0) {
      errors.rating = "Rating input has no stars"
    }
    setValidationErrors(errors);
  }, [review, rating, props.title])



  const onSubmit = async (e) => {
    e.preventDefault();

    const reviewUpdateform = {
      review,
      rating,
      title:props.title,
      status:"active"
    }
    
    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;

    setValidationErrors({});
   
    const response = await dispatch(fetchUpdateReview(reviewUpdateform, myReview.id))
    await dispatch(fetchAllReviews())
    props.toggleShow()
    await props.handleClose(response)
  }

  const onChange = (number) => {
    setRating(parseInt(number));
  };

  if(!sessionUser) navigate('/')
 
  return (
    <>
      <button
        className=""
        type="button"
        onClick= {props.toggleShow}
      >
      
      </button>
      {props.show ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">
                   Edit your Review
                  </h3>
                 

                  <button
                    type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() =>props.handleClose()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                 
                </div>
                {/*body*/}
                <form onSubmit={onSubmit}>
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}
                 <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 my-3 leading-relaxed">
                    <div className="sm:col-span-4 md:w-[32rem]">
                        <span><label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label></span>
                        <span className='text-coral-red'> {hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
                        <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Good price. Great Service" required value = {props.title} onChange={(e) => props.setTitle(e.target.value)}/>
                    </div>
                    <div className="sm:col-span-4">
                        <label for="review" className="block mb-2 text-sm font-medium text-gray-900">Review</label>
                        <span className='text-coral-red'> {hasSubmitted && validationErrors.review && `${validationErrors.review}`}</span>
                        <textarea type="text" name="review" id="review" rows={10} className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Describe your experience" required value = {review} onChange={(e) => setReview(e.target.value)}/>
                    </div>
                    <div className="sm:col-span-4">
                        <label for="rating" className="block mb-2 text-sm font-medium text-gray-900">Rating</label>
                        <div ><StarRatingInput disabled={false} onChange={onChange} stars={rating} /></div>
                <div className="text-coral-red">{hasSubmitted && validationErrors.stars && `${validationErrors.stars}`}</div>
                        {/* <input type="text" name="rating" id="rating" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Title for the Catagory" required value = {rating} onChange={(e) => setRating(e.target.value)}/> */}
                    </div>
                    
                </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={props.toggleShow}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    // type="button"
                    // onClick={() => setShowModal(false)}
                  >
                    Edit Review
                  </button>
                </div>
                  </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}