

const LOAD_SEARCH = "search/LOAD_SEARCH";


// search results
const load_search = payload => ({
    type: LOAD_SEARCH,
    payload
})

// thunk
// get search results
export const getSearchResults = (query) => async dispatch => {
    const response = await fetch(`/api/search/${query}`);
    if(response.ok){
        const payload = await response.json();
        dispatch(load_search(payload));
        return payload
    }
    return response;
};

const initialState = {}
export default function searchReducer(state = initialState, action){
    switch(action.type){
        case LOAD_SEARCH:
            const allSearch = {}
            action.payload.forEach(ele=> allSearch[ele.id]=ele)
            return {...state, ...allSearch}

        default: 
            return state;
    }
}


