import React from 'react'
import Footer from '../Footer'

export const Inactive = () => {
    return (
        <div className="flex flex-col min-h-screen justify-between">
            <div></div>
            <div className="max-container1 text-lg px-10">This account is currently Inactive. This may be a result of failed verification or the resident has moved out of Riverstone. If you feel, this is an error, contact us using the contact page. </div>
            <Footer/>
        </div>
      )
}
