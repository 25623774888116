import React from 'react'
import Footer from '../Footer'

export const Thankyou = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
    <div className="px-5 sm:py-10 max-container  bg-gray-100">
        <div className="flex flex-col items-center">
        <div><img src={"https://myaaprojects.s3.us-east-2.amazonaws.com/rstxapp/Thankyou-image.png"}/></div>
        <div className="pt-20  pb-10 font-palanquin text-xl">We appreciate you contacting us. One of our colleagues will get back to you shortly.

Have a great day!</div>
</div>
        </div>
        <Footer/>
    </div>
  )
}
