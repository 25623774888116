import Footer from '../Footer';
import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { fetchPostBusiness, getAllBusinesses } from '../../store/businesses';
import {  useNavigate, useParams } from 'react-router-dom';
import { fetchAddBusinessCatagory } from '../../store/businesscatagory';
import { getAllCatagories } from "../../store/catagories"


export const AddVendor = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let catagory_id = Number(useParams().id)
  
  let sessionUser = useSelector((state) => state.session.user);
  const catagory = useSelector(state => state.catagory[catagory_id])
  const business = useSelector((state => Object.values(state.business)))

  
  let user_id;
    if (sessionUser) {
        user_id = sessionUser.id
    }
  
  const [fullName, setFullName] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [phone1, setPhone1] = useState('')
  const [phone2, setPhone2] = useState('')
  const [website, setWebsite] = useState('')
  const [facebook, setFacebook] = useState('')
  const [validationErrors, setValidationErrors] = useState({})
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  

  useEffect(()=> {
    const errors = {}
    if(fullName.length === 0 && company.length === 0) errors.fullName = "technician's name or business name is required"
    if(company.length > 50) errors.company = "Business name must be less than 50 chars"
    if(fullName && fullName.length < 2) errors.fullName = "technicians name must be atleast 2 characters"
    if(company && company.length < 2) errors.company = "business name must be atleast 2 characters"
    if(fullName.length > 50) errors.fullName = 'Name must be 50 characters or less'
    if(phone1 && phone1.length > 10) errors.phone1 = 'Phone must be 10 characters with no dashes or spaces'
    if(phone1 && !(phone1.match(/^[0-9]+$/g))) errors.phone1 = 'Not a valid phone number'
    if(phone1 && phone1.length < 10) errors.phone1 = 'Phone must be 10 digits with no dashes or spaces'
    if(phone2 && !(phone2.match(/^[0-9]+$/g))) errors.phone2 = 'Not a valid phone number'
    if(phone2 && phone2.length > 10) errors.phone2 = 'Phone must be 10 digits with no dashes or spaces'
    if(phone2 && phone2.length < 10) errors.phone2 ='Phone must be 10 characters with no dashes or spaces'
    if(email.length === 0 && phone1.length === 0) errors.phone1 = "phone or email is required";
    if (email && !(email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g))) errors.email = 'Not a valid email address'
    setValidationErrors(errors)
  }, [fullName, phone1, email, company, phone2])

  useEffect(()=> {
    dispatch(getAllBusinesses())
  }, [dispatch])

  useEffect(()=> {
    dispatch(getAllCatagories())
  }, [dispatch])

  
  // const existingBusiness = business.find(item => (phone1 && item.phone1 === phone1) || (phone1 && item.phone2 === phone1) || (phone2 && item.phone1 === phone2) || (phone2 && item.phone2 === phone2) || (email && item.email === email))
 let existingBusiness;
 if(fullName && company){
  existingBusiness = business.find(item => item.contact == fullName && item.name == company)
 } else if (fullName && !company){
  existingBusiness =  business.find(item => ((phone1 && item.phone1 === phone1) || (phone1 && item.phone2 === phone1) || (phone2 && item.phone1 === phone2) || (phone2 && item.phone2 === phone2) || (email && item.email === email)))
 } else if (company && !fullName){
  existingBusiness = business.find(item => item.name == company && ((phone1 && item.phone1 === phone1) || (phone1 && item.phone2 === phone1) || (phone2 && item.phone1 === phone2) || (phone2 && item.phone2 === phone2) || (email && item.email === email)))
 }

  const handleCancel = () => {
    navigate(`/vendors/${catagory_id}`)
}
  
  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    
    // if (existingBusiness) navigate(`/vendordetails/${existingBusiness.id}`)
  if(existingBusiness){
    navigate('/addskills', {state:{id:existingBusiness.id, catagory_id:catagory_id}})
  } else {

    const newVendorForm = {
      user_id,
      contact:fullName,
      name:company,
      email,
      phone1,
      phone2,
      website,
      facebook,
      status:'pending',
      type:catagory.type

    }
    
    setHasSubmitted(true)
    if(Object.keys(validationErrors).length > 0) setIsLoading(false)
    if(Object.keys(validationErrors).length > 0) return;

    
    const newVendor = await dispatch(fetchPostBusiness(newVendorForm)) 
    .catch(async (res) => {
      const data = await res.json();
      
      if (data && data.errors) {
        setIsLoading(false)
        setValidationErrors(data.errors)
        
      }
    });

    
    
    if(newVendor){
      const newVendCat = {
        catagory_id,
        business_id: newVendor.id
      }

      await dispatch(fetchAddBusinessCatagory(newVendCat))
      navigate(`/vendordetails/${newVendor.id}`, {state:{catagory:catagory.catagory}})
    }
   
  }
  // setFullName('')
  // setCompany('')
  // setEmail('')
  // setPhone1('')
  // setPhone2('')
  // setWebsite('')
  // setFacebook('')
  }

  return (
    <div className='min-h-screen flex flex-col justify-between'>

    <div class="min-h-screen p-6 bg-gray-100 flex items-center justify-center ">
  <div class="container max-w-screen-lg mx-auto">
    
      {catagory && catagory.title && <h2 class="font-semibold text-xl text-gray-600 mb-6">Add a {catagory.title}</h2>}
      {/* <p class="text-gray-500 mb-6">Enter as much information as you know.</p> */}

      <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
        <div class="grid gap-6 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
          <div class="text-gray-600">
            <p class="font-medium text-lg pb-2">Personal Details</p>
            <p className="pb-3">Please fill out as much information as you know.</p>
            <p className="pb-3">Once you add the vendor, you will be able to post a review for the vendor.</p>
            <p className="pb-3">This website is reserved for residents only and only accessible by them.</p>
            <p className="pb-3">Adding a review will help other residents in choosing a vendor for their next project.</p>

            <p></p>
          </div>

          <div class="lg:col-span-2">
              <form onSubmit={onSubmit}>
            <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
              <div class="md:col-span-5">
                <label for="fullname">Full Name</label> <span className="text-coral-red">{hasSubmitted && validationErrors.fullName && ` ${validationErrors.fullName}`}</span><span className="text-coral-red">{hasSubmitted && validationErrors.contact && ` ${validationErrors.contact}`}</span>
                <input type="text" 
                name="fullname" 
                id="fullname" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={fullName} 
                onChange={(e)=> setFullName(e.target.value)}
                placeholder="John doe"/>
              </div>
             
              <div class="md:col-span-5">
                <label for="company">Name of Business</label><span className="text-coral-red">{hasSubmitted && validationErrors.company && ` ${validationErrors.company}`}</span>
                <input type="text" 
                name="company" 
                id="company" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={company} 
                onChange={(e)=> setCompany(e.target.value)} 
                placeholder="ABC Company" />
              </div>

              <div class="md:col-span-5">
                <label for="phone1">Phone 1</label><span className="text-coral-red">{hasSubmitted && validationErrors.phone1 && ` ${validationErrors.phone1}`}</span>
                <input type="text" 
                name="phone1" 
                id="phone1" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={phone1} 
                onChange={(e)=> setPhone1(e.target.value)} 
                placeholder="Phone must be 10 characters with no dashes or spaces" />
              </div>
              <div class="md:col-span-5">
                <label for="phone2">Phone 2</label><span className="text-coral-red">{hasSubmitted && validationErrors.phone2 && ` ${validationErrors.phone2}`}</span>
                <input type="text" 
                name="phone2" 
                id="phone2" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={phone2} 
                onChange={(e)=> setPhone2(e.target.value)} 
                placeholder="Phone must be 10 characters with no dashes or spaces" />
              </div>
              <div class="md:col-span-5">
                <label for="email">Email Address</label><span className="text-coral-red">{hasSubmitted && validationErrors.email && ` ${validationErrors.email}`}</span>
                <input type="text" 
                name="email" 
                id="email" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={email} 
                onChange={(e)=> setEmail(e.target.value)} 
                placeholder="email@domain.com" />
              </div>
              <div class="md:col-span-5">
                <label for="website">Website</label>
                <input type="text" 
                name="website" 
                id="website" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={website} 
                onChange={(e)=> setWebsite(e.target.value)}
                placeholder="wwww.website.com" />
              </div>
              <div class="md:col-span-5">
                <label for="facebook">Link to Facebook Page</label>
                <input type="text" 
                name="facebook" 
                id="facebook" 
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" 
                value={facebook} 
                onChange={(e)=> setFacebook(e.target.value)} 
                placeholder="facebook.com/xxx" />
              </div>
         
              <div class="md:col-span-5 text-right">
                <div>{isLoading == true && <p className="text-coral-red">form is being Submitted</p>}</div>
                <div class="inline-flex items-center mt-3">
                <button
                onClick={handleCancel}
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                >Cancel</button>    
            
                  <button class="bg-coral-red hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                </div>
              </div>
            </div>
              </form>
          </div>
        </div>
      </div>
   
  </div>
</div>
<Footer/>
</div>
  )
}
