import { useEffect } from "react";
import { useNavigate, Navigate} from 'react-router-dom';

import React from 'react'
import { useSelector } from "react-redux";

export const Protected = (props) => {
    const {Component, Type, Verified } = props;
    const navigate = useNavigate();
    const sessionUser = useSelector(state => state.session.user)

    useEffect(()=> {
        if(!sessionUser){
            navigate('/login')
        }
        // if(sessionUser && sessionUser.verified !== 'active'){
        //   navigate('/')
        // }
    })
   
   
  return (
    <div>{sessionUser && Type.includes(sessionUser.type) && Verified.includes(sessionUser.verified) && <Component/>}</div>
    // <div>{sessionUser &&  sessionUser.type == Type && <Component/>}</div>
  )
}
