import React from 'react'

export const Button1 = ({
    label,
    backgroundColor,
    textColor,
    hover,
    fullWidth,
    onClick
   

  }) => {
  return (
    <button
   
    className={`flex justify-center items-center gap-2 px-4 py-2  rounded text-sm leading-none
    ${
      backgroundColor
        ? `${backgroundColor} ${textColor}`
        : `bg-gray-900 text-white  ${hover}`
    } ${fullWidth && "w-full"}`}
    onClick={onClick}
  >
    {label}

  </button>
  )
}
// className="bg-gray-700 hover:bg-coral-red text-white font-bold py-2 px-4 rounded font-montssarat max-container"