import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAddCatagory } from '../../store/catagories'
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer'

const AddCatagory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sessionUser = useSelector(state => state.session.user)


    const [catagory, setCatagory] = useState('')
    const [img, setImg] = useState('')
    const [type, setType] = useState('')
    const [title, setTitle] = useState('')
    const [validationErrors, setValidationErrors] = useState({})
    const [hasSubmitted, setHasSubmitted] = useState()
    const [imageLoading, setImageLoading] = useState(false);
    
    useEffect(()=> {
        const errors = {}
        if(catagory.length === 0) errors.catagory = 'Catagory is required';
        if(!img) errors.img = 'Img is required';
        if(type.length === 0) errors.type = 'Type is required';
        if(title.length === 0) errors.type = 'title is required';
        setValidationErrors(errors)
    }, [catagory, img, type, title])

    const handleCancel = () => {
        navigate('/admin/dashboard')
    }

    const onSubmit = async (e) => {
        e.preventDefault();


        const formData = new FormData();
        formData.append('catagory', catagory)
        formData.append('img', img)
        formData.append('type', type)
        formData.append('title', title)

        
        setHasSubmitted(true);
        if(Object.keys(validationErrors).length > 0) return;

       const newCat = await dispatch(fetchAddCatagory(formData))
        .catch(async (res) => {
            const data = await res.json();
            if (data && data.errors) {
              setValidationErrors(data.errors)
            }
          });

        if(newCat) {
            navigate('/admin/dashboard')
        }     
        }
        

        if(!sessionUser || sessionUser.type !== 'admin') navigate('/')
    
  return (
    <div className="flex flex-col h-screen justify-between" >
        <div className="max-container1 mt-20">
        <form onSubmit={onSubmit} className="bg-white border border-gray-300 rounded p-5">
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                        <div className="sm:col-span-2">
                            <span><label for="catagory" className="block mb-2 text-sm font-medium text-gray-900">Catagory</label></span><span className='text-coral-red'> {hasSubmitted && validationErrors.catagory && `${validationErrors.catagory}`}</span>
                            <input type="text" name="catagory" id="catagory" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: painter" required value = {catagory} onChange={(e) => setCatagory(e.target.value)}/>
                        </div>
                        <div className="sm:col-span-2">
                            <label for="type" className="block mb-2 text-sm font-medium text-gray-900">Type</label><span className='text-coral-red'> {hasSubmitted && validationErrors.type && `${validationErrors.type}`}</span>
                            <input type="text" name="type" id="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: Vendor" required value = {type} onChange={(e) => setType(e.target.value)}/>
                        </div>
                        <div className="sm:col-span-2">
                            <label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label><span className='text-coral-red'> {hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
                            <input type="title" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Title for the Catagory" required value = {title} onChange={(e) => setTitle(e.target.value)}/>
                        </div>
                                           
                        <div className="sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload file</label><span className='text-coral-red'> {hasSubmitted && validationErrors.img && `${validationErrors.img}`}</span>
                            <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" required onChange={(e) => setImg(e.target.files[0])}/>
                            <div className="mt-1 text-sm text-gray-500" id="user_avatar_help">Add an icon image</div>
                        </div>
                    </div>
                    <button type="submit" className="w-full items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-gray-900 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                        Add Catagory
                    </button>
                    <button
                    onClick={handleCancel}
                     className="w-full items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-coral-red rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">Cancel</button>    
                     {(imageLoading)&& <p>Loading...</p>}
                </form>
                </div>
                <Footer/>

    </div>

  )
}

export default AddCatagory