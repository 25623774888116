import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchUpdateReview } from '../../store/review';
// import { getAllBusinesses } from '../../store/businesses'
import {  fetchUpdateCatagory, getAllCatagories } from "../../store/catagories";
// import './Review/addreview.css'


export default function EditCatagoryModal(id) {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const catagory_id = id.id

 
  
  const [showModal, setShowModal] = useState(false);

  const myCatagory = useSelector((state) => state.catagory[catagory_id])
  const sessionUser = useSelector((state)=> state.session.user)

  let userType;
  if(sessionUser){
    userType=sessionUser.type
  }
 
  const [catagory, setCatagory] = useState(myCatagory && myCatagory.catagory);
  const [img, setImg] = useState(myCatagory && myCatagory.img);
  const [title, setTitle] = useState(myCatagory && myCatagory.title);
  const [type, setType] = useState(myCatagory && myCatagory.type);
  const [validationErrors, setValidationErrors] = useState({});
  const [imageLoading, setImageLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);


  useEffect(()=> {
    dispatch(getAllCatagories())
}, [dispatch])

  useEffect(() => {
    let errors = {};
    if (catagory.length === 0) {
      errors.catagory = "Catagory is required"
    }
    if (title.length ===  0) {
      errors.title = "title is required"
    }
    if (type.length ===  0) {
        errors.title = "type is required"
      }

    setValidationErrors(errors);
  }, [catagory, title, type])

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("catagory", catagory)
    formData.append("title", title)
    formData.append("type", type)
    formData.append("img", img)


    const reviewUpdateform = {
      catagory,
      title,
      // img,
      type
    }
    
    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;
    
    setValidationErrors({});

    let updateCatagory = dispatch(fetchUpdateCatagory(formData, catagory_id))

    if (updateCatagory){
        setTitle('')
        setCatagory('')
        setImg(null)
        setType("")
        setImageLoading(false);
        setShowModal(false)
    }


  }
 

  if(!sessionUser) navigate('/')
  if(userType !== 'admin') navigate('/')
  if (!myCatagory) return null
  return (
    <>
      <button
        className="bg-gray-900 text-white active:bg-coral-red font-regular text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Edit
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">
                   Edit your Review
                  </h3>
                 

                  <button
                    type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                 
                </div>
                {/*body*/}
                <form onSubmit={onSubmit}>
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}
                 <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 my-3 leading-relaxed">
                    <div className="sm:col-span-4 md:w-[32rem]">
                        <span><label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label></span>
                        <span className='text-coral-red'> {hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
                        <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Good price. Great Service" required value = {title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>
                    <div className="sm:col-span-4">
                        <label for="catagory" className="block mb-2 text-sm font-medium text-gray-900">Catagory</label>
                        <span className='text-coral-red'> {hasSubmitted && validationErrors.catagory && `${validationErrors.catagory}`}</span>
                        <input type="text" name="catagory" id="catagory" className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Describe your experience" required value = {catagory} onChange={(e) => setCatagory(e.target.value)}/>
                    </div>
                    <div className="sm:col-span-4">
                        <label for="type" className="block mb-2 text-sm font-medium text-gray-900">Type</label>
                        <span className='text-coral-red'> {hasSubmitted && validationErrors.type && `${validationErrors.type}`}</span>
                        <input type="text" name="type" id="type" className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Describe your experience" required value = {type} onChange={(e) => setType(e.target.value)}/>
                    </div>
                    <div className="sm:col-span-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload Image<img src={img} alt="" style={{width:'50px'}}></img></label><span className='text-coral-red'></span>
                <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" onChange={(e) => setImg(e.target.files[0])} />
              </div>
                    
                </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    // type="button"
                    // onClick={() => setShowModal(false)}
                  >
                    Edit Catagory
                  </button>
                </div>
                  </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}