import React from 'react'
import Footer from '../Footer'

export const NewCatagoryEmail = () => {
  return (
    <div className="flex flex-col h-screen justify-between bg-gray-100">
        <div className="px-10 py-10 max-container  bg-gray-100">
            <div>We have received your request. We will add the catagory if it is required or contact you for more information.</div>
        </div>
        <Footer/>
        </div>
  )
}
