import React, { useEffect, useState } from 'react'
import Footer from '../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteReviewModal from './DeleteReviewModal'
import { fetchAllReviews } from '../../store/review';
import EditReviewModal from './EditReviewModal';


export const MyReviews = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    let reviews = useSelector(state => Object.values(state.review))
    const sessionUser = useSelector(state => state.session.user)

    let userId;
    if (sessionUser) {
        userId = sessionUser.id
    }
    reviews = reviews.filter(ele => ele.user_id == userId)
    
    
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [title, setTitle] = useState();
    const [key, setKey] = useState();
    const [myReviews, setMyReviews] = useState([])
    
    useEffect(() => {
        dispatch(fetchAllReviews())
    }, [dispatch])

    useEffect(() => {
        setTitle(modalData && modalData.title);
      },[modalData]);

    function toggleShow(){
        setShow(!show)
    }
    function toggleShow1(){
        setShow1(!show1)
    }  
    
    const handleClose = (response) => {
        dispatch(fetchAllReviews())
       return setModalData({...modalData,  response})

    };

    const handleClose1 = () => {
        dispatch(fetchAllReviews())
      setMyReviews(...myReviews, reviews)
    }

    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true)


    function datefield(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date1 = date.split('T')[0]
        const date2 = date1.split('-')

        return date2[2] + " " + months[date[1]] + " " + date2[0]
        // return date.split('T')[0]

    }

    function starRating(star) {
        if (star === 1) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 2) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 3) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 4) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 5) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i></div>)
        }
    }

    if (!sessionUser) navigate('/')
    return (
        <div className="bg-gray-100 min-h-screen flex flex-col justify-between">


            <div className="max-container1">
                <div class="py-12 px-4 md:px-6 2xl:px-0 2xl:container 2xl:mx-auto flex justify-center items-center shrink-0 w-full">
                    <div class="flex flex-col justify-between items-start w-full space-y-8 shrink-0">
                        <div class="flex justify-between items-start w-full">
                            <h1 class="text-2xl lg:text-3xl font-semibold leading-7 lg:leading-9 text-gray-800">My Reviews</h1>
                        </div>
    
                        {reviews.length ?
                            <div class="w-full flex justify-between items-start flex-col bg-white px-3 py-3 shrink-0">{reviews.map((review, idx) => (
                                <div key={`${idx}_${review.id}`} className="w-full border-b-2 pb-4">
                                    <div class="mt-6 flex justify-start items-center flex-row space-x-2.5 pb-5 shrink-0"> 
                                        {/* <div>
                                 <img className="" src="https://myaaprojects.s3.us-east-2.amazonaws.com/profile-circle.png" alt="girl-avatar" width="38" height="38" />
                             </div> */}
                                         <div class="flex flex-col justify-between items-start space-y-2 shrink-0">
                                            <p  class="text-sm font-medium leading-none text-gray-800"> Review for {review.businesscontact && review.businesscontact}, {review.businessname && review.businessname} </p>
                                            <p class="text-sm leading-none text-gray-800"> Reviewed on {datefield(review.createdAt)}</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row justify-between w-full">

                                        <div class="flex flex-row justify-between items-start">

                                            <p class="text-xl md:text-xl font-medium leading-normal text-gray-800 break-all">{review.title}</p>

                                        </div>

                                        <div>{starRating(review.rating)}</div>
                                    </div>
                                    <div id="menu" class="md:block">
                                        <p class="mt-3 text-sm leading-normal text-gray-800 w-full md:w-9/12 xl:w-5/6">{review.review}</p>
                                        <div className="flex justify-start gap-3">
                                    <button  className="bg-gray-800 hover:bg-coral-red text-white font-bold py-1 px-3 mt-3 rounded"key={review.id} onClick={()=> {
                                       
                                            setModalData(review)
                                            handleShow()
                                    }}>Edit</button>
                                    <button className="bg-coral-red text-white font-bold py-1 px-3 mt-3 rounded" key={review.id} onClick={()=> {
                                        setKey(review.id)
                                    //    setModalData(review)
                                       handleShow1()
                               }}>Delete</button>
                             
                                    </div>
                                   </div>
                                </div>
                            ))}
                             <div className="flex justify-start gap-3">
                             <div> <EditReviewModal key={modalData && modalData.id} modalData={modalData} handleClose={handleClose} handleShow={handleShow} show={show} toggleShow={toggleShow} title={title} setTitle={setTitle}/></div>
                         <div><DeleteReviewModal myReviews={myReviews} key={key} id={key} show1={show1} handleShow1={handleShow1} handleClose1={handleClose1} toggleShow1={toggleShow1}/></div>
                            
                                    </div>
                            </div>
                            
                            : <p>You currently do not have any reviews posted. Begin by adding the technicians you have worked with and post a review for them, which will help other residents find a suitable person for their next project or an emergency repair.</p>}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
