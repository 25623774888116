import React, {useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBusinesses } from '../../store/businesses'
import { fetchAllReviews } from '../../store/review'

export const Dashboard = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const sessionUser = useSelector(state => state.session.user)
  const businesses = useSelector(state => Object.values(state.business))
  const reviews = useSelector(state => Object.values(state.review))

  const vendors = businesses.filter(ele => ele.type === "vendor")
  const services = businesses.filter(ele=> ele.type === "service")
  const business = businesses.filter(ele=> ele.type === "business")
  

  useEffect(()=> {
    dispatch(getAllBusinesses())
  }, [dispatch])

  useEffect(()=> {
    dispatch(fetchAllReviews())
  }, [])
  // if(!sessionUser) return null;
  if(!sessionUser) navigate('/')

  return (
    <div className="flex flex-col h-screen justify-between">
    <div className="max-container">

      <div>Vendors: {vendors.length}</div>
      <div>Services: {services.length}</div>
      <div>Businesses: {business.length}</div>
      <div>Reviews: {reviews.length}</div>

       <div className="flex flex-col sm:flex-row gap-10 mb-10">
        <div >catagories<div></div>
        <div><Link to ="/admin/viewcatagory">View catagory</Link></div>
            <div><Link to ="/admin/addcatagory">Add catagory</Link></div>
           
            {/* <div>update catagory</div> */}
            {/* <div>delete catagory</div> */}
          </div>

        <div>vendors
          <div>Add Vendors</div>
          <div><Link to ="/admin/viewvendors">View Vendors</Link></div>
          {/* <div>Update Vendors</div> */}
          {/* <div>Delete Vendors</div> */}
        </div>
      
       {/* <div>events
          <div>Add events</div>
          <div>view events</div>
          <div>Update events</div>
          <div>Delete events</div>
       </div> */}
       <div>Users
        
       <div><Link to ="/admin/userlist">View Users</Link></div>
        <div>update Users</div>
        
       </div>

       {/* <div>Business
        <div>add business</div>
        <div>view business</div>
        <div>update business</div>
        <div><Link to={"/admin/business"}>approve business</Link></div>
       </div> */}
       {/* <div>Services
          <div>Add services</div>
          <div>view services</div>
          <div>Update services</div>
          <div>Delete services</div>
        </div> */}


      </div>
        
        </div>
        <Footer/>
        </div>
  )
}
