import { csrfFetch } from "./csrf";
const LOAD_CART = "cart/LOAD_CART"
const ADD_CART = "cart/ADD_CART"


// all products
const load_cart = payload => ({
    type: LOAD_CART,
    payload
})

const add_cart = payload => ({
    type:ADD_CART,
    payload
})

export const getAllCart = (userId) => async dispatch => {
    const response = await fetch(`/api/cart/${userId}`);
   
    if (response.ok) {
        const payload = await response.json();
      
        dispatch(load_cart(payload));
    }
}

export const postCart = (user_id) => async dispatch => {
    
    const response = await csrfFetch('/api/cart/new', {
        method:"POST",
        headers: { "Content-Type": "application/json"},
        body:JSON.stringify(user_id),
    });
    const payload = await response.json();
    dispatch(load_cart(payload));
    return payload;
}

const initialState = {};
export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CART:
            return {...state, ...action.payload}
        case ADD_CART:
            return {...state, ...action.payload}    
        default:
            return state;
    }
}