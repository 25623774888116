import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useEffect, useState} from 'react';
import Footer from "../Footer"
import {fetchUpdateUserList, getAllUsers} from '../../store/userlist'
import {Button1} from '../Button/Button1'
import { EditUserModal } from './EditUserModal';


export const Userlist = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const users = useSelector(state => Object.values(state.userList))

    const sessionUser = useSelector(state => state.session.user)

    


    const [filterVerified, setFilterVerified] = useState('')
    const [filterType, setFilterType] = useState('')

    let filteredUser;
    if(!filterType && !filterVerified){
        filteredUser = users;
    }
    if(!filterType && filterVerified){
        filteredUser = users.filter(user => user.verified === filterVerified)
    }
    if(filterType && !filterVerified){
        filteredUser = users.filter(user => user.type === filterType)
    }
    if(filterType && filterVerified){
        filteredUser = users.filter(user => user.type === filterType && user.verified === filterVerified)
    }
    // if(filterType && ){
    //     filteredUser =  users.filter(user => user.type == filterType)
    //  } else {
    //      filteredUser = filteredUser1;
    //  }
    // if(!filterType && !filterVerified){
    //     filteredUser
    // }


    // const filteredUser1 = filterVerified && users.filter(user => user.verified === filterVerified)
    // const filteredUser = filteredUser1 && users.filter(user => user.type === filterType)

    useEffect(()=> {
        dispatch(getAllUsers())
    }, [dispatch])

    if(!users) navigate('/')
    if(!sessionUser || sessionUser.type !== 'admin') navigate('/')
  return (
   <div className="flex flex-col min-h-screen justify-between">
    <div className="max-container1">
        <div className="flex flex-row gap-10">
        <div>Filter by Status: 
        <select name="filterVerified" id="filterVerified" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={filterVerified}  required onChange={(e) => setFilterVerified(e.target.value)}>
                  <option value=""></option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">Pending</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
        </div>
        <div>Filter by type: 
        <select name="filterType" id="filterType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={filterType}  required onChange={(e) => setFilterType(e.target.value)}>
                  <option value=""></option>
                  <option value="admin">Admin</option>
                  <option value="owner">Owner</option>
                  <option value="tenant">Tenant</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
        </div>
        </div>
        <div className="mt-5">
        {filteredUser && filteredUser.map((user)=>
        <div className="flex flex-row gap-5 items-center justify-between">
            <div>{user.first_name}</div>
            <div>{user.last_name}</div>
            {/* {edit===true && <div>{user.email}</div>} */}
            <div>{user.verified}</div>
            <div>{user.type}</div>
            {/* <button label='Edit' hover='hover:bg-coral-red' key={user.id} onClick={()=> setEdit(!edit)}>Edit</button> */}
            <div className=""><EditUserModal id={user.id} key={user.id}/></div>
            </div>
        )}
        </div>

    </div>
    <Footer/>
   </div>
  )
}
