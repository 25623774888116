import React from 'react'
import { useState, useEffect } from "react";
import * as sessionActions from "../../store/session"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";


export const ResetPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation()

    const email1 = location.state;

   

    const sessionUser = useSelector((state)=> state.session.user);
    // const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState(email1)


    useEffect(()=> {
        let newError = {};
        if(password.length < 8) newError.password = 'password must be atleast 8 characters'
    if(password.length > 30) newError.password = 'password must be less than 30 characters'
    if(!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) newError.password = 'password must contain atleast one lowercase, one uppercase, one number, and one special character'
    setErrors(newError)
    }, [password, confirmPassword] )


   

    const handleSubmit = async (e) => {
        e.preventDefault();
        setHasSubmitted(true);
        if(Object.keys(errors).length > 0) return;
        setEmail(email1)
        const newInfo = {email, password}
        
        if(password === confirmPassword) {
                
            const data = await dispatch(sessionActions.updateProfile(newInfo))
                // const data = await res.json();
               
                if (data && data.errors) {
                  setErrors(data.errors)
                } 
               
                setPassword("")
                setConfirmPassword("")
                navigate('/changepasswordsucess')
            
        } else {
            setErrors({cnf:'Confirm Password must be the same as the Password'});
        }
    };

    if(sessionUser) return navigate('/')

  return (
    <div className="sm:mt-28">
    <div className="" >
        <div className="flex bg-white rounded-lg shadow-xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
            <div className="hidden lg:block lg:w-1/2 bg-cover bg-[url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')]">
            </div>
            <div className="w-full p-8 lg:w-1/2">
                <h2 className="text-2xl font-semibold text-gray-700 text-center">Reset Your Password</h2>
                {/* <p className="text-l pt-4 text-gray-600 text-center">Please enter your email address and we'll send you instructions on how to reset your password</p> */}
                <div className="mt-4 flex items-center justify-between">
                    
                </div>
                <form onSubmit={handleSubmit}>
                    <ul>
                        {errors.length > 0 &&  errors.map((error, idx)=> (
                            <li style={{color:'red', listStyle:"none"}} key={idx}>{error}</li>
                        ))}
                    </ul>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Enter New Password</label>
                    <input 
                    value={password}
                    onChange={(e)=> setPassword(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>

                    <span className="text-coral-red text-sm">{hasSubmitted && errors.password && errors.password}</span>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Confirm Password</label>
                    <input 
                    value={confirmPassword}
                    onChange={(e)=> setConfirmPassword(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>
                <span className="text-coral-red text-sm">{hasSubmitted && errors.cnf && errors.cnf}</span>
                <div className="mt-8">
                    <button className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600" type="submit">Reset Password</button>
                </div>
                </form>
                <div className="mt-4 flex items-center justify-between">
                    {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                    {/* <a href="#" className="text-xs text-gray-500 uppercase">or sign up</a> */}
                    {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                </div>
            </div>
        </div>
    </div>
    </div>

  )
}
