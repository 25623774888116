import { csrfFetch } from "./csrf";
const LOAD_EVENTDETAILS = "events/LOAD_EVENTDETAILS"
const ADD_EVENTDETAIL = "events/ADD_EVENTDETAIL"
const DELETE_EVENTDETAIL = "events/DELETE_EVENTDETAIL"
const EDIT_EVENTDETAIL = "events/UPDATE_EVENTDETAIL"

// all events
const load_eventdetails = payload => ({
    type: LOAD_EVENTDETAILS,
    payload
})

const add_eventDetail = payload => ({
    type: ADD_EVENTDETAIL,
    payload
})

const delete_eventDetail = payload => ({
    type: DELETE_EVENTDETAIL,
    payload
})

const edit_eventdetail = payload => ({
    type: EDIT_EVENTDETAIL,
    payload
})

// Thunks
// get all events
export const getAllEventDetails = () => async dispatch => {
    const response = await fetch(`/api/eventdetails`);
    if(response.ok){
        const payload = await response.json();
        dispatch(load_eventdetails(payload))
    }
}

// add an event
export const addEventDetail = (formData) => async dispatch => {
   
    const response = await csrfFetch(`/api/eventdetails/new`, {
        method: 'POST',
        body: formData
    })
    const payload = await response.json();
    dispatch(add_eventDetail(payload))
    return payload
}

// delete eventdetail
export const deleteEventDetail = (id) => async dispatch => {
    const response = await csrfFetch(`/api/eventdetails/delete/${id}`, {
        method:"DELETE",
    });
    if (response.ok){
        dispatch(delete_eventDetail(id))
    }
}

// edit an event
export const editEventDetail = (formData, id) => async dispatch => {
    const response = await csrfFetch(`/api/eventdetails/update/${id}`, {
        method:"PUT",
        body:formData
    })
    const payload = await response.json();
    dispatch(edit_eventdetail(payload))
}



const initialState = {};
export default function eventdetailReducer(state = initialState, action){
    switch(action.type){
        case LOAD_EVENTDETAILS:
            const allEventDetails = {}
            action.payload.forEach(ele => allEventDetails[ele.id]=ele)
            return {...state, ...allEventDetails}

        case ADD_EVENTDETAIL:
            return {...state, ...action.payload}  

        case EDIT_EVENTDETAIL:
            return {...state, ...action.payload}    
            
        case DELETE_EVENTDETAIL:
            const delEventdetail = {...state};
            delete delEventdetail[action.payload]
            return{ ...state }      
        default:
            return state;    
    }   

}
