import { csrfFetch } from "./csrf";

const LOAD_PRODUCTS = "reviews/LOAD_PRODUCTS"

// all products
const load_products = payload => ({
    type: LOAD_PRODUCTS,
    payload
})

export const fetchAllProducts = () => async dispatch => {
    const response = await fetch(`/api/product`);
    if(response.ok){
        const payload = await response.json();
        dispatch(load_products(payload))
    }
}

const initialState = {}

export default function productReducer(state = initialState, action){
    switch(action.type){

        case LOAD_PRODUCTS:
            const allProducts = {}
            action.payload.forEach(ele => allProducts[ele.id] = ele)
            return {...state, ...allProducts}

        default: 
            return state;
    }
}