import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import * as sessionActions from "../../store/session"
import { useDispatch, useSelector } from 'react-redux';

export const EditDisplayNameModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { handleShow, handleClose, show } = props

  const sessionUser = useSelector((state) => state.session.user)

  const [display, setDisplay] = useState(sessionUser.username);
  const [validationErrors, setValidationErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    let errors = {};
    if (display && display.length < 4) {
      errors.display = "Display name must be 4 Characters or more"
    }
    if (display && display.length > 30) {
      errors.display = "Display name must be less than 30 characters or less"
    }

    setValidationErrors(errors);
  }, [display])

  const onSubmit = async (e) => {
    e.preventDefault();

    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;

    const username = {
      username: display,
      email: sessionUser.email
    }

    const data = await dispatch(sessionActions.updateProfile1(username))

    if (data && data.errors) {
      setValidationErrors(data.errors)
    }
   
    setValidationErrors({});
    handleClose();
    sessionActions.restoreUser()
  }

  if (!sessionUser) navigate('/')

  return (
    <>
      <button
        className=""
        type="button"
        onClick={() => {
          handleShow()
        }}>
        Change display name

      </button>
      {show ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">
                    Change display name
                  </h3>
                  <button
                    type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => handleClose()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>

                </div>
                {/*body*/}
                <form onSubmit={onSubmit}>
                  <div className="relative p-6 flex-auto">
                    <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 my-3 leading-relaxed">
                      <div className="sm:col-span-2 md:w-[20rem]">
                        <span><label for="display" className="block mb-2 text-sm font-medium text-gray-900">Display Name</label></span>
                        <span className='text-coral-red'> {hasSubmitted && validationErrors.display && `${validationErrors.display}`}</span>
                        <input type="text" name="display" id="display" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Good price. Great Service" required value={display} onChange={(e) => setDisplay(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="submit"
                    >
                      Change
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );

}
