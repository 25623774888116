import { csrfFetch } from "./csrf";
const LOAD_BUSINESSES = "businesses/LOAD_BUSINESSES"
const ADD_BUSINESSES = "businesses/ADD_BUSINESSES"
const UPDATE_BUSINESSES = "businesses/UPDATE_BUSINESSES"

// all businesses
const load_businesses = payload => ({
    type: LOAD_BUSINESSES,
    payload
})

const add_businesses = payload => ({
    type: ADD_BUSINESSES,
    payload
})

const update_businesses = payload => ({
    type: UPDATE_BUSINESSES,
    payload
})

// thunks
export const getAllBusinesses = () => async dispatch => {
    const response = await fetch(`/api/businesses`);
    if(response.ok){
        const payload = await response.json();
       
        dispatch(load_businesses(payload));
    }
}

export const fetchPostBusiness = (newBusinessForm) => async dispatch => {
    
    const response = await csrfFetch(`/api/businesses/new`, {
        method :"POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(newBusinessForm)
    });
    const payload = await response.json();
    dispatch(add_businesses(payload))
   
    return payload
    }

export const fetchUpdateBusiness = (updateBusinessForm, businessId) => async dispatch => {
    const response = await csrfFetch(`/api/businesses/update/${businessId}`, {
        method :"PATCH",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(updateBusinessForm)
    });
    if (response.ok) {
        const payload = await response.json()
        
        dispatch(update_businesses(payload))
       }
}    

const initialState = {};
export default function businessReducer(state = initialState, action){
    switch(action.type){
        case LOAD_BUSINESSES:
            const allBusinesses = {};
            action.payload.forEach(ele =>allBusinesses[ele.id]=ele)
            return {...state, ...allBusinesses}

        case ADD_BUSINESSES:
            return {...state, [action.payload.id]:action.payload}    

        case UPDATE_BUSINESSES:
            return {...state, [action.payload.id]:action.payload}
            
        default: 
            return state;
    }
}