import React from "react";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchDeleteReview } from "../../store/review";
import {fetchAllReviews } from "../../store/review";
import { getAllBusinesses } from "../../store/businesses";

export default function DeleteReviewModal(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const reviewId = props.id;
  const sessionUser = useSelector((state) => state.session.user)

  let userId;
  if(sessionUser){
    userId = sessionUser.id
  }
 
  const handleSubmit = (e) => {
            e.preventDefault()
            dispatch(fetchDeleteReview(reviewId)).then( dispatch(fetchAllReviews())).then( dispatch(fetchAllReviews())).then(dispatch(getAllBusinesses())).then(dispatch(getAllBusinesses()))
            props.toggleShow1()
            props.handleClose1()    
        }

 if(!sessionUser) navigate('/')
   
  return (
    <>
      <button
        className=""
        type="button"
        onClick={() => props.toggleShow1}
      >
      </button>
      {props.show1 ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                   Confirm Delete Review
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.handleClose1()}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={props.toggleShow1}
                  >
                    No, Keep Review
                  </button>
                  <button
                    className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit}
                    
                  >
                    Delete Review
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}