import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { getAllBusinesses } from '../../store/businesses'
import { fetchAllReviews } from '../../store/review'
import EditReviewModal from '../ProfilePage/EditReviewModal';
import DeleteReviewModal from '../ProfilePage/DeleteReviewModal';
import { Button1 } from '../Button/Button1';
import { wrench } from '../../assets/icons'
import { avatar1, avatar2, avatar3, logoplaceholder, verified } from '../../assets/icons';
import { getAllBusinessimages } from '../../store/businessimages';
import { catNotes } from '../../constants';

export const VendorDetails = () => {
    const navigate = useNavigate();
    const vendorId = useParams().id;
    const dispatch = useDispatch();
    const location = useLocation();

    const catagory = location.state?.catagory
    // const { data = null } = location.state use this line if above doesnt work

   

    const vendors = useSelector(state => Object.values(state.business))
    const reviews = useSelector(state => Object.values(state.review))
    const sessionUser = useSelector(state => state.session.user)
    const [vendor] = vendors.filter(ele => ele.id === Number(vendorId))
    let logoCard = useSelector(state => Object.values(state.businessImages))
    logoCard = logoCard.filter(ele => ele.business_id == vendorId && (ele.imgtype == "logo" || ele.imgtype == "businesscard"))

    const [logo1, logo2] = logoCard;

    let user_id;
    if (sessionUser) {
        user_id = sessionUser.id;
    }


    useEffect(() => {
        dispatch(getAllBusinesses())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllBusinessimages())
    }, [dispatch])

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [modalData, setModalData] = useState();
    const [title, setTitle] = useState();
    const [key, setKey] = useState();

    useEffect(() => {
        dispatch(fetchAllReviews())
    }, [dispatch])

    useEffect(() => {
        setTitle(modalData && modalData.title);
    }, [modalData]);

    function toggleShow() {
        setShow(!show)
    }
    function toggleShow1() {
        setShow1(!show1)
    }

    const handleClose = (response) => {
        dispatch(fetchAllReviews())
        dispatch(getAllBusinesses())
        return setModalData({ ...modalData, response })
    };

    const handleClose1 = () => {
        dispatch(fetchAllReviews())
        dispatch(getAllBusinesses())
    }

    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true)

    const ButtonMailto = ({ mailto, label }) => {
        return (
            <Link
                to='#'
                onClick={(e) => {
                    window.location.href = mailto;
                    e.preventDefault();
                }}
            >
                {label}
            </Link>
        );
    };

    const ButtonTel = ({ tel, label }) => {
        return (
            <Link
                to="#"
                onClick={(e) => {
                    window.location.href = tel;
                    e.preventDefault();
                }}
            >
                {label}
            </Link>
        )
    }


    useEffect(() => {
        handleClose()
    }, [])

    function datefield(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date1 = date.split('T')[0]
        const date2 = date1.split('-')

        return date2[2] + " " + months[+date2[1] - 1] + " " + date2[0]
    }

    function starRating(star) {
        if (star === 1) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 2) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 3) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 4) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 5) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i></div>)
        }
    }

    let userNoReview = true;
    if (reviews.length > 0) {
        vendor && vendor.Reviews && vendor.Reviews.forEach(review => {
            if (user_id && Number(review.user_id) === Number(user_id)) userNoReview = false;
        });
    }

    // if (sessionUser && sessionUser.verified === 'pending') navigate('/pending')
    // if (!sessionUser) navigate('/login')
    if (!vendor) return null;
    if (!vendor.Reviews) return null;

   
    return (
        <div className='min-h-screen flex flex-col justify-between bg-gray-100'>

            <div className="max-container1 mt-10 md:mt-5 bg-gray-100">
                {vendor.name && !vendor.contact && <p className="text-lg font-semibold md:text-3xl mt-10 md:mt-28 ml-5 break-all">{vendor.name}</p>}
                {!vendor.name && vendor.contact && <h1 className="text-lg font-semibold md:text-3xl mt-10 md:mt-28 ml-5 break-all">{vendor.contact}</h1>}
                {vendor.name && vendor.contact && <span><span className="truncate text-lg font-semibold md:text-3xl mt-10 md:mt-28 ml-5 break-all">{vendor.contact} ,</span><span className="text-md md:text-3xl mt-28 sm:mt-28"> {vendor.name}</span></span>}
                <div class="container mx-auto my-2 p-5">
                    <div class="md:flex no-wrap md:mx-2 ">

                        <div class="w-full md:w-3/12 md:mx-2">

                            <div class="bg-white p-3 border-t-4 border-coral-red">

                                <div class="image overflow-hidden">
                                    <img class="h-auto w-full mx-auto"
                                        src={logo2 ? logo2.img : logo1 ? logo1.img : logoplaceholder}
                                        alt="" />
                                </div>

                                <ul
                                    class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">

                                    <li class="flex items-center py-3">
                                        <span>Avg Rating</span>
                                        <span class="ml-auto"></span>
                                        <span
                                            className="  bg-gray-900 py-1 px-2 rounded text-white text-sm">{Number(vendor.avgRating).toFixed(1)} </span>


                                    </li>
                                    <li class="flex items-center py-3">
                                        <span>No. of Reviews</span>
                                        <span class="ml-auto"></span>
                                        <span
                                            className="  bg-gray-900 py-1 px-2 rounded text-white text-sm">{vendor.Reviews.length} </span>


                                    </li>
                                </ul>
                            </div>
                            <div class="my-4"></div>
                        </div>
                        <div class="w-full md:w-9/12">
                            <div class="bg-white p-3 shadow-sm rounded-sm">
                                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                    <span clas="text-green-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </span>
                                    <span class="tracking-wide">About</span>
                                </div>
                                <div class="text-gray-700">
                                    <div class="grid md:grid-cols-2 text-sm">
                                        {vendor && vendor.contact && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Name</div>
                                            <div class="p-4 py-2 overflow-hidden">{vendor && vendor.contact}</div>
                                        </div>}
                                        {vendor && vendor.name && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Company</div>
                                            <div class="px-4 py-2 overflow-hidden">{vendor && vendor.name}</div>
                                        </div>}

                                        {vendor && vendor.phone1 && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Phone 1</div>
                                            <div class="px-4 py-2 overflow-hidden"><ButtonTel label={vendor.phone1} tel={"tel:" + vendor.phone1} /></div>
                                        </div>}
                                        {vendor && vendor.phone2 && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Phone 2</div>
                                            <div class="px-4 py-2 overflow-hidden"><ButtonTel label={vendor.phone2} tel={"tel:" + vendor.phone2} /></div>
                                        </div>}
                                        {vendor && vendor.email && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Email</div>
                                            <div class="px-4 py-2 overflow-hidden"><ButtonMailto label={vendor.email} mailto={"mailto:" + vendor.email} /></div>
                                        </div>}
                                        {vendor && vendor.website && <div class="grid grid-cols-2 items-center">
                                            <div class="px-4 py-2 font-semibold">Website</div>
                                            <div class="px-4 py-2 overflow-hidden"><a href={"https://" + vendor.website} target="_blank" rel="noreferrer">{vendor.website}</a></div>
                                        </div>}
                                        {vendor && vendor.facebook && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Facebook Page</div>
                                            <div class="px-4 py-2 overflow-hidden"><a href={"https://" + vendor.facebook} target="_blank" rel="noreferrer">{vendor.facebook}</a></div>
                                        </div>}
                                        {vendor && vendor.isresident == true && <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Resident</div>
                                            <div class="px-4 py-2 overflow-hidden"><img src={verified} width={25} alt="" /></div>
                                        </div>}
                                    </div>
                                </div>
                                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mt-10 flex-wrap">

                                    <img src={wrench} width={20} alt="" />
                                    <span className="tracking-wide">Skills: </span>
                                    {vendor.Catagories.map((cat, idx) => (
                                        <div className="flex"><span >{cat.catagory} {idx < vendor.Catagories.length - 1 && "|"} </span> </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="max-container1 md:pt-0 px-4 md:px-6 2xl:px-0 2xl:container 2xl:mx-auto flex justify-center items-center">

                    <div className="flex flex-col justify-start items-start w-full md:mx-10">
                        <div className="flex flex-col w-full bg-white py-5">
                            {vendor.type == "vendor" && <div className=" text-coral-red pb-5 px-5">*We do not verify if the technician / business is insured, licenced, experienced, and specialized to perform the job. Please, directly check with the vendors before you hire them.</div>}
                            {vendor.type == "service" && <div className=" text-coral-red pb-5 px-5">*We do not verify if the business / person is insured, licenced, experienced, and specialized to perform the job. Please, directly check with the business / person  before you hire them.</div>}
                            {vendor.type == "vendor" && <div className=" text-coral-red pb-5 px-5">*Before undertaking any project, ensure that all essential permits and authorizations are acquired by consulting with both the city authorities and your HOA (Riverstone or Sienna).</div>}
                            {catagory && Object.keys(catNotes).includes(catagory) && <div className=" text-coral-red mt-10 sm:mt-10 ml-5">{catNotes[catagory]}</div>}
                        </div>
                        <div className="flex justify-start items-start">
                            <p className="text-3xl lg:text-3xl font-semibold leading-7 mt-8  lg:mt-8 pb-3 lg:leading-9 text-gray-800">Reviews</p>
                        </div>

                        {sessionUser && userNoReview ? <div className="flex flex-col lg:flex-row gap-3 items-start"><div>Have you used this business or person for any of your needs, </div><Link to={`/addreview/${vendorId}`}><Button1 label='Post your Review' hover='hover:bg-coral-red' /></Link></div> : sessionUser && !userNoReview ? <div className="mb-5">You have already posted a review for this vendor. Visit your profile page to edit or delete the review</div>: !sessionUser && <div className="mb-5">Reviews can be posted only by residents. Signup or login to post a review for this business</div>}

                        {/* map this section */}
                        <div className="w-full">{vendor.Reviews && vendor.Reviews.sort((a, b) => a.rating > b.rating ? -1 : a.rating < b.rating ? 1 : 0) && vendor.Reviews.map((review, idx) => (
                            <div key={`${idx}_${review.id}`} className="w-full flex justify-start items-start flex-col bg-white p-6 border-2 border-gray-50 border-b-gray-200 mb-10">
                                <div className="flex flex-col md:flex-row justify-between w-full">
                                    <div className="flex flex-row justify-between items-start">
                                        <p className="text-xl md:text-xl font-medium  leading-normal text-gray-800 dark:text-white break-all">{review.title}</p>
                                    </div>
                                    <div>{starRating(review.rating)}</div>
                                </div>
                                <div id="menu" className="md:block">
                                    <p className="mt-3 text-base leading-normal text-gray-600 w-full">{review.review}</p>
                                    {/* md:w-9/12 xl:w-5/6 */}
                                    <div className="mt-6 flex justify-start items-center flex-row space-x-2.5">
                                        <div>
                                            {review.User.avatar == "ava1" && <img src={avatar1} width={"50px"} alt="" />}
                                            {review.User.avatar == "ava2" && <img src={avatar2} width={"50px"} alt="" />}
                                            {review.User.avatar == "ava3" && <img className="pb-2" src={avatar3} width={"50px"} alt="" />}
                                        </div>
                                        <div className="flex flex-col justify-start items-start space-y-2">
                                            <p className="font-medium leading-none text-gray-800">{review.User && review.User.username}</p>
                                            <p className="text-sm leading-none text-gray-600"> Reviewd on {datefield(review.createdAt)}</p>
                                            <p className="text-sm leading-none text-gray-600"> {review.username}</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-start gap-3 mt-3">
                                        {user_id && user_id === review.user_id ? <button className="bg-gray-800 hover:bg-coral-red text-white font-bold py-1 px-4 w-fit rounded" key={`${idx}_${review.id}`} onClick={() => {

                                            setModalData(review)
                                            handleShow()
                                        }}>Edit</button> : null}
                                        {user_id && user_id === review.user_id ? <button className="bg-gray-800 hover:bg-coral-red text-white font-bold py-1 px-4 w-fit rounded" key={`${idx}_${review.id}`} onClick={() => {
                                            setKey(review.id)
                                            handleShow1()
                                        }}>Delete</button> : null}

                                    </div>
                                    <div className="mt-5 flex flex-row gap-2">

                                    </div>

                                </div>
                            </div>
                        ))}</div>
                        {sessionUser && <div> <EditReviewModal key={modalData && modalData.id} modalData={modalData} handleClose={handleClose} handleShow={handleShow} show={show} toggleShow={toggleShow} title={title} setTitle={setTitle} /></div>}
                        {sessionUser && <div><DeleteReviewModal key={key} id={key} show1={show1} handleShow1={handleShow1} handleClose1={handleClose1} toggleShow1={toggleShow1} /></div> }
                    </div>
                </div>
                {/* map ends here */}
            </div>
            <Footer />
        </div>
    )
}
