import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../Footer'
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { fetchAddSale } from '../../store/sale';
import { getAllCatagories } from "../../store/catagories";

export const AddSale = (i) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let { state } = useLocation();
  let catagoryId = state.id
  
  const catagory = useSelector(state => state.catagory[catagoryId])
  const sessionUser = useSelector(state => state.session.user)

  let userId;
  if (sessionUser) {
    userId = sessionUser.id
  }


  useEffect(() => {
    dispatch(getAllCatagories())
  }, [dispatch])

  const [contactType, setContactType] = useState("realtor")
  const [title, setTitle] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("SugarLand")
  const [zip, setZip] = useState(77479)
  const [bedrooms, setBedrooms] = useState(1)
  const [bathrooms, setBathrooms] = useState(1)
  const [garage, setGarage] = useState("No garage")
  const [sqft, setSqft] = useState("")
  const [lot, setLot] = useState("")
  const [year, setYear] = useState("")
  const [price, setPrice] = useState("")
  const [weblink, setWeblink] = useState("")
  const [make, setMake] = useState("")
  const [model, setModel] = useState("")
  const [color, setColor] = useState("")
  const [mileage, setMileage] = useState("")
  const [realtorName, setRealtorName] = useState("")
  const [realtorCompany, setRealtorCompany] = useState("")
  const [realtorPhone, setRealtorPhone] = useState("")
  const [realtorEmail, setRealtorEmail] = useState("")
  const [img, setImg] = useState("")
  const [imageLoading, setImageLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [validationErrors, setValidationErrors] = useState({})

  useEffect(() => {
    const errors = {}
    if (title.length === 0) errors.title = "Title is required"
    if (title.length > 150) errors.title = "must be 150 characters or less"
    if (catagory.catagory === "Homes for Sale" && address.length === 0) errors.address = "address is required"
    if (address.length > 100) errors.address = "must be 100 characters or less"
    if (catagory.catagory === "Homes for Sale" && city.length === 0) errors.city = "city is required"
    if (city.length > 25) errors.city = "must be 25 characters or less"
    if (catagory.catagory === "Homes for Sale" && zip.length === 0) errors.zip = "zipcode is required"
    if (zip.length > 5 || zip.length < 5) errors.zip = "must be exactly 5 charcters"
    if (catagory.catagory === "Homes for Sale" && !bedrooms) errors.bedrooms = "number of bedrooms is required"
    if (catagory.catagory === "Homes for Sale" && !bathrooms) errors.bathrooms = "number of bathrooms is required"
    if (catagory.catagory === "Homes for Sale" && !garage) errors.garage = "garage is required"
    if (catagory.catagory === "Homes for Sale" && !sqft) errors.sqft = "square foot is required"
    if (catagory.catagory === "Homes for Sale" && (isNaN(sqft) || sqft <= 0)) errors.sqft = 'square foot has be above 0';
    if (sqft.length > 5) errors.sqft = "square foot has be five digits or less"
    if (catagory.catagory === "Homes for Sale" && !lot) errors.lot = "lot size is required"
    if (catagory.catagory === "Homes for Sale" && (isNaN(lot) || lot <= 0)) errors.lot = 'lot has be above 0';
    if (lot.length > 6) errors.lot = "sqft has be six digits or less";
    if (catagory.catagory === "Homes for Sale" && (!year || isNaN(year) || year < 1 || year.length !== 4 ) ) errors.year = "year is required"

    if (catagory.catagory === "Cars for sale" && (!year || isNaN(year) || year < 1 || year.length !== 4 ) ) errors.year = "valid year is required";
    if (price.length === 0) errors.price = 'Price is required';
    if (isNaN(price) || price <= 0) errors.price = 'Price has be above 0 dollars';
    if (price.length > 6) errors.price = 'Price has be a numeric dollar amount with maximum 6 digits including decimals';
    if (catagory.catagory === "Homes for Sale" && !weblink) errors.weblink = "Link to the listing is required";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && realtorName.length === 0) errors.realtorName = "Name is required";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && realtorName.length > 50) errors.realtorName = "Name must be 50 characters or less";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && realtorCompany.length > 50) errors.realtorCompany = "Company must be 50 characters or less";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && realtorPhone.length < 10) errors.realtorPhone = "Realtor contact information is required";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && (realtorPhone.length < 10 || isNaN(realtorPhone))) errors.realtorPhone = "Enter a valid phone number with no dashes, dots, brackets, or spaces";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && realtorEmail.length === 0) errors.realtorEmail = "Email is required";
    if (catagory.catagory === "Homes for Sale" && contactType === "realtor" && !(realtorEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g))) errors.realtorEmail = 'Not a valid email address';
    if (catagory.catagory === "Cars for sale" && make.length === 0) errors.make = "make is required"
    if (catagory.catagory === "Cars for sale" && model.length === 0) errors.model = "model is required"
    if (catagory.catagory === "Cars for sale" && color.length === 0) errors.color = "color is required"
    if (catagory.catagory === "Cars for sale" && mileage.length === 0) errors.mileage = "mileage is required"
    if (catagory.catagory === "Cars for sale" && isNaN(mileage)) errors.mileage = "enter a valid mileage"
    if (catagory.catagory === "Cars for sale" && mileage.length > 6) errors.mileage = "enter upto 6 digits without decimals"
    setValidationErrors(errors);


  }, [title, address, city, zip, bedrooms, bathrooms, garage, sqft, lot, year, price, weblink, make, model, color, mileage, realtorName, realtorPhone, realtorCompany, realtorEmail])

  const onOptionChange = e => {
    setContactType(e.target.value)
  }

  const handleCancel = () => {
    navigate('/events')
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("user_id", userId)
    formData.append("catagory_id", catagoryId)
    formData.append("type", catagory.catagory)
    formData.append("title", title)
    formData.append("streetAddress", address)
    formData.append("city", city)
    formData.append("zip", zip)
    formData.append("bedrooms", bedrooms)
    formData.append("bathrooms", bathrooms)
    formData.append("garage", garage)
    formData.append("sqft", sqft)
    formData.append("lot", lot)
    formData.append("year", year)
    formData.append("price", price)
    formData.append("weblink", weblink)
    formData.append("img", img)
    formData.append("contacttype", contactType)
    formData.append("contact", realtorName)
    formData.append("company", realtorCompany)
    formData.append("email", realtorEmail)
    formData.append("phone", realtorPhone)
    formData.append("make", make)
    formData.append("model", model)
    formData.append("mileage", mileage)
    formData.append("color", color)
    formData.append("notes", "")
    formData.append("status", "active")

    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;
    setImageLoading(true);

    let newSale = await dispatch(fetchAddSale(formData))

    if (newSale) {
      setContactType("realtor")
      setTitle("")
      setAddress("")
      setCity("")
      setZip("")
      setBedrooms("")
      setBathrooms("")
      setGarage("")
      setSqft("")
      setLot("")
      setYear("")
      setPrice("")
      setMake("")
      setModel("")
      setColor("")
      setMileage("")
      setRealtorName("")
      setRealtorPhone("")
      setRealtorCompany("")
      setRealtorEmail("")
      setImg(null)
      setImageLoading(false);
      navigate('/')
    }

    if(!catagory) return null;

  }
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div className="lg:w-[70%] max-container">
        <h2 className="mb-4 text-xl font-bold text-gray-900">Add {catagory.catagory}</h2>
        <div>
          <form onSubmit={onSubmit} className="bg-white border border-gray-300 rounded p-5 mx-10">
            <div className="grid gap-4 sm:grid-cols-6 sm:gap-6">
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-6 bg-slate-200 p-5 rounded">

                {catagory.catagory === "Homes for Sale" && <div className="">
                  <div className="flex gap-5 mb-3">
                    <div className="flex flex-row gap-3 items-center">
                      <input
                        type='radio'
                        name="realtor"
                        id="realtor"
                        value="realtor"
                        checked={contactType === "realtor"}
                        onChange={onOptionChange} />
                      <label class="block text-sm font-medium text-gray-900" for="realtor" >Sale by Realtor</label>
                    </div>

                    <div className="flex gap-3 items-center">
                      <input
                        type='radio'
                        name="owner"
                        id="owner"
                        value="owner"
                        checked={contactType === "owner"}
                        onChange={onOptionChange} />
                      <label class="block text-sm font-medium text-gray-900" for="owner" >Sale by Owner</label>
                    </div>
                  </div>

                  {contactType === "realtor" &&
                    <div className="flex flex-col lg:flex-row gap-5 w-full">
                      <div className="flex flex-col w-full">
                        <label for="realtorname" className="block mb-2 text-sm font-medium text-gray-900">Realtor Name: </label>
                        <input type="text" name="realtorname" id="realtorname" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="John Doe" required value={realtorName} onChange={(e) => setRealtorName(e.target.value)} />
                      </div>
                      <div className="flex flex-col w-full">
                        <label for="realtorname" className="block mb-2 text-sm font-medium text-gray-900">Realtor company(optional): </label>
                        <input type="text" name="realtorCompany" id="realtorCompany" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="ABC Realty" value={realtorCompany} onChange={(e) => setRealtorCompany(e.target.value)} />
                      </div>
                      <div className="flex flex-col w-full">
                        <label for="realtorname" className="block mb-2 text-sm font-medium text-gray-900">Realtor phone: </label>
                        <input type="text" name="realtorPhone" id="realtorPhone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="10 digits without any dashes or space" value={realtorPhone} onChange={(e) => setRealtorPhone(e.target.value)} />
                      </div>
                      <div className="flex flex-col w-full">
                        <label for="realtorname" className="block mb-2 text-sm font-medium text-gray-900">Realtor email: </label>
                        <input type="text" name="realtorEmail" id="realtorEmail" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="ex: johndoe@abcrealty.com" value={realtorEmail} onChange={(e) => setRealtorEmail(e.target.value)} />
                      </div>
                      <div className="py-5">
                        <p className='text-coral-red text-sm'> {hasSubmitted && validationErrors.realtorName && `${validationErrors.realtorName}`}</p>
                        <p className='text-coral-red text-sm'> {hasSubmitted && validationErrors.realtorCompany && `${validationErrors.realtorCompany}`}</p>
                        <p className='text-coral-red text-sm'> {hasSubmitted && validationErrors.realtorPhone && `${validationErrors.realtorPhone}`}</p>
                        <p className='text-coral-red text-sm'> {hasSubmitted && validationErrors.realtorEmail && `${validationErrors.realtorEmail}`}</p>
                      </div>
                    </div>
                  }
                  {contactType === "owner" && <p className="w-full">No contact information is required. Interested people will contact you using the email on file.</p>
                  }
                </div>}
              </div>}

              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-6">
                <label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label><span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
                <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Beautiful and spacious 4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={title} required onChange={(e) => setTitle(e.target.value)} />
              </div>}
              {catagory.catagory === "Cars for Sale" && <div className="sm:col-span-6">
                <span><label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label></span>
                <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="2021 Toyota Camry in Good condittion, less mileage" value={title} required onChange={(e) => setTitle(e.target.value)} />
                <span className='text-coral-red text-sm'>{hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
              </div>}
              {catagory.catagory === "Items for Sale" && <div className="sm:col-span-6">
                <span><label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label></span>
                <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="2021 Toyota Camry in Good condittion, less mileage" value={title} required onChange={(e) => setTitle(e.target.value)} />
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <div><span><label for="address" className="block mb-2 text-sm font-medium text-gray-900">Street Address</label></span></div>
                <input type="text" name="address" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="123 Street Name" value={address} required onChange={(e) => setAddress(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.address && `${validationErrors.address}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="city" className="block mb-2 text-sm font-medium text-gray-900">City</label></span>
                <input type="text" name="city" id="city" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Sugar Land" value={city} required onChange={(e) => setCity(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.city && `${validationErrors.city}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="zip" className="block mb-2 text-sm font-medium text-gray-900">Zip</label></span>
                <input type="text" name="zip" id="zip" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="77479" value={zip} required onChange={(e) => setZip(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.zip && `${validationErrors.zip}`}</span>
              </div>}

              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="bedrooms" className="block mb-2 text-sm font-medium text-gray-900">Bedrooms</label></span>
                <select type="text" name="bedrooms" id="bedrooms" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={bedrooms} required onChange={(e) => setBedrooms(e.target.value)}>
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.bedrooms && `${validationErrors.bedrooms}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="bathrooms" className="block mb-2 text-sm font-medium text-gray-900">Bathrooms</label></span>
                <select name="bathrooms" id="bathrooms" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={bathrooms} required onChange={(e) => setBathrooms(e.target.value)}>
                  <option value=""></option>
                  <option value="1">1</option>
                  <option value="1">1.5</option>
                  <option value="2">2</option>
                  <option value="1">2.5</option>
                  <option value="3">3</option>
                  <option value="1">3.5</option>
                  <option value="4">4</option>
                  <option value="1">4.5</option>
                  <option value="5">5</option>
                  <option value="1">5.5</option>
                  <option value="6">6</option>
                  <option value="1">6.5</option>
                  <option value="7">7</option>
                  <option value="1">7.5</option>
                  <option value="8">8</option>
                  <option value="1">8.5</option>
                  <option value="9">9</option>
                  <option value="1">9.5</option>
                  <option value="10">10</option>
                </select>
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.bathrooms && `${validationErrors.bathrooms}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="garage" className="block mb-2 text-sm font-medium text-gray-900">Garage</label></span>
                <select name="garage" id="garage" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={garage}  required onChange={(e) => setGarage(e.target.value)}>
                  <option value=""></option>
                  <option value="No garage">No Garage</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.garage && `${validationErrors.garage}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="sqft" className="block mb-2 text-sm font-medium text-gray-900">Sqft</label></span>
                <input type="text" name="sqft" id="sqft" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: 2840" value={sqft} required onChange={(e) => setSqft(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.sqft && `${validationErrors.sqft}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-2">
                <span><label for="lot" className="block mb-2 text-sm font-medium text-gray-900">Lot size</label></span>
                <input type="text" name="lot" id="lot" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: 8000" value={lot} required onChange={(e) => setLot(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.lot && `${validationErrors.lot}`}</span>
              </div>}
              {catagory.catagory === "Homes for Sale" && <div className="w-full">
                <span><label for="year" className="block mb-2 text-sm font-medium text-gray-900">Year built</label></span>
                <input type="text" name="year" id="year" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: 1999" value={year} required onChange={(e) => setYear(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.year && `${validationErrors.year}`}</span>
              </div>}
              {catagory.catagory === "Cars for sale" && <div className="w-full">
                <span><label for="make" className="block mb-2 text-sm font-medium text-gray-900">Make</label></span>
                <input type="text" name="make" id="make" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: Toyota" value={make} required onChange={(e) => setMake(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.make && `${validationErrors.make}`}</span>
              </div>}
              {catagory.catagory === "Cars for sale" && <div className="w-full">
                <span><label for="model" className="block mb-2 text-sm font-medium text-gray-900">Model</label></span>
                <input type="text" name="model" id="model" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: Camry"  value={model} required onChange={(e) => setModel(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.model && `${validationErrors.model}`}</span>
              </div>}
              {catagory.catagory === "Cars for sale" && <div className="w-full">
                <span><label for="model" className="block mb-2 text-sm font-medium text-gray-900">Color</label></span>
                <input type="text" name="color" id="color" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: Red" value={color} required onChange={(e) => setColor(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.color && `${validationErrors.color}`}</span>
              </div>}
              {catagory.catagory === "Cars for sale" && <div className="w-full">
                <span><label for="mileage" className="block mb-2 text-sm font-medium text-gray-900">Mileage</label></span>
                <input type="text" name="mileage" id="mileage" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: 74246" value={mileage} required onChange={(e) => setMileage(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.mileage && `${validationErrors.mileage}`}</span>
              </div>}
              {catagory.catagory === "Cars for sale" && <div className="w-full">
                <span><label for="year" className="block mb-2 text-sm font-medium text-gray-900">Year</label></span>
                <input type="text" name="year" id="year" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="eg: 1999" value={year} required onChange={(e) => setYear(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.year && `${validationErrors.year}`}</span>
              </div>}
              <div className="w-full">
                <span><label for="price" className="block mb-2 text-sm font-medium text-gray-900">Price</label></span>
                <input type="text" name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="499,000" value={price} required onChange={(e) => setPrice(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.price && `${validationErrors.price}`}</span>
              </div>
              {catagory.catagory === "Homes for Sale" && <div className="sm:col-span-6">
                <span><label for=" weblink" className="block mb-2 text-sm font-medium text-gray-900">MLS Listing</label></span>
                <input type="text" name="weblink" id="weblink" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="www.website.com" value={weblink} required onChange={(e) => setWeblink(e.target.value)} />
                <span className='text-coral-red text-sm'> {hasSubmitted && validationErrors.weblink && `${validationErrors.weblink}`}</span>
              </div>}
              {catagory.catagory === "Cars for sale" && <div className="sm:col-span-6">
                <span><label for=" weblink" className="block mb-2 text-sm font-medium text-gray-900">Car Listing (optional)</label></span>
                <p className="text-sm text-gray-500">If you have listed your car for sale on any website, add the link here.</p>
                <input type="text" name="weblink" id="weblink" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="www.carmax.com" value={weblink} onChange={(e) => setWeblink(e.target.value)} />

              </div>}

              {(catagory.catagory === "Homes for Sale" || catagory.catagory === "Cars for sale") && <div className="sm:col-span-6">
                <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload Image</label><span className='text-coral-red'></span>
                {catagory.catagory === "Cars for sale" && <p className="text-sm text-gray-500">Upload a full exterior picture of your vehicle.</p>}
                {catagory.catagory === "Homes for Sale" && <p className="text-sm text-gray-500">Upload a front exterior picture of your home.</p>}
                <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" required onChange={(e) => setImg(e.target.files[0])} />
                {/* <div className="mt-1 text-sm text-gray-500" id="user_avatar_help">Add a front exterior picture of your property</div> */}
              </div>}

              {catagory.catagory === "Items for Sale" && <div className="sm:col-span-5">
                <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload Image</label><span className='text-coral-red'></span>
                <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" required onChange={(e) => setImg(e.target.files[0])} />
                <p>Upload a picture of the item you are selling.</p>

              </div>}

            </div>
            <button type="submit" className=" items-center px-5 py-2.5 mt-4 mr-5 sm:mt-6 text-sm font-medium text-center text-white bg-gray-900 rounded-lg     focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
              Submit Post
            </button>
            <button
              onClick={handleCancel}
              className="items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-coral-red rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">Cancel</button>
            {(imageLoading) && <p>Loading...</p>}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
}
