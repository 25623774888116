import React from 'react';
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { logout } from "../store/session";
import { profile, logo } from '../assets/icons';
import { avatar1, avatar2, avatar3 } from '../assets/icons';

import { avatars } from '../constants'

// import {hamburger} from '../assets/images'
// import { LoginPage } from './LoginPage';





function Navigation() {

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const sessionUser = useSelector(state => state.session.user);

  let user_id;
  if (sessionUser) {
    user_id = sessionUser.id
  }

  let user_type;
  if (sessionUser) {
    user_type = sessionUser.type;
  }

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    setToggle(!toggle);
    navigate('/')
  };

  const [toggle, setToggle] = useState(false);

  const showNav = () => {
    setToggle(!toggle);
  };

  function findImage(value) {
    let [result] = avatars.filter(ele => ele.name == value)
    return result.img ? result.img : null;
  }

  const navbarData = [
    {
      title: "Home",
      href: "/",
      cname:
        "border-t font-medium w-full flex justify-center p-2.5 mt-3 lg:border-none lg:p-0 lg:mt-0 lg:w-auto",
      access: 'all'

    },
    {
      title: "Home Services",
      href: "/catagories",
      cname:
        "border-t font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
      access: 'all'
    },
    {
      title: "Location Services",
      href: "/services",
      cname:
        "border-t font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
      access: 'all'
    },
    // {
    //   title: "Events",
    //   href: "/events",
    //   cname:
    //     "border-t font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
    //     access: 'all'
    // },
    // {
    //   title: "Businesses",
    //   href: "/businesslist",
    //   cname:
    //     "border-t font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
    //     access: 'all'
    // },
    // {
    //   title: "For Sale",
    //   href: "/sale",
    //   cname:
    //     "border-t font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
    //     access: 'all'
    // },

    {
      title: "About",
      href: "/legal/aboutus",
      cname:
        "border-t font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
      access: 'all'
    },
    {
      title: "Contact",
      href: "/contact",
      cname:
        "border-t border-b font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto",
      access: 'all'
    },
    // {
    //   title: <div className="hidden lg:block"><i class="fa-solid fa-magnifying-glass"></i></div>,
    //   href: "/search",
    //   cname:"hidden lg:block lg:border-none lg:p-0 lg:w-auto",
    //     access: 'all'
    // },

    // {
    //   title: "",
    //   href: "",
    //   cname:""
    // "border-t border-b font-medium w-full flex justify-center p-2.5 md:border-none md:p-0 md:w-auto"
    // },

    {
      title: sessionUser && sessionUser.avatar == 'ava1' && <img src={avatar1} width={35} alt="img" /> || sessionUser && sessionUser.avatar === 'ava2' && <img src={avatar2} width={35} alt="img" /> || sessionUser && sessionUser.avatar === 'ava3' && <img src={avatar3} width={35} alt="img" /> || sessionUser && sessionUser.avatar === 'ava0' && <img src={profile} width={30} alt="img" /> || sessionUser && sessionUser.avatar === 'avatar1' && <img src={profile} width={30} alt="img" />,
      href: user_id ? "/profilepage" : null,
      cname:
        user_id ? " border-b font-medium w-full flex justify-center p-2.5 lg:border-none lg:p-0 lg:w-auto" : null,
      access: 'all'
    },


  ];

  // const onToggleMenu=(e)=> {
  //   e.name = e.name === 'menu' ? 'close' : 'menu'
  //   // navLinks.classList.toggle('top-[9%]')

  //   return(
  //     <header class="bg-white">
  //         <nav class="flex justify-between items-center w-[92%]  mx-auto">
  //             <div>
  //                 <img class="w-16 cursor-pointer" src="https://cdn-icons-png.flaticon.com/512/5968/5968204.png" alt="..."/>
  //             </div>
  //             <div
  //                 class="nav-links duration-500 md:static absolute bg-white md:min-h-fit min-h-[60vh] left-0 top-[-100%] md:w-auto  w-full flex items-center px-5">
  //                 <ul class="flex md:flex-row flex-col md:items-center md:gap-[4vw] gap-8">
  //                     <li>
  //                         <a class="hover:text-gray-500" href="#">Products</a>
  //                     </li>
  //                     <li>
  //                         <a class="hover:text-gray-500" href="#">Solution</a>
  //                     </li>
  //                     <li>
  //                         <a class="hover:text-gray-500" href="#">Resource</a>
  //                     </li>
  //                     <li>
  //                         <a class="hover:text-gray-500" href="#">Developers</a>
  //                     </li>
  //                     <li>
  //                         <a class="hover:text-gray-500" href="#">Pricing</a>
  //                     </li>
  //                 </ul>
  //             </div>
  //             <div class="flex items-center gap-6">
  //                 <button class="bg-[#a6c1ee] text-white px-5 py-2 rounded-full hover:bg-[#87acec]">Sign in</button>
  //                 <button onclick= {onToggleMenu} name="menu" class="text-3xl cursor-pointer md:hidden"><img src={hamburger} width={25} height={25}/></button>
  //             </div>
  //             </nav>
  //     </header>
  //   )
  // }


  return (
    <nav className="sticky top-0 w-full bg-white items-center flex justify-center p-4">
      <div className="flex justify-between sm:justify-between items-center w-full flex-wrap lg:flex-nowrap max-container1">

        <a href="/" className="flex items-center">

          <div> <span className="self-center text-2xl md:text-3xl font-regular  font-rochester whitespace-nowrap text-black">Sienna</span><span className="self-center text-2xl md:text-3xl font-regular font-rochester whitespace-nowrap text-coral-red"> & </span><span className="self-center text-2xl md:text-3xl font-regular pr-10 whitespace-nowrap font-rochester text-black">Riverstone</span><div className="text-xs"></div></div>
        </a>

        <button
          className="flex justify-end lg:hidden ring-1 ring-white rounded"
          onClick={showNav}
        >
          {!toggle ? <i className="fas fa-bars text-black w-9 h-9 flex justify-center items-center hover:text-blue"></i> : <i className="fas fa-x text-black w-9 h-9 flex justify-center items-center hover:text-blue"></i>}
        </button>


        <ul
          className={`${toggle ? " flex" : " hidden"
            } flex-col justify-center items-center w-full first:mt-2 lg:flex-row  lg:w-auto lg:space-x-10 lg:flex lg:pt-2`}
        >

          {navbarData.map((link, index) => {
            return (
              <li key={index} className={link.cname}>
                <NavLink
                  className="hover:text-coral-red"
                  // activeStyle={{ color:'text-coral-red' }}
                  to={link.href}
                  onClick={showNav}
                >
                  {link.title}
                </NavLink>
              </li>
            );
          })}
        </ul>

        {/* <button onClick={<Link to="/login"></Link>}
          className={`${
            toggle ? " flex" : " hidden"
          } text-gray hover:bg-gray-300 mx-auto md:mx-0 md:flex md:mt-0 items-center justify-center font-medium bg-gray-100 px-1 p-2 rounded-lg mt-4 w-24`}
        >
          Login
        </button> */}

        {/* <NavLink to="/search"><i class="fa-solid fa-magnifying-glass"></i></NavLink> */}

        {user_type === 'admin' ? <NavLink className={`${toggle ? " flex" : " hidden"
          } text-gray mx-auto lg:mx-0 lg:flex lg:mt-0 items-center justify-center font-medium px-1 p-2 rounded-lg mt-3 w-full lg:w-24 border-b lg:border-none`} to={'/admin/dashboard'}>Admin</NavLink> : null}

        {/* {user_id? <Link to ={'/profilepage'}><img src={profile} width={30}/></Link>:null} */}
        {user_id ? <button className={`${toggle ? " flex" : " hidden"
          } text-gray mx-auto lg:mx-0 lg:flex lg:mt-0 items-center justify-center font-medium px-1 p-2 rounded-lg mt-3 w-full lg:w-24 border-b lg:pt-4 lg:border-none`} onClick={handleLogout} >Log Out</button> : <Link to='/login' onClick={showNav} className={`${toggle ? " flex" : " hidden"
            } text-gray mx-auto lg:mx-0 lg:flex lg:mt-0 items-center justify-center font-medium px-1 p-2 rounded-lg mt-3 w-full lg:w-24 border-b lg:pt-4 lg:border-none`}>LogIn</Link>}
      </div>


    </nav>

  );
}





export default Navigation