import React, { useEffect } from 'react'
import Footer from '../Footer'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBusinesses } from '../../store/businesses'
import { getAllBusinessimages } from '../../store/businessimages'
import { getAllBusinesshours } from '../../store/businesshours'
import { getAllBusinessfeatures } from '../../store/businessfeatures'

export const BusinessDetails = () => {

	const id = useParams().id;
	const dispatch = useDispatch()

	const businessDetail = useSelector(state => state.business[id]);
	const businessImages = useSelector(state => Object.values(state.businessImages));
	const businessHours = useSelector(state => Object.values(state.businessHours))
	const businessFeatures = useSelector(state => Object.values(state.businessFeatures))

	const myImages = businessImages.filter((image) => image.business_id === Number(id));
	const headerImg = myImages.filter((img) => img.imgtype === 'header')

	useEffect(() => {
		dispatch(getAllBusinesses())
	}, [dispatch])

	useEffect(() => {
		dispatch(getAllBusinessimages())
	}, [dispatch])

	useEffect(() => {
		dispatch(getAllBusinesshours())
	}, [dispatch])

	useEffect(() => {
		dispatch(getAllBusinessfeatures())
	}, [dispatch])



	if (!businessDetail) return null
	return (
		<div classNameName="h-screen flex flex-col justify-between">
			<div classNameName="max-container">
				{/* <div classNameName="w-full m-0 p-0 bg-cover bg-bottom bg-[image:var(--image-url)]" style={{'--image-url': `url(${cover})`, height:'400px'}} > */}
				<div
					className="max-container p-0 bg-cover bg-center" style={{ backgroundImage: `url(${headerImg[0] && headerImg[0].img})`, height: '400px' }}
				>
					<div className="container max-w-4xl mx-auto pt-16 md:pt-32 text-center break-normal ">
						<p className="text-white font-extrabold text-3xl md:text-5xl">
							{businessDetail.name}
						</p>
						<p className="text-xl md:text-2xl text-gray-500">{businessDetail.subtitle}</p>
					</div>
				</div>


				<div className="container px-4 md:px-0 max-w-6xl mx-auto -mt-32">

					<div className="mx-0 sm:mx-6">


						{/* <nav className="mt-0 w-full">
							<div className="container mx-auto flex items-center">

								<div className="flex w-1/2 pl-4 text-sm">
									<ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
										<li className="mr-2">
											<a className="inline-block py-2 px-2 text-white no-underline hover:underline" href="">Hours</a>
										</li>
										<li className="mr-2">
											<a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2" href="multimenu post.html">Specials</a>
										</li>
										<li className="mr-2">
											<a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2" href="/">Products</a>
										</li>
										<li className="mr-2">
											<a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:underline py-2 px-2" href="post_vue.html">Links</a>
										</li>
									</ul>
								</div>


								<div className="flex w-1/2 justify-end content-center">
									<a className="inline-block text-gray-500 no-underline hover:text-white hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 avatar" data-tippy-content="@twitter_handle" href="https://twitter.com/intent/tweet?url=#">
										<svg className="fill-current h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M30.063 7.313c-.813 1.125-1.75 2.125-2.875 2.938v.75c0 1.563-.188 3.125-.688 4.625a15.088 15.088 0 0 1-2.063 4.438c-.875 1.438-2 2.688-3.25 3.813a15.015 15.015 0 0 1-4.625 2.563c-1.813.688-3.75 1-5.75 1-3.25 0-6.188-.875-8.875-2.625.438.063.875.125 1.375.125 2.688 0 5.063-.875 7.188-2.5-1.25 0-2.375-.375-3.375-1.125s-1.688-1.688-2.063-2.875c.438.063.813.125 1.125.125.5 0 1-.063 1.5-.25-1.313-.25-2.438-.938-3.313-1.938a5.673 5.673 0 0 1-1.313-3.688v-.063c.813.438 1.688.688 2.625.688a5.228 5.228 0 0 1-1.875-2c-.5-.875-.688-1.813-.688-2.75 0-1.063.25-2.063.75-2.938 1.438 1.75 3.188 3.188 5.25 4.25s4.313 1.688 6.688 1.813a5.579 5.579 0 0 1 1.5-5.438c1.125-1.125 2.5-1.688 4.125-1.688s3.063.625 4.188 1.813a11.48 11.48 0 0 0 3.688-1.375c-.438 1.375-1.313 2.438-2.563 3.188 1.125-.125 2.188-.438 3.313-.875z"></path></svg>
									</a>
									<a className="inline-block text-gray-500 no-underline hover:text-white hover:text-underline text-center h-10 p-2 md:h-auto md:p-4 avatar" data-tippy-content="#facebook_id" href="https://www.facebook.com/sharer/sharer.php?u=#">
										<svg className="fill-current h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"></path></svg>
									</a>
								</div>

							</div>
						</nav> */}

						<div className="bg-gray-200 w-full text-xl md:text-2xl text-gray-800 leading-normal rounded-t">


							<div className="flex flex-col lg:flex-row bg-white justify-evenly gap-10">
								{/* <a href="post.html" className="flex flex-wrap no-underline hover:no-underline"> */}
								{/* <div className="w-full md:w-2/3 rounded-t">	
							<img src="https://source.unsplash.com/collection/494263/800x600" className="h-full w-full shadow"/>
						</div> */}

								<div className="bg-white"><p className=" text-gray-900 text-xs text-center md:text-sm pt-6 px-6">OUR ADDRESS</p>
									<p className=" text-gray-600 text-xs text-center md:text-sm pt-3 px-6">{businessDetail.address}, {businessDetail.city}, {businessDetail.state} {businessDetail.zip}</p>
									<p className=" text-gray-600 text-xs text-center md:text-sm pt-6 px-6">PHONE</p>
									<p className=" text-gray-600 text-xs text-center md:text-sm pt-2 px-6">{businessDetail.phone1}</p>
									<p className=" text-gray-600 text-xs text-center md:text-sm pt-6 px-6">EMAIL</p>
									<p className=" text-gray-600 text-xs text-center md:text-sm pt-2 px-6">{businessDetail.email}</p>
									<p className=" text-gray-600 text-xs text-center md:text-sm py-6 px-6">VISIT US ONLINE AT: {businessDetail.website}</p>
									<div className="flex justify-center gap-10 pb-5">
										<Link to={businessDetail.facebook}><div className="w-11 h-11 flex 
    
									justify-center items-center
									bg-coral-red rounded-full">
											<img src={"https://myaaprojects.s3.us-east-2.amazonaws.com/rstxapp/socialicon/facebook.png"} alt="facebook" width={24} height={24} />



										</div></Link>
										<div className="w-11 h-11 flex 
										justify-center items-center
										bg-coral-red rounded-full">
											<img src={"https://myaaprojects.s3.us-east-2.amazonaws.com/rstxapp/socialicon/instagram.png"} alt="facebook" width={24} height={24} />



										</div>
										<div className="w-11 h-11 flex 
										justify-center items-center
										bg-coral-red rounded-full">
											<img src={"https://myaaprojects.s3.us-east-2.amazonaws.com/rstxapp/socialicon/twitter.png"} alt="facebook" width={24} height={24} />



										</div>
										<div className="w-11 h-11 flex 
										justify-center items-center
										bg-coral-red rounded-full">

											<img src={"https://myaaprojects.s3.us-east-2.amazonaws.com/rstxapp/socialicon/yelp.png"} alt="facebook" width={24} height={24} />

										</div>

									</div>
								</div>

								<div className="border"></div>
								<div className="bg-white">
									<p className=" text-gray-900 text-xs text-center md:text-sm py-5 px-6">STORE HOURS</p>
									{businessHours.map(hour => (

										<div className="text-sm pb-2">{hour.day}: {hour.start} - {hour.end}</div>
									))}
									{/* <p className="text-gray-800 font-serif text-base pt-3 px-10 mb-3">
									Monday: hgjhgjhgjgjhjghgjhgj
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Tuesday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Wednesday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Thursday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Friday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Saturday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-5">
									Sunday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-1">
									Lunch Hours: 
								</p>< */}
								</div>
								{/* <div className="flex flex-col">
								{/* <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden pb-3 shadow-md"> */}
								{/* <p className=" text-gray-600 text-xs text-center md:text-sm pt-6 px-6">OUR ADDRESS</p>
								<p className=" text-gray-600 text-xs text-center md:text-sm pt-3 px-6">{businessDetail.address}, {businessDetail.city}, {businessDetail.state} {businessDetail.zip}</p>
								<p className=" text-gray-600 text-xs text-center md:text-sm pt-6 px-6">PHONE</p>
								<p className=" text-gray-600 text-xs text-center md:text-sm pt-2 px-6">{businessDetail.phone1}</p>
								<p className=" text-gray-600 text-xs text-center md:text-sm pt-6 px-6">EMAIL</p>
								<p className=" text-gray-600 text-xs text-center md:text-sm pt-2 px-6">{businessDetail.email}</p>
								<p className=" text-gray-600 text-xs text-center md:text-sm pt-6 px-6">VISIT US ONLINE AT: {businessDetail.website}</p> */}
								{/* </div> */}

								{/* <div className="flex flex-col">
								<p className=" text-gray-600 text-xs md:text-sm text-center pt-8 px-6">STORE HOURS</p> */}
								{/* <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg"> */}

								{/* <p className="text-gray-800 font-serif text-base pt-3 px-10 mb-3">
									Monday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Tuesday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Wednesday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Thursday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Friday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-3">
									Saturday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-5">
									Sunday: 
								</p>
								<p className="text-gray-800 font-serif text-base px-10 mb-1">
									Lunch Hours: 
								</p> */}
								{/* </div> */}
							</div>

							{/* <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
								<div className="flex items-center justify-between"> */}
							{/* <img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author"/> */}
							{/* <p className="text-gray-600 text-xs md:text-sm">1 MIN READ</p> */}
							{/* </div> */}
							{/* </div> */}
							{/* </div> */}

							{/* </a> */}
							{/* </div> */}




							<div className="flex flex-wrap justify-between pt-12 -mx-6">


								<div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/home" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[0] ? <img src={businessFeatures[0].img} className="h-64 w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-64 w-full rounded-t pb-6" />}
											{businessFeatures[0] ? <p className="w-full text-gray-600 text-xs md:text-sm px-6">{businessFeatures[0].text1}</p> : <p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>}
											{businessFeatures[0] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[0].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 1</div>}
											{businessFeatures[0] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[0].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[0] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[0].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">1 MIN READ</p>}
										</div>
									</div>
								</div>

								<div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[1] ? <img src={businessFeatures[1].img} className="h-64 w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-64 w-full rounded-t pb-6" />}
											{businessFeatures[1] ? <p className="w-full text-gray-600 text-xs md:text-sm px-6">{businessFeatures[1].text1}</p> : <p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>}
											{businessFeatures[1] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[1].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 2</div>}
											{businessFeatures[1] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[1].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[1] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[1].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">1 MIN READ</p>}
										</div>
									</div>
								</div>


								<div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[2] ? <img src={businessFeatures[2].img} className="h-64 w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-64 w-full rounded-t pb-6" />}
											{businessFeatures[2] ? <p className="w-full text-gray-600 text-xs md:text-sm px-6">{businessFeatures[2].text1}</p> : <p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>}
											{businessFeatures[2] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[2].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 3</div>}
											{businessFeatures[2] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[2].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[2] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[2].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">1 MIN READ</p>}
										</div>
									</div>
								</div>



								<div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[3] ? <img src={businessFeatures[3].img} className="h-full w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-full w-full rounded-t pb-6" />}

											{businessFeatures[3] ? <p className="w-full text-gray-600 text-xs md:text-sm px-6">{businessFeatures[3].text1}</p> : <p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>}
											{businessFeatures[3] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[3].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 4</div>}
											{businessFeatures[3] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[2].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[3] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[3].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">FIND OUT</p>}
										</div>
									</div>
								</div>


								<div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 flex-row bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[4] ? <img src={businessFeatures[4].img} className="h-full w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-full w-full rounded-t pb-6" />}
											{businessFeatures[4] ? <p className="w-full text-gray-600 text-xs md:text-sm px-6">{businessFeatures[4].text1}</p> : <p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>}
											{businessFeatures[4] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[4].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 5</div>}
											{businessFeatures[4] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[2].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[4] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[4].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">FIND OUT</p>}
										</div>
									</div>
								</div>




								<div className="w-full md:w-2/3 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[5] ? <img src={businessFeatures[5].img} className="h-full w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-full w-full rounded-t pb-6" />}

											{/* <img src="https://source.unsplash.com/collection/325867/800x600" className="h-full w-full rounded-t pb-6"/> */}
											{businessFeatures[5] ? <p className="w-full text-gray-600 text-xs md:text-sm px-6">{businessFeatures[5].text1}</p> : <p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>}
											{businessFeatures[5] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[5].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 6</div>}
											{businessFeatures[5] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[2].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[5] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[5].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">FIND OUT</p>}
										</div>
									</div>
								</div>


								<div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
									<div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
										<a href="/" className="flex flex-wrap no-underline hover:no-underline">
											{businessFeatures[6] ? <img src={businessFeatures[6].img} className="h-full w-full rounded-t pb-6" /> : <img src="https://source.unsplash.com/collection/225/800x600" className="h-full w-full rounded-t pb-6" />}

											<p className="w-full text-gray-600 text-xs md:text-sm px-6">GETTING STARTED</p>
											{businessFeatures[6] ? <div className="w-full font-bold text-xl text-gray-900 px-6">{businessFeatures[6].text2}</div> : <div className="w-full font-bold text-xl text-gray-900 px-6">Feature 6</div>}
											{businessFeatures[6] ? <p className="text-gray-800 font-serif text-base px-6 mb-5">
												{businessFeatures[6].text3}
											</p> : <p className="text-gray-800 font-serif text-base px-6 mb-5">
												Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu nunc commodo posuere et sit amet ligula.
											</p>}
										</a>
									</div>
									<div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
										<div className="flex items-center justify-between">
											<img className="w-8 h-8 rounded-full mr-4 avatar" data-tippy-content="Author Name" src="http://i.pravatar.cc/300" alt="Avatar of Author" />
											{businessFeatures[6] ? <p className="text-gray-600 text-xs md:text-sm"><Link to={businessFeatures[6].text4}>FIND OUT</Link></p> : <p className="text-gray-600 text-xs md:text-sm">FIND OUT</p>}
										</div>
									</div>
								</div>

							</div>


						</div>



						<div className="container font-sans bg-green-100 rounded mt-8 p-4 md:p-24 text-center">
							<h2 className="font-bold break-normal text-2xl md:text-4xl">Subscribe to Ghostwind CSS</h2>
							<h3 className="font-bold break-normal font-normal text-gray-600 text-base md:text-xl">Get the latest posts delivered right to your inbox</h3>
							<div className="w-full text-center pt-4">
								<form action="#">
									<div className="max-w-xl mx-auto p-1 pr-0 flex flex-wrap items-center">
										<input type="email" placeholder="youremail@example.com" className="flex-1 appearance-none rounded shadow p-3 text-gray-600 mr-2 focus:outline-none" />
										<button type="submit" className="flex-1 mt-4 md:mt-0 block md:inline-block appearance-none bg-green-500 text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow hover:bg-green-400">Subscribe</button>
									</div>
								</form>
							</div>
						</div>




						<div className="flex w-full items-start my-10 font-sans">
							{/* <img className="w-10 h-10 rounded-full mr-4" src="http://i.pravatar.cc/300" alt="Avatar of Author"/> */}
							<div className="flex-1">
								<Link to={'/'}><p className=" font-bold md:text-xl">Return to RS TX App</p></Link>
								{/* <p className="text-gray-600 text-xs md:text-base">Tailwind CSS version of Ghost's Casper theme by <a className="text-gray-800 hover:text-green-500 no-underline border-b-2 border-green-500" href="https://www.tailwindtoolbox.com">TailwindToolbox.com</a></p> */}
							</div>
							<div className="justify-end">

							</div>
						</div>


					</div>


				</div>


			</div>

		</div>

	)
}
