import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllCart } from '../../store/cart'
import { getAllCartitems } from '../../store/cartitem'
import { loadStripe } from '@stripe/stripe-js'
import { csrfFetch } from '../../store/csrf'
import Footer from '../Footer'
import { Button1 } from '../Button/Button1'
export const Cart = () => {
  const id = useParams().id
  const dispatch = useDispatch()
  const cart = useSelector(state => Object.values(state.cart) || null)
  // const [myCart] = cart
  const cartItem = useSelector(state => Object.values(state.cartitems) || null)
  const sessionUser = useSelector(state => state.session.user)

  let userId;
  if (sessionUser) {
    userId = sessionUser.id
  }

  const timePeriod = (updateDate) => {
    const today = new Date();
    const dateToday = today.getDate();
    const monthToday = today.getMonth();
    const yearToday = today.getFullYear();
    let cartDate = updateDate.split('T')[0];
    cartDate = new Date(cartDate)
    const cartDateDate = cartDate.getDate();
    const cartDateMonth = cartDate.getMonth();
    const cartDateYear = cartDate.getFullYear();
    if (dateToday == +cartDateDate && monthToday == +cartDateMonth && yearToday == +cartDateYear) return true;
    return false;
  }

  const myCartItems = cartItem.filter(ele => ele.cart_id == id && (timePeriod(ele.updatedAt) === true))
  const adTotal = (myCartItems) => {
    let sum = 0;
    for (let key of myCartItems) {
      sum = sum + key.Product.price
    }
    return sum;
  }


  useEffect(() => {
    dispatch(getAllCart(userId))
  }, [dispatch, userId])

  useEffect(() => {
    dispatch(getAllCartitems())
  }, [dispatch])

  const makePayment = async () => {
    const stripe = await loadStripe("pk_test_51NdxWKBX4bR0BiaFukHQ9jLuQMN40v74YFICejsA52uaX7gifmhjBe023rDqfESbliSGWqaqtiM9b4b0uS2YdMVR00mSCdT9xa")
    const body = {
      products: myCartItems
    }
    const headers = {
      "Content-Type": "application/json"
    }
    const response = await csrfFetch('/api/checkout', {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    })
    const session = await response.json();

    const result = stripe.redirectToCheckout({ sessionId: session.id })

    if (result.error) {
      console.log(result.error)
    }
  }

  if (!cartItem) return null;
  if (!myCartItems) return null;
  return (
    <div className="flex flex-col justify-between min-h-screen bg-gray-100">
      <div className="max-container1 ">
        <h1 className="font-semibold text-xl mt-10 mb-3">Shopping Cart</h1>

        <div className="">{myCartItems.map(item => (
          <div className="flex flex-row bg-white mb-5 p-5">
            <div className="flex flex-col justify-between">
              <div className="flex flex-row justify-between gap-10">
                <div>page: {item.page}</div>
                <div>month: {item.month} </div>
                <div>year: {item.year}</div>
                <div>Price: {item.Product && item.Product.price}</div>
              </div>
              <div className="flex flex-row gap-5 items-end">
                <button className="font-light text-sm text-blue-900">Delete Ad</button>
                <div>|</div>
                <button className="font-light text-sm text-blue-900">Update Ad</button>
              </div>
            </div>
          </div>
        ))}</div>
        <div className="flex justify-between font-light text-sm text-blue-900">
          <div>Total items in cart: {myCartItems.length}</div>
         
          <button>Empty Cart</button>
        
          <button>Continue Shopping</button>
        </div>
        <div className="flex flex-col items-end mt-5 gap-3">
        {myCartItems.Product && <div>Total: ${adTotal(myCartItems)}</div>}
        <Button1 onClick={makePayment} className="" label="check out" />
        </div>
        <p className='text-right mb-10'>*Ad image can be uploaded only after ad is purchased.</p>
      </div>
      <Footer />
    </div>
  )
}
