import React from 'react'

const Button2 = ({
    label,
    backgroundColor,
    textColor,
    hover,
    fullWidth,
    onclick
   

  }) => {
  return (
    <button
   
    className={`flex justify-center items-center gap-2 px-4 py-2  rounded-full text-sm leading-none
    ${
      backgroundColor
        ? `${backgroundColor} ${textColor}`
        : `bg-gray-900 text-white  ${hover}`
    } ${fullWidth && "w-full"}`}
  >
    {label}
    onClick{()=> onclick}
  </button>
  )
}

export default Button2