import React, { useEffect } from 'react'
import {getAllCatagories} from "../../store/catagories"
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../Footer'
import EditCatagoryModal  from './EditCatagoryModal'
import  DeleteCatagoryModal  from './DeleteCatagoryModal'
import { useNavigate } from 'react-router-dom'

export const ViewCatagory = () => {
    const navigate = useNavigate()
    const AllCatagories = useSelector(state => Object.values(state.catagory))
    const dispatch = useDispatch();
    const sessionUser = useSelector(state => state.session.user)

    useEffect(()=>{
        dispatch(getAllCatagories());
    }, [dispatch])

    if(!sessionUser ) navigate('/')
    if(sessionUser && sessionUser.type !== 'admin') navigate('/')
   
  return (
    <div className="flex flex-col min-h-screen justify-between">
        <div className="max-container1">
            <div>View Catagories</div>
            <div>{AllCatagories.map((catagory, idx)=> (
                <div className="flex flex-row gap-10 items-center justify-between border-b-2 border-gray-200 " key={catagory.id}>
                <div>{catagory.id}</div>
                <div className="w-11 h-11 flex justify-center items-center bg-coral-red rounded-full"><img src={catagory.img} width={24} height={24}/></div>
                <div>{catagory.catagory}</div>
                <div >{catagory.title}</div>
                <div>{catagory.type}</div>
                <div className="mt-5 flex flex-row gap-2 items-center"><EditCatagoryModal id={catagory.id} key={catagory.id}/><DeleteCatagoryModal id={catagory.id} key={catagory.id} className="bg-gray-800 hover:bg-coral-red text-white text:sm py-2 px-2 rounded" type="submit"/></div>
                </div>
            ))}</div>
        </div>
        <Footer/>
        </div>
  )
}
