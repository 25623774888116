import React, { useEffect } from 'react'
import Footer from '../Footer'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSales } from "../../store/sale"
import { getAllCatagories } from "../../store/catagories"

export const SaleDetails = () => {
  const dispatch = useDispatch()
  const catagoryId = useParams().id;
  const sales = useSelector(state => Object.values(state.sales))

  const filteredSale = sales.filter(ele => ele.catagory_id === Number(catagoryId))
  const catagory = useSelector(state => state.catagory[catagoryId])

  const [filteredSale1] = filteredSale
  
  useEffect(() => {
    dispatch(getAllSales())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllCatagories())
  }, [dispatch])

  function getDate(value){
    return value.split('T')[0]
  }

  if(!filteredSale) return null;
  // if(!filteredSale1) return null;
  
  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
      <div className="px-5 sm:py-10 max-container  bg-gray-100">
        <section>
          <div className="flex lg:mx-20 md:py-5 items-center bg-white my-10 px-10 py-5 md:gap-10 gap-5 justify-center shadow-3xl ">
            {(catagory.catagory).toLowerCase() === 'homes for sale' && <div className="flex flex-col lg:flex-row items-center gap-5"><div>Do you have a Home to list for sale?</div><Link to="/forsale/addsale" state={{ id: catagory.id }}><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Add a Property</button></Link></div>}
            {(catagory.catagory).toLowerCase() === 'cars for sale' && <div className="flex  flex-col lg:flex-row items-center gap-5"><div>Do you have a Car or vehicle to list for sale?</div><Link to="/forsale/addsale" state={{ id: catagory.id }}><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Add a Vehicle</button></Link></div>}
            {(catagory.catagory).toLowerCase() === 'items for sale' && <div className="flex  flex-col lg:flex-row items-center gap-5"><div>Do you have an household item to list for sale?</div><Link to="/forsale/addsale" state={{ id: catagory.id }}><button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Add an Item</button></Link></div>}
            {/* <div className=""><Link to={`/events/addevents/${eventId}`}><button className="bg-gray-700 hover:bg-coral-red text-white font-bold py-2 px-4 rounded font-montssarat max-container">Add an Open House Event</button></Link></div> */}
          </div>
        </section>

        <div className="lg:mx-20 shadow-3xl mb-10">{filteredSale.map((sale) => (
          <div className=" flex flex-col md:flex-row items-stretch justify-evenly bg-white px-5 py-5 gap-10 border">
            <div className=""><img src={sale.img} alt="" width="200" height="200" /></div>
            <div className="flex flex-col justify-between">
              <div className="font-bold text-xl break-all">{sale.title}</div>
              <div className="grid grid-cols-4 gap-x-10">
                {<div className="break-all">Date posted: {getDate(sale.createdAt)}</div>}
                {sale.type === "Cars for sale" && <div className="break-all">Make: {sale.make}</div>}
                {sale.type === "Cars for sale" && <div className="break-all">Model: {sale.model}</div>}
                {sale.type === "Cars for sale" && <div className="break-all">Color: {sale.color}</div>}
                {sale.type === "Cars for sale" && <div className="break-all">Year: {sale.year}</div>}
                {sale.type === "Cars for sale" && <div className="break-all">Mileage: {sale.mileage}</div>}

                {(sale.type).toLowerCase() === "homes for sale" && <div className="break-all">Square foot: {sale.sqft}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && <div className="break-all">Year built: {sale.year}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && <div className="break-all">Lot size: {sale.lot}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && <div className="break-all">Bedrooms: {sale.bedrooms}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && <div className="break-all">Bathrooms: {sale.bathrooms}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && <div className="break-all">Garage: {sale.garage} car garage</div>}
                {(sale.type).toLowerCase() === "homes for sale" && sale.contacttype === "realtor" && <div className="break-all">Realtor Name: {sale.contact}</div>}
              </div>
                {(sale.type).toLowerCase() === "homes for sale" && sale.contacttype === "realtor" && <div>Realtor Phone: {sale.phone}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && sale.contacttype === "realtor" && <div>Realtor Email: {sale.email}</div>}
                {(sale.type).toLowerCase() === "homes for sale" && <div>MLS Listing: {<Link to={sale.weblink}>Click here</Link>}</div>}
              {sale.type === "Items for Sale" && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Contact Seller</button>}
              {(sale.type).toLowerCase() === "homes for sale" && sale.contacttype === "realtor" && <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Contact Realtor</button>}
            {sale.type === "Cars for sale" &&  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">Contact Seller</button>}
            </div>

          </div>
        ))}</div>
        <div><p>All listings will remain active for 60 days. If you have found a buyer or entered into a contract to sell your property or vehicle, please promptly deactivate the listing.</p>
          <p> If you are the owner of the post, and would like to extend the posting after the 60 day period, please visit your profile page and reactivate your listing for another 60 days.
            <p>Use this page to only list only your personal property in Riverstone.</p>
          </p></div>
      </div>
      <Footer />
    </div>
  )
}
