import React, { useEffect, useState } from 'react'
import EditVendorModal from './EditVendorModal'
import Footer from '../Footer'
import { useDispatch, useSelector } from "react-redux"
import { getAllBusinesses } from '../../store/businesses'
import { useParams, Link, useNavigate } from 'react-router-dom';
import { fetchAllReviews } from '../../store/review'
import { Button1 } from '../Button/Button1';
import { wrench } from '../../assets/icons';
import { EditVendorModal1 } from './EditVendorModal1'
import { EditVendorModal2 } from './EditVendorModal2'

export const EditVendor = () => {
    const navigate = useNavigate();
    const vendorId = useParams().id;
    const dispatch = useDispatch();
    const vendors = useSelector(state => Object.values(state.business))
    const reviews = useSelector(state => Object.values(state.review))
    const sessionUser = useSelector(state => state.session.user)
    const [vendor] = vendors.filter(ele => ele.id === Number(vendorId))
    
    let user_id;
    if (sessionUser) {
        user_id = sessionUser.id;
    }

    useEffect(() => {
        dispatch(getAllBusinesses())
    }, [dispatch])

    useEffect(() => {
        dispatch(fetchAllReviews())
    }, [dispatch])

    function datefield(date) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const date1 = date.split('T')[0]
        const date2 = date1.split('-')

        return date2[2] + " " + months[date[1]] + " " + date2[0]
    }

    function starRating(star) {
        if (star === 1) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 2) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 3) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 4) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-regular fa-star" style={{ color: "#ff6452" }}></i></div>)
        } else if (star === 5) {
            return (<div><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i><i class="fa-solid fa-star" style={{ color: "#ff6452" }}></i></div>)
        }
    }

    let userNoReview = true;
    if (reviews.length > 0) {
        vendor && vendor.Reviews && vendor.Reviews.forEach(review => {
            if (user_id && Number(review.user_id) === Number(user_id)) userNoReview = false;
        });
    }

    if (!vendor) return null;
    if (!vendor.Reviews) return null;

    if (sessionUser && sessionUser.verified !== 'active') navigate('/')
    if (!sessionUser) navigate('/login')

    return (
        <div className='h-screen flex flex-col justify-between'>

            <div className="max-container1 md:mt-20">
           { vendor.name && !vendor.contact && <p className="text-md md:text-3xl mt-10 md:mt-28 ml-5 break-all">{vendor.name }</p>}
           {!vendor.name && vendor.contact && <h1 className="text-md md:text-3xl mt-10 md:mt-28 ml-5 break-all">{vendor.contact }</h1>}
           { vendor.name && vendor.contact && <span><span className="text-md md:text-3xl mt-10 md:mt-28 ml-5 break-all">{vendor.contact } ,</span><span className="text-md md:text-3xl mt-28 sm:mt-28"> {vendor.name }</span></span>}
                <div class="container mx-auto my-2 p-5">
                               
                    <div class="md:flex no-wrap md:-mx-2 ">

                        <div class="w-full md:w-3/12 md:mx-2">

                            <div class="bg-white p-3 border-t-4 border-coral-red">
                                
                                {/* <div class="image overflow-hidden">
                        <img class="h-auto w-full mx-auto"
                        src="https://lavinephotography.com.au/wp-content/uploads/2017/01/PROFILE-Photography-112.jpg"
                        alt="">
                    </div> */}
                                {/* <p className="text-gray-900 font-bold  text:xs lg:text-xl leading-8 my-1 text-clip overflow-hidden">{vendor && vendor.contact}</p> */}
                                {/* <h3 class="text-gray-600 font-lg text-semibold leading-6">Owner at Her Company Inc.</h3> */}
                                {/* <p class="text-sm text-gray-500 hover:text-gray-600 leading-6">Lorem ipsum dolor sit amet
                        consectetur adipisicing elit.
                    Reprehenderit, eligendi dolorum sequi illum qui unde aspernatur non deserunt</p> */}
                                <ul
                                    class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                    <li class="flex items-center py-3">
                                        <span>Status</span>
                                        <span class="ml-auto"><span
                                            class="bg-gray-900 py-1 px-2 rounded text-white text-sm">{vendor && vendor.status}</span></span>
                                    </li>
                                    <li class="flex items-center py-3">
                                        <span>Added on</span>
                                        <span class="ml-auto"><span
                                            class="bg-gray-900 py-1 px-2 rounded text-white text-sm">{datefield(vendor && vendor.createdAt)}</span></span>
                                        {/* <span class="ml-auto">{vendor && datefield(vendor.created_at)}</span> */}
                                    </li>
                                    <li class="flex items-center py-3">
                                        <span>Avg Rating</span>
                                        <span class="ml-auto"></span>
                                        <span
                                            className="  bg-gray-900 py-1 px-2 rounded text-white text-sm">{Number(vendor.avgRating).toFixed(1)} </span>

                                        {/* <span class="ml-auto">{vendor && datefield(vendor.created_at)}</span> */}
                                    </li>
                                    <li class="flex items-center py-3">
                                        <span>No. of Reviews</span>
                                        <span class="ml-auto"></span>
                                        <span
                                            className="  bg-gray-900 py-1 px-2 rounded text-white text-sm">{vendor.Reviews.length} </span>

                                        {/* <span class="ml-auto">{vendor && datefield(vendor.created_at)}</span> */}
                                    </li>
                                </ul>
                            </div>
                            <div class="my-4"></div>
                            <div class="bg-white p-3 hover:shadow">
                                <div class="flex items-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
                                </div>
                            </div>
                        </div>
                        <div class="w-full md:w-9/12 mx-2 h-64">
                            <div class="bg-white p-3 shadow-sm rounded-sm">
                                <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                    <span clas="text-green-500">
                                        <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </span>
                                    <span class="tracking-wide">About</span>
                                </div>
                                <div class="text-gray-700">
                                    <div class="grid md:grid-cols-2 text-sm">
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Name</div>
                                           { vendor && vendor.contact? <div class="px-4 py-2 overflow-hidden">{vendor && vendor.contact}</div> : <div className="px-4 py-2">Not Available</div>}
                                        </div>
                                        {/* <div class="grid grid-cols-2">
                                <div class="px-4 py-2 font-semibold">Last Name</div>
                                <div class="px-4 py-2">Doe</div>
                            </div> */}
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Company</div>
                                            {vendor && vendor.name ? <div class="px-4 py-2 overflow-hidden">{vendor && vendor.name}</div> : <div className="px-4 py-2">Not Available</div>}
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Phone 1</div>
                                            {vendor && vendor.phone1 ? <div class="px-4 py-2 overflow-hidden">{vendor && vendor.phone1}</div> : <div className="px-4 py-2">Not Available</div>}
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Phone 2</div>
                                            {vendor && vendor.phone2 ? <div class="px-4 py-2 overflow-hidden">{vendor && vendor.phone2}</div> : <div className="px-4 py-2">Not Available</div>}
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Email</div>
                                            {vendor && vendor.email ? <div class="px-4 py-2 overflow-hidden">{vendor && vendor.email}</div> : <div className="px-4 py-2">Not Available</div>}
                                        </div>
                                        <div class="grid grid-cols-2 items-center">
                                            <div class="px-4 py-2 font-semibold">Website</div>
                                            {vendor && vendor.website ? <div class="px-4 py-2 overflow-hidden">{vendor && vendor.website}</div> : <div className="px-4 py-2">Not Available</div>}
                                        </div>
                                        <div class="grid grid-cols-2">
                                            <div class="px-4 py-2 font-semibold">Facebook Page</div>
                                            {vendor && vendor.facebook ? <div class="px-4 py-2 overflow-hidden"><a href={vendor.facebook}>{vendor.facebook}</a></div> : <div className="px-4 py-2">Not Available</div>}
                                            {/* <a href="'"+{vendor.facebook}+"'" target="_blank" rel="noopener noreferrer"> */}
      {/* bobbyhadz.com
    </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mt-5 flex-wrap">
                                {/* <span clas="text-green-500">
                            <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                            </svg>
                        </span> */}
                                <img src={wrench} width={20} />
                                <span className="tracking-wide">Skills: </span>
                                {vendor.Catagories.map((cat, idx) => (
                                    <div className="flex"><span >{cat.catagory} {idx < vendor.Catagories.length-1 && "|"} </span> </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div>*We do not verify if the vendor is insured and licenced to perform the job. Please, directly check with the vendors before you hire them. </div> */}


                <div className="max-container1 mt-5 py-10 sm:py-1 px-4 md:px-6 2xl:px-0 2xl:container 2xl:mx-auto flex justify-center items-center">
                </div>
                <EditVendorModal id={vendorId} />
                <EditVendorModal1 id={vendorId} />
                <EditVendorModal2 id={vendorId}/>
            </div>
            <Footer />
        </div>
    )
}
