import { csrfFetch } from "./csrf";
const LOAD_BUSICAT = "busicat/LOAD_BUSICAT"
const ADD_BUSICAT = "busicat/ADD_BUSICAT"
const DELETE_BUSICAT = "busicat/DELETE_BUSICAT"

const load_busicat = payload => ({
    type: LOAD_BUSICAT,
    payload
})

const add_busicat = payload => ({
    type: ADD_BUSICAT,
    payload
})

const delete_busicat = payload => ({
    type: DELETE_BUSICAT,
    payload
})

export const getAllBusiCats =() => async dispatch => {
    const response = await fetch(`/api/businesscatagories`);
    if (response.ok){
        const payload = await response.json();
        dispatch(load_busicat(payload));
    }
}

export const fetchAddBusinessCatagory = (newBusiCat) => async dispatch => {

    const response = await csrfFetch(`/api/businesscatagories/new`, {
        method: 'POST',
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(newBusiCat)
    });
        const payload = await response.json();
        dispatch(add_busicat(payload));
        return payload
}

export const fetchDeleteBusinessCatagory = (busiCatId) => async dispatch => {
    
    const response = await csrfFetch(`/api/businesscatagories/delete/${busiCatId.catId}/${busiCatId.vendor_id}`, {
        method:"DELETE",
    });
    if(response.ok){
        dispatch(delete_busicat(busiCatId))
    }
}

const initialState = {};

export default function businesscatagoryReducer(state= initialState, action){
    switch(action.type){
        case LOAD_BUSICAT:
            const businessCat = {}
            action.payload.forEach(ele => businessCat[ele.id] = ele);
            return {...state, ...businessCat}
        case ADD_BUSICAT:
            return {...state, [action.payload.id]: action.payload}   
        
        case DELETE_BUSICAT:
            const delBusicat = {...state};
            delete delBusicat[action.payload]
            return{ ...state }  

        default: 
            return state;
    }
}