import React from 'react'
import Footer from '../Footer'

export const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen flex flex-col justify-between">
            <div className="max-container1 px-5 pb-10">
                <h1 className="font-bold mt-10 text-center pb-5">Privacy Policy</h1>
              
                <p className="pb-5"><strong><b>Jayclad Publishing LLC </b></strong><strong><b></b></strong>Last modified: January 1, 2024</p>


                <p className="pb-5">Welcome to siennaandriverstone.com. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. <strong><b>Jayclad Publishing LLC</b></strong>, d/b/a “Sienna and Riverstone” (the “Company”, “siennaandriverstone”, or “we”), respects your privacy and is committed to protecting it through our compliance with the practices described in this notice. Sienna and Riverstone is engaged in providing information about products and services available to the residents of Sienna and Riverstone.</p>
                <p className="pb-5">This notice describes our practices for collecting, using, maintaining, protecting, and disclosing the personal information we may collect from you or that you may provide when you visit our website (https://www.siennaandriverstone.com/or communications or forms that link or refer to this notice (collectively, our “Website”)), including purchasing ads through our Website. This notice applies to the personal information collected through our Website, regardless of the country where you are located.</p>
                <p className="pb-5">The Website may include links to third-party websites, plug-ins, services, social networks, or applications. Clicking on those links or enabling those connections may allow the third-party to collect or share data about you. We do not control these third-party websites, and we encourage you to read the Privacy Notice of every website you visit.</p>
                <p className="pb-5">Please read this notice carefully to understand our policies and practices for processing and storing your personal information. By engaging with our Website, you accept and consent to the practices described in this notice. This notice may change from time to time. Your continued engagement with our Website after any such revisions indicates that you accept and consent to them, so please check this page periodically for updates.</p>
                <p><strong><b>Data We May Collect About You</b></strong><strong><b></b></strong></p>
                <p>We collect and use different types of data from and about you including:</p>
                <ul>
                    <li className="pb-5">Personal information that we could reasonably use to identify you directly or indirectly, such as your name, postal address, email address, telephone number, payment information, or any other identifier we may use to contact you online or offline (“personal information”).</li>
                    <li className="pb-5">Non-personal information that does not directly or indirectly reveal your identity or directly relate to an identified individual, such as demographic information, statistics, or aggregated information. Statistical or aggregated data does not directly identify a specific person, but we may derive non-personal statistical or aggregated data from personal information. For example, we may aggregate personal information to calculate the percentage of users accessing a specific Website feature.&nbsp;</li>
                    <li className="pb-5">Uploaded content data, which includes any image or design file uploaded to our server by you, which will be automatically collected and saved until you request us to delete it by sending an email to&nbsp;Sienna and Riverstone (<b></b><a href="mailto:siennaandriverstone@gmail.com"><strong><u><b>siennaandriverstone@</b></u></strong><u>gmail</u><strong><u><b>.com</b></u></strong></a>). Any project created will be automatically collected and saved until deletion is requested by the user who created the project. If a project has been ordered it can no longer be deleted.</li>
                    <li className="pb-5">Technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, browser plug-in types and versions, or operating system and platform.</li>
                    <li className="pb-5">Non-personal details about your Website interactions, including the full Uniform Resource Locators (URLs), clickstream to, through, and from our Website (including date and time), products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page, or any phone number used to call our customer service number.</li>
                    <li className="pb-5">Personal information collected from public sources, such as Facebook, Twitter, Instagram, and other social media platforms. Personal information we collect may include photos, posts, likes, and your engagement with our content. For instance, we may repost on Instagram a post you make on Instagram in relation to Sienna and Riverstone and/or our products in our own Instagram feed.</li>
                </ul>
                <p className="pb-5">If we combine or connect non-personal, technical, or demographic data with personal information so that it directly or indirectly identifies an individual, we treat the combined information as personal information.</p>
                <p><strong><b>How We Collect Data About You</b></strong></p>
                <p>We use different methods to collect data from and about you including through:</p>
                <ul>
                    <li>Direct interactions. You may give us information about you by filling in forms or by corresponding with us by phone, e-mail, or otherwise. This includes information you provide when you create an account, subscribe to our newsletters, transact on our Website or otherwise place an order for our Products, and if you report a problem with our Website. Please note that in order to create an account, the only personal information you have to provide is an email address, name, and password.</li>
                    <li className="pb-5">Automated technologies or interactions. As you interact with our Website, we may automatically collect technical data about your equipment, browsing actions and patterns as specified above. We may collect this information by using cookies, server logs, and other similar technologies.</li>
                </ul>
                <p><strong><b>Cookies and Automatic Data Collection Technologies</b></strong></p>
                <p className="pb-5">A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Pixel tags (also called web beacons) are small blocks of code placed on websites and emails. To collect information automatically as described in this section, the Company and its service providers may use cookies and other technologies like pixel tags to help us identify and track visitors, usage, and access preferences for our Services, as well as track and understand email campaign effectiveness. You can turn off your web browser’s ability to accept cookies, but if you do so, certain parts of our Website may not work for you.</p>
                <p className="pb-5">We may collect public posts to social media platforms that specifically mention our Company via our handles.</p>
                <p className=""><strong><b>Third-Party Use of Cookies and Other Tracking Technologies</b></strong></p>
                <p className="pb-5">Some content or applications, including advertisements, on the Website are served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our Website. They may associate the information collected with your personal information or they may collect information, including personal information, about your online activities over time and across different websites or other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
                <p className="pb-5">We do not control how these third-party tracking technologies operate or how they may use the collected data. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see&nbsp;<a href="#bookmark=id.1t3h5sf"><strong><b>Your Personal information Use Choices</b></strong></a>.</p>
                <p className=""><strong><b>How We Use Your Personal information</b></strong></p>
                <p className="pb-3">We use your information, including your personal information, for the following purposes:</p>
                <ul>
                    <li className="pb-5">To process and fulfil your orders or contact you concerning the status of an order;</li>
                    <li className="pb-5">To provide the Products to you and respond to your requests and to help ensure a good customer experience using the details you submit when you place an order or when you reach out to us (which may include your name, email, shipping address and payment information);</li>
                    <li className="pb-5">To send information to you, including purchase confirmation, invoices, technical notices, updates, security alerts, and support and administrative messages;</li>
                    <li className="pb-5">For direct marketing of our Products and services;</li>
                    <li className="pb-5">To fulfill our obligations under a contract or other agreement;&nbsp;</li>
                    <li>To respond to your inquiries related to employment opportunities or other requests;</li>
                    <li className="pb-5">To send newsletters, surveys, offers, and other promotional materials related to our products and for other marketing purposes via email to help promote our business and tailor our advertising to you;</li>
                    <li className="pb-5">To comply with our legal obligations where required; and</li>
                    <li className="pb-5">For other business purposes in the context of a merger, acquisition, asset sale/purchase or administration/bankruptcy.</li>
                </ul>
                <p className="pb-5">We may also use personal information to contact you about our own goods and services that may be of interest to you. If you do not want us to use your data in this way, please check the relevant box located on the form where we collect your data (during the checkout process or when you register with our Website), unsubscribe via the link at the bottom of any communication we send to you, or email us at&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><u><b>siennaandriverstone@</b></u></strong><u>gmail</u><strong><u><b>.com</b></u></strong></a>.</p>
                <p className="pb-5">We may use personal information to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
                <p className="pb-5">We may use non-personal information for any business purpose.</p>
                <p className=""><strong><b>Disclosure of Your Personal information</b></strong></p>
                <p className="pb-5">We may share your personal information with:</p>
                <ul>
                    <li  className="pb-5"> Business partners, manufacturers, suppliers, service providers, sub-contractors, and other third parties we use to support our business (such as analytics and search engine providers that assist us with Website improvement and optimization and third-parties who assist us with custom manufacturing). We contractually require these third parties to keep that personal information confidential and use it only for the contracted purposes.</li>
                    <li className="pb-5">Third-party business partners in connection with the sale of Products, whereby the sale of such Products is part of a partnership that requires purchasing data (email address, company name / shop ID, SKUs, and quantities purchased) to be shared with such business partner, subject to the confidentiality requirements between the parties and all obligations set forth in the privacy policy of the business partner. This is only applicable if you are a pre-existing customer of the business partner and purchase through the business partner-specific portal located on the Website.&nbsp;</li>
                    <li className="pb-5">Payment processing providers who provide secure payment processing services. Please note your payment card details are not shared with Sienna and Riverstone by the provider (PayPal, CCAvenue, Stripe or Authorize).</li>
                    <li className="pb-5">For any other purposes that we disclose when you provide the data.</li>
                    <li className="pb-5">With your consent.</li>
                </ul>
                <p className="pb-5">We may also disclose your personal information to third parties:</p>
                <ul>
                    <li className="pb-5">In the event that we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets.</li>
                    <li className="pb-5">To a buyer or other successor in the event of merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, where one of the transferred assets is the personal information we hold.</li>
                    <li className="pb-5">To comply with any court order, law, or legal process, including responding to any government or regulatory request.</li>
                    <li className="pb-5">To protect the rights, property, or safety of our business, our employees, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of cybersecurity, fraud protection, and credit risk reduction.</li>
                </ul>
                <p className="pb-5">We may share non-personal information without restriction.</p>
                <p className=""><strong><b>Consent to Personal information Transfer</b></strong></p>
                <p className="pb-5">We are based in the United States of America (“USA”). We may process, store, and transfer the personal information we collect, in and to a country outside your own, with different privacy laws that may or may not be as comprehensive as your own. By submitting your personal information or engaging with our Websites, you consent to this transfer, storing, or processing.</p>
                <p className="pb-5">If you are located outside of the USA and Canada, you may provide us with your email address to be notified when our products are available for purchase in your country. By providing us with your email address, you explicitly consent to such notification and to the transfer of your personal information from the country where you are located to the USA.</p>
                <p className=""><strong><b>Your Personal information Use Choices</b></strong></p>
                <p className="pb-5">We strive to provide you with choices regarding certain personal information uses, particularly around marketing and advertising. We have established the following personal information control mechanisms:</p>
                <ul>
                    <li className="pb-5">Promotional Offers from the Company.&nbsp;If you do not want us to use your contact information to promote our own products and services, or third parties’ products or services, you can opt-out by checking the relevant box located on the form where we collect your data (the order form or registration form), by sending us an email with your request to&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><u><b>siennaandriverstone@</b></u></strong><u>gmail</u><strong><u><b>.com</b></u></strong></a>, or by replying to any promotional email we have sent you or following the opt-out links on that message. This opt out does not apply to information provided to the Company as a result of a Product purchase, Product service experience, or other transactions.</li>
                    <li  className="pb-5">Third-Party Advertising.&nbsp;If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by checking the relevant box located on the form where we collect your data (the order form or registration form) or by sending us an email with your request to&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><b>siennaandriverstone@</b></strong>gmail<strong><b>.com</b></strong></a>,</li>
                    <li className="pb-5">Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some parts of this Website may become inaccessible or not function properly. For more information about tracking technologies, please see&nbsp;<a href="#bookmark=id.1fob9te"><strong><b>Cookies and Automatic Data Collection Technologies</b></strong></a>.</li>
                </ul>
                <p className="pb-5">Our Website may, from time to time, contain links to and from the websites of our partner networks, advertisers, and affiliates, or include plug-ins enabling third-party features. If you follow a link to any third-party website or engage a third-party plug-in, please note that these third parties have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal information to these third parties.</p>
                <p  className="pb-5">The Company currently does not support Do Not Track (“DNT”). DNT is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable DNT by visiting the Preferences or Settings page of your web browser.</p>
                <p className=""><strong><b>Accessing and Correcting Your Personal information</b></strong></p>
                <p className="pb-5">You can access, review, and change your personal information by sending us an email at&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><b>siennaandriverstone@</b></strong>gmail<strong><b>.com</b></strong></a>, to request access to, correct, or delete personal information that you have provided to us. We may not accommodate a request to change or delete information if we believe the change or deletion would violate any law or legal requirement or negatively impact the information’s accuracy.&nbsp;</p>
                <p className=""><strong><b>Privacy Notice for Residents of Certain States in the USA</b></strong></p>
                <p>Some USA state laws (e.g., California and Nevada) provide state residents with additional privacy rights. This notice meets current U.S. state privacy and data protection law and will be updated as these laws evolve. We do not sell your personal information as defined by any applicable US law, including the California Consumer Protection Act (2018) (the “CCPA”).</p>
                <p className="pb-5">California Residents:&nbsp;Additional Disclosures Related to Collection, Use, and Disclosure of Personal Information</p>
                <p className="pb-5">If you are a California resident, the CCPA requires us to disclose the following information with respect to our collection, use and disclosure of personal information (referred to in this section as “personal information”).</p>
                <ul>
                    <li className="pb-5">Categories of Personal Information Collected:&nbsp;In the preceding 12 months, we have collected the following categories of personal information: identifiers, personal information categories listed in the California Customer Records statutes, commercial information, and internet or other similar network activity. For examples of the personal information we collect, please see “Data We May Collect About You” above.</li>
                    <li className="pb-5">Business or Commercial Purpose for Collecting and Using Personal Information: We collect personal information for the business purposes described in “How We Use Your Personal information” above.</li>
                    <li className="pb-5">Categories of Sources of Personal Information: We collect personal information directly from you, automatically, and from other sources, each of which is more particularly described in “How We Collect Data About You” above.</li>
                    <li className="pb-5">Categories of Personal Information Disclosed for a Business or Commercial Purpose: In the preceding 12 months, we have disclosed the following categories of personal information for business or commercial purposes: identifiers, personal information categories listed in the California Customer Records statutes, commercial information, and internet or other similar network activity.</li>
                    <li className="pb-5">Categories of Third Parties with whom We Share Personal Information: We may share your personal information with the third parties as described in “Disclosure of Your Personal information” above.</li>
                    <li className="pb-5">Sale of Personal Information: SiennaandRiverstone.com does not sell your personal information.</li>
                </ul>
                <p><strong><b>Your Consumer Rights</b></strong></p>

                <p className="pb-5">California consumers have the right to request access to their personal information, additional details about our information practices and deletion of their personal information (subject to certain exceptions). We describe how California consumers can exercise their rights under the CCPA below. Please note that you may designate an authorized agent to exercise these rights on your behalf by providing written materials demonstrating that you have granted the authorized agent power of attorney. Please note that if an authorized agent submits a request on your behalf, we may need to contact you to verify your identity and protect the security of your personal information.</p>

                <p className="pb-5">Right to Know:&nbsp;You may request access to the specific pieces of personal information we have collected about you in the prior 12 months. You may also request additional details about our information practices, including the categories of personal information we have collected about you, the sources of such collection, the categories of personal information we share for a business or commercial purpose, the categories of third parties with whom we share your personal information, and the specific pieces of personal information we collected about you (also called a data portability request). We are required by law to verify your identity prior to granting access to your data in order to protect your privacy and security.</p>

                <p className="pb-5">Deletion:&nbsp;You may request that we delete the personal information we have collected about you. Please note that we may retain certain information as required or permitted by applicable law, and we may deny your deletion request if retaining the personal information is necessary for us or our service providers under one of the nine statutory exceptions listed in Cal. Civ. Code § 1798.105, as amended by AB 1146. We are required by law to verify your identity prior to deleting your information in order to protect your privacy and security. If you request to delete your personal information, certain of our products and services may no longer be available to you.</p>
                <p className="pb-5">To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us in writing at</p>


                <p className="pb-5">And email us at &nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><b>siennaandriverstone@</b></strong>gmail<strong><b>.com</b></strong></a>, Only you, or someone legally authorized to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child. You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must (i) provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, and (ii) describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time, we will inform you of the reason and extension period in writing.</p>

                <p className="pb-5">We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable consumer request does not require you to create an account with us. We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
                <p><strong><b>Non-Discrimination</b></strong></p>

                <p className="pb-5">We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not deny you goods or services; charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties; provide you a different level or quality of goods or services; and/or suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>

                <p className=""><strong><b>Privacy Notice for Residents of Canada</b></strong></p>
                <p className="pb-5">We retain personal data for as long as you have an account with us in order to meet our contractual obligations to you and for six years after that and/or purchase of Products to identify any issues and resolve any legal proceedings.</p>

                <p className="pb-5">Please contact us at&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><u><b>siennaandriverstone@gmail.com</b></u></strong></a>&nbsp;if (i) you wish to access, update, and/or correct inaccuracies in your personal data or change your consent preferences (please note that we may need to collect additional personal data for the purposes of verifying your identity before responding to your request); (ii) you would like additional information about how our foreign-based service providers process your personal data; and/or (iii) if you have any questions or complaints about the manner in which we treat your personal data.</p>
                
                <p className="pb-5">You can usually access, correct, or delete your personal information using your account settings and tools that we offer, but if you aren’t able to do that, or you would like to contact us about one of the other rights, scroll down to “How to Reach Us” to, well, find out how to reach us. European Union (“EU”) and Swiss individuals also have the right to make a complaint to a government supervisory authority.</p>

                <p><strong><b>Legal Bases For Use of Your Information</b></strong></p>

                <p>The legal bases for using your information as set out in this Privacy Notice are as follows:</p>

                <ul  className="pb-5">
                    <li>Where use of your information is necessary in order to fulfill our commitments to you under our&nbsp;Terms of use and/or other agreements with you or is necessary to administer your account (for example, in order to enable access to our Website or charge you for products or services you purchase);</li>
                    <li>Where use of your information is necessary for compliance with a legal obligation;</li>
                    <li>Where use of your information is necessary in order to protect your vital interests or those of another person;</li>
                    <li>Where use of your information is necessary for our legitimate interests or the legitimate interests of others (for example, to provide and update our Website, to improve our Website, or&nbsp; to communicate with you); or</li>
                    <li>Where we have your consent, in accordance with applicable law (for example before we place certain cookies on your device and access and analyze them later on).</li>
                </ul>

                <p><strong><b>Data Security</b></strong></p>
                <p className="pb-5">The security of your personal information is very important to us. We use physical, electronic, and administrative safeguards designed to protect your personal information from loss, misuse, and unauthorized access, use, alteration, or disclosure. Our third party payment processors encrypt any payment transactions. We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.</p>

                <p className="pb-5">The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to take care when providing information in public areas of the Website such as product reviews, which any Website visitor can view.</p>
                <p className="pb-5">Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for the circumvention of any privacy settings or security measures contained on the Website.</p>

                <p><strong><b>Children’s Online Privacy</b></strong></p>

                <p className="pb-5">You must be at least 13 years of age or older to register and use our Website. We do not direct our Website to minors and we do not knowingly collect personal information from children under 13 or as defined by local legal requirements. If we learn we have mistakenly or unintentionally collected or received personal information from a child without appropriate consent, we will delete it. If you believe we mistakenly or unintentionally collected any information from or about a child, please contact us at&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><u><b>siennaandriverstone@gmail.com</b></u></strong></a></p>
                <p><strong><b>Our Communications to You</b></strong></p>

                <p className="pb-5">We may send you promotional emails from time to time (with your permission where required), you can opt-out of these at any time. These messages may include promotional messages about our new products and other Company news. We may also remind you about items which you have left in your cart but not claimed (with your permission where required) and when you have requested to be notified about items that are back in stock.</p>
                <p className="pb-5">Where you have opted-out of receiving our promotional emails, you may still receive service communications related to your purchase or account or services you have directly requested from us.</p>

                <p><strong><b>Retention of Data</b></strong><strong><b></b></strong></p>
                <p className="pb-5">We will retain your personal information only for as long as is reasonably necessary for the purposes set out in this Privacy Notice. The criteria used to determine the retention periods include: (i) how long the personal information is needed to provide the services (including in connection with our guarantees) and operate the business; (ii) the type of personal information collected; and (iii) whether we are subject to a legal, contractual or similar obligation to retain the data (e.g., mandatory data retention laws, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation or disputes).&nbsp;</p>

                <p><strong><b>Changes to Our Privacy Notice</b></strong></p>
                <p className="pb-5">We reserve the right to modify this Privacy Policy at any time. Changes will be effective upon posting the updated Privacy Policy on the website. We will post any changes we may make to our Privacy Notice on this page and indicate on the Website home page that we updated this Privacy Notice. If the changes materially alter how we use or treat your personal information we will notify you through a notice on the Website home page. Please check back frequently to see any updates or changes to our Privacy Notice.</p>

                <p><strong><b>Your Choices</b></strong></p>
                <p className="pb-5 px-5">You may choose not to provide certain personal information, but this may limit your ability to use certain features of our website.</p>

                <p><strong><b>Third-Party Links</b></strong></p>
                <p className="pb-10">Our website may contain links to third-party websites. We are not responsible for the content or privacy practices of these websites.</p>

                <p><strong><b>Contact Information</b></strong></p>
                <p className="pb-10">Please address questions, comments, and requests regarding this Privacy Notice and our privacy practices to our email address at&nbsp;<a href="mailto:siennaandriverstone@gmail.com"><strong><u><b>siennaandriverstone@gmail.com</b></u></strong></a></p>
            </div>
            <Footer />
        </div>


    )
}
