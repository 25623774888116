import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import Footer from './Footer';
import { getAllBusinesses } from '../store/businesses'
import {getAllCatagories} from "../store/catagories";


export const Search = () => {
    const dispatch = useDispatch();
    const vendorsList = useSelector(state=> Object.values(state.business))
    const catagories = useSelector(state=> Object.values(state.catagory))
    const [search, setSearch] = useState("");
    const [results, setResults] = useState("")
    const [errors, setErrors] = useState({})

    useEffect(()=> {
        dispatch(getAllBusinesses())
    }, [dispatch])
    
    useEffect(()=> {
      dispatch(getAllCatagories())
    }, [dispatch])

    const handleSubmit = async(e) => {
    //     e.preventDefault();
    //     setSearch("")
    //    if(!e.target.value)setResults([])
    //    const data=  await dispatch(getSearchResults(search))
    
    // setResults(searchResults.filter(function(n) {
    //     return data.indexOf(n) !== -1;
    // }))
    }

  return (
   <div className="flex flex-col justify-between min-h-screen bg-white">
    <div></div>
    <div className="w-full lg:w-[60%] max-container1">
<form class="px-10">
    <div class="flex">
        
        <div class="relative w-full">
            <input 
            type="search" 
            id="search-dropdown" 
            class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-50 rounded-lg border-r-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500" 
            placeholder="Search catagories, name, business name, phone, email..." 
            required
            value = {search}
            onChange={(e) => setSearch(e.target.value)}
             />
            <button 
            type="submit" 
            onClick = {handleSubmit}
            class="absolute top-0 end-0 p-2.5 text-sm font-medium h-full text-white bg-gray-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span class="sr-only">Search</span>
            </button>
        </div>
    </div>
</form>

</div>
{results.length > 0  && <div>{results.map(item => (
    <div>
        <div>{item.catagory}</div>
        </div>
))}</div>}
<Footer/>
</div>
  )
}
