import { csrfFetch } from "./csrf"

const LOAD_BUSINESSIMAGES = "businessimages/LOAD_BUSINESSIMAGES"
const ADD_BUSINESSIMAGES = "businessimages/ADD_BUSINESSIMAGES"
const UPDATE_BUSINESSIMAGES = "businessimages/UPDATE_BUSINESSIMAGES"

// all businesses
const load_businessimages = payload => ({
    type: LOAD_BUSINESSIMAGES,
    payload
})

const add_businessimages = payload => ({
    type: ADD_BUSINESSIMAGES,
    payload
})

const update_businessimages = payload => ({
    type: UPDATE_BUSINESSIMAGES,
    payload
})

// thunks
export const getAllBusinessimages = () => async dispatch => {
    const response = await fetch(`/api/businessimages`);
    if(response.ok){
        const payload = await response.json();
       
        dispatch(load_businessimages(payload));
    }
}

export const fetchAddBusinessImage = (formData) => async dispatch => {
    const response =await csrfFetch(`/api/businessimages/new`, {
        method: 'POST',
        body: formData
    })
    const payload = await response.json();
    dispatch(add_businessimages(payload))

    return payload
}

export const fetchUpdateBusinessImg = (formData, businessId) => async dispatch => {
    const response = await fetch(`/api/businessimages/update/${businessId}`, {
        method: "POST",
        body: formData
    })
    const payload = await response.json();
    dispatch(update_businessimages(payload))
    return payload;
}

const initialState = {};
export default function businessImagesReducer(state = initialState, action){
    switch(action.type){
        case LOAD_BUSINESSIMAGES:
            const allBusinessImages = {};
            action.payload.forEach(ele =>allBusinessImages[ele.id]=ele)
            return {...state, ...allBusinessImages}

        case ADD_BUSINESSIMAGES:
            return {...state, [action.payload.id]: action.payload}

        case UPDATE_BUSINESSIMAGES:
            return {...state, [action.payload.id]: action.payload}
        
            
        default: 
            return state;
    }
}