import React from 'react'
import {hero, heroimage} from '../../assets/images'
import { services } from '../../constants'
import ServiceCard from '../ServiceCard'
import Footer from '../Footer'
import ReviewCard from './ReviewCard'
import { reviews } from '../../constants'
import { avatar1, avatar2, avatar3 } from '../../assets/icons'

const Home = () => {
    return (
      <>
      <div className="w-full flex xl:flex-row flex-col-reverse justify-center items-center gap-10 max-container1 pb-10 lg:my-20 ">
        <div className="font-montserrat font-medium text-2xl md:text-3xl lg:text-4xl md:pl-5 text-center" ><span>Where</span> <span className="text-coral-red">Residents </span><span>Connect...</span></div>
        <div className="px-5 mt-5"><img src={heroimage} alt="hero image" width={610} height={500}/></div>
        {/* <div className="px-2 my-10"><img src="https://source.unsplash.com/photos-jdriGWcZZKo" alt="Logo" width={610} height={500}/></div> */}
      </div>
      <section className="max-container1 flex justify-center flex-wrap gap-9 mb-10">
      {services.map((service)=> (
        <ServiceCard key={service.label} {...service}/>
      ))}
      </section>
      <section className='max-container1 pb-20 pt-20'>
      <h3 className='font-palanquin text-center text-4xl font-bold px-3'>
        What the
        <span className='text-coral-red'> Residents </span>
        Say?
      </h3>
      <p className='m-auto mt-4 max-w-lg  text-center info-text'>
        Hear genuine stories from our satisfied residents about their
        exceptional experiences with us.
      </p>

      <div className='mt-24 flex flex-1 justify-evenly items-center max-lg:flex-col gap-14'>
        {reviews.map((review, index) => (
          <ReviewCard
            key={index}
            imgURL={review.imgURL}
            customerName={review.customerName}
            rating={review.rating}
            feedback={review.feedback}
          />
        ))}
      </div>
    </section>
      <Footer/>
      </>
        // <section id ="home" className="w-full flex xl:flex-row flex-col justify-center gap-10 max-container">
        //   <div className="relative xl:w-2/5 flex flex-col justify-center items-start w-full max-xl:padding-x pt-28">
        //     {/* <p className="text-xl font-montserrat text-coral-red">One Place For All Your Needs</p>
        //     <p className="text-xl font-montserrat text-coral-red">Our Summer Collection</p>  */}
        //     <h1 className="mt-10 font-palanquin text-8xl max-sm:text-[72px] max-sm:leading-[82] font-bold"><span className="xl:whitespace-nowrap relative z-10 pr-10">The New Arrival</span>
        //     <br />
        //       <span className="text-coral-red inline-block mt-3">Nike</span> Shoes
        //     </h1> 
        //   </div>
        //   <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
        //         <img src={hero} alt="hero image"   width={610} height={500}/>
        //     </div>        
        //  </section>
       
      )
}

export default Home