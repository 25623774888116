import { csrfFetch } from "./csrf";
const LOAD_SALES = "sales/LOAD_SALES"
const ADD_SALES = "sales/ADD_SALES"
const UPDATE_SALE="sales/UPDATE_SALE"

// all events
const load_sales = payload => ({
    type: LOAD_SALES,
    payload
})

const add_sales = payload => ({
    type: ADD_SALES,
    payload
})

const update_sale = payload => ({
    type: UPDATE_SALE,
    payload
})


// Thunks
// get all Sales
export const getAllSales = () => async dispatch => {
    const response = await fetch(`/api/sale`);
    if (response.ok){
        const payload = await response.json();
        dispatch(load_sales(payload))
    }
}

// add a sale 
export const fetchAddSale = (formData) => async dispatch => {
  
    const response = await csrfFetch(`/api/sale/new`, {
        method: 'POST',
        body: formData
    })
    const payload = await response.json();
    dispatch(add_sales(payload))
    return payload;
}

// update a sale
export const fetchUpdateSale = (formData, saleId) => async dispatch => {
   
    const response = await csrfFetch(`/api/sale/update/${saleId}`, {
        method: 'POST',
        body: formData
    });
    const payload = await response.json();
    dispatch(update_sale(payload))
    return payload
}

const initialState = {};
export default function saleReducer(state = initialState, action){
    switch(action.type){
        case LOAD_SALES:
            const allSales = {}
            action.payload.forEach(ele=> allSales[ele.id]=ele)
            return {...state, ...allSales}
        case ADD_SALES:
            return {...state, [action.payload.id]:action.payload}  
        case UPDATE_SALE:
            return {...state, [action.payload.id]: action.payload}     
        default: 
            return state;    
    }
}


