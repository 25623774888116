import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom';
import Footer from '../Footer';
import * as sessionActions from "../../store/session"
import {avatars} from '../../constants'


export const ProfileImageChangeSuccess = () => {
    const navigate = useNavigate()
    const sessionUser = useSelector(state => state.session.user);

    useEffect(()=> {
        sessionActions.restoreUser()
    }, [])

    function findImage(value){
        let result = avatars.find(ele => ele.name == value)
        return result.img;
    }

    if(!sessionUser) navigate('/')
  return (
    <div className="flex flex-col justify-between h-screen">
        <div></div>
    <div className="max-container1" >
        <div className="flex bg-white rounded-lg shadow-xl items-center">
           
            <div className="w-full flex flex-col items-center p-8">
           
                <h2 className="text-2xl font-semibold text-gray-700 text-center">Congratulations</h2>
                <div className="pb-5">Your Profile Image has been changed successfully.</div>

                <div className="font-bold text-4xl pb-10 truncate"><img src={findImage(sessionUser.avatar)} alt=""/></div>
                <Link to='/profilepage' className="">Return to Profile Page</Link>
               
            </div>
        </div>
    </div>
    <Footer/>
    </div>

  )
}
