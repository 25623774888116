import React, {useState, useEffect, useRef} from 'react';
import { csrfFetch } from "../../store/csrf";
import emailjs from '@emailjs/browser';
import Footer from '../Footer'
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export const Contact = () => {

    const form =useRef();
    const navigate = useNavigate()
    const captchaRef = useRef(null);
    const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;

    const sendEmail = async (e) => {
        e.preventDefault();
        const captchaValue = await captchaRef.current.executeAsync()
        captchaRef.current.reset()
        const response = await fetch(`/api/captcha?captcha=${captchaValue}`
        )
        const data = await response.json();
        console.log(data)
      if(data.success){
          emailjs.sendForm('service_5nzik11', 'template_ju1jowh', form.current, 'fkUsWYrQekpxHsmP0')
          .then((result)=> {
              console.log(result.text);
          }, (error)=> {
              console.log(error.text)
          })
          e.target.reset()
          navigate('/contact/thankyou')
      }
    }

  return (
    <div>
    <section className="bg-white max-container">
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-3xl tracking-tight font-bold text-center text-gray-800">Contact Us</h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 text-sm">Got a question? Want to send feedback about the wesite? Need more details about a feature or how to use the site, Let us know.</p>
        <form ref={form} onSubmit={sendEmail} className="space-y-8">
        <div>
                <label for="name" className="block mb-2 text-sm font-medium text-gray-800">Your name</label>
                <input type="text" id="name" name="user_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="John Doe" required></input>
            </div>
            <div>
                <label for="email" className="block mb-2 text-sm font-medium text-gray-800">Your email</label>
                <input type="email" id="email" name="user_email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@email.com" required></input>
            </div>
            <div>
                <label for="subject" className="block mb-2 text-sm font-medium text-gray-800">Subject</label>
                <input type="text" id="subject" name="user_subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="What is the email about?" required></input>
            </div>
            <div className="sm:col-span-2">
                <label for="message" className="block mb-2 text-sm font-medium text-gray-800">Your message</label>
                <textarea id="message" rows="6" name="user_message" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Leave a comment..." required></textarea>
            </div>
            <ReCAPTCHA
              className="recaptcha"
              sitekey={SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
            <button type="submit" value="send" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-gray-900 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-coral-red dark:focus:ring-primary-800">Send message</button>
        </form>
    </div>
  </section>
  <Footer/>
  </div>
  )
}
