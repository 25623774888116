import React from 'react'
import Footer from '../Footer'
import { Product } from './Product'

export const MyAds = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between">
        <div>

        </div>
        <Product/>
        <Footer/>
    </div>
  )
}
