import React from 'react'
import Footer from '../Footer'

export const VerifySignup = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between">
    <div></div>
        <div className="max-container mt-5 px-5 sm:mt-10">
    <div>Thank you for Signing up. Once your credentials are verified, you will have full access to the website.</div>
        </div>
        <Footer/>
    </div>
  )
}
