import { csrfFetch } from "./csrf";
const LOAD_CARTITEMS = "cartitems/LOAD_CARTITEMS"
const ADD_CARTITEM = "cartitem/LOAD_CARTITEM"

// all products
const load_cartitems = payload => ({
    type: LOAD_CARTITEMS,
    payload
})

const add_cartitem = payload => ({
    type: ADD_CARTITEM,
    payload
})

export const getAllCartitems = () => async dispatch => {
    const response = await fetch(`/api/cartitems`);
    if (response.ok) {
        const payload = await response.json();

        dispatch(load_cartitems(payload));
    }
}

export const postCartItem =(item) => async dispatch => {
   
    const response = await csrfFetch(`/api/cartitems/new`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify( item ),
    });
    const payload = await response.json();
    dispatch(add_cartitem(payload));
    return payload;
}



const initialState = {};
export default function cartitemsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_CARTITEMS:
            const allCartitems = {};
            action.payload.forEach(ele => allCartitems[ele.id] = ele)
            return { ...state, ...allCartitems }
        case ADD_CARTITEM:
            return {...state, [action.payload.id]: action.payload}     
        default:
            return state;
    }
}