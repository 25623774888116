import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Footer from './Footer'
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { getAllCatagories } from "../store/catagories"


export const Catagories = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const catagories = useSelector(state => Object.values(state.catagory))
  const sessionUser = useSelector(state => state.session.user)
  const catagories1 = catagories.filter(ele => ele.type === 'vendor')
  
  catagories1.sort((a,b) => a.catagory < b.catagory ? -1 : a.catagory > b.catagory ? 1 : 0)

  
  useEffect(() => {
    dispatch(getAllCatagories())
  }, [dispatch])

  const form = useRef();

  if (!catagories) return null

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_fwvk2g9', 'template_ju1jowh', form.current, 'fkUsWYrQekpxHsmP0')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text)
      })

    e.target.reset()
    navigate('/catagories/newcatagoryemail')
  }

  return (

    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
      {/* <div className="max-container"> */}
      <div className="px-10 py-10 max-container1  bg-gray-100">
        <div>
        <h1 className='sm:pt-20 text-2xl text-center'>Home Services</h1>
        <p className='pt-5 pb-10  text-md text-center'>Click on a catagory to view a list of vendors or to add a vendor</p>
        </div>
        <section className="max-container flex flex-row justify-center flex-wrap gap-4 mb-10">
          {/* {services.map((service)=> (
                  <ServiceCard key={service.label} {...service}/>
                ))} */}

          {catagories1.map((catagory) => (
            <div className="flex sm:w-[300px] 
              sm:min-w-[200px] w-full rounded-
              [20px] shadow-3xl px-5 py-5 bg-white">
                <div className="flex flex-row items-center gap-3">
                {/* <div className="w-11 h-11 flex justify-center items-center bg-coral-red rounded-full"><img src={catagory.img} width={24} height={24}/></div> */}
                <div className="flex justify-center items-center bg-coral-red rounded-full p-2.5"><img src={catagory.img} width={24} height={24}/></div>
                <Link to={`/vendors/${catagory.id}`}>{catagory.catagory}</Link>
                </div>
                </div>
          ))}

        </section>

        {/* {sessionUser? 
        <section className="max-container w-full">
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md bg-white w-full">
            <p className="mb-8 lg:mb-16 font-light text-center text-coral-red sm:text-xl">If you do not see the catagory you are looking for, email us and we will add it for you. dfgf </p>
            <form ref={form} onSubmit={sendEmail} className="space-y-8">
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
              <div className="md:col-span-3">
                <label for="name" className="block mb-2 text-sm font-medium text-gray-800">Your name</label>
                <input type="text" id="name" name="user_name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="John Doe" required></input>
              </div>
              <div className="md:col-span-3">
                <label for="email" className="block mb-2 text-sm font-medium text-gray-800">Your email</label>
                <input type="email" id="email" name="user_email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="name@email.com" required></input>
              </div>
              {/* <div className="md:col-span-2">
                <label for="subject" className="block mb-2 text-sm font-medium text-gray-800">Subject</label>
                <input type="text" id="subject" name="user_subject" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Request for a new catagory" required></input>
              </div> */}
              {/* <div className="md:col-span-6">
                <label for="message" className="block mb-2 text-sm font-medium text-gray-800">Your message</label>
                <textarea id="message" rows="6" name="user_message" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="I would like to add the catagory...." required></textarea>
              </div>
              <div className="md:col-span-6">
              <button type="submit" value="send" className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-gray-900 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-coral-red dark:focus:ring-primary-800">Send message</button>
              </div>
              </div>
            </form>
          </div>
        </section>: null} */} 
        {sessionUser? 
         <section className="max-container1 w-full">
          {sessionUser && sessionUser.verified === "active" && <p className="mb-8 lg:mb-16 font-light text-center text-gray-900 sm:text-l"><span>If you do not see the catagory you are looking for, </span><span className="text-coral-red"><Link to="/contact">email us </Link></span><span>and we will add it for you.</span></p>}
         </section>: null}
      </div>
    
      <Footer />
    </div>
  )
}
