import React from 'react'
import Footer from '../Footer'

export const Pending = () => {
  return (
    <div className="flex flex-col min-h-screen justify-between">
        <div></div>
        <div className="max-container1 text-lg px-10">This account is currently pending verification. You will be notified once the account is approved.</div>
        <Footer/>
    </div>
  )
}
