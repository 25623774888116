import React from 'react'
import Footer from './Footer'

export const Missing = () => {
  return (
  
  
    
 
    <div class="flex flex-col  justify-between min-h-screen bg-white">
        <div class="flex flex-col max-container1">
           
            <span class="text-center font-bold my-10 opacity-30">
            </span>
    
           
            <div class="flex flex-col items-center">
                <div class="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
                    This page does not exist
                </div>
    
                <div class="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
                    The page you are looking for could not be found.
                </div>
            </div>
    
                      
                       
                  
                    
    
                  
                    
                
            
        </div>
        <Footer/>
    </div>
  )
}
