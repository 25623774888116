import React, { useState, useEffect } from "react";
import { useDispatch,  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllBusinesses } from "../../store/businesses";
import { fetchAddBusinessImage } from "../../store/businessimages";

export const EditVendorModal2 = ({id}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();

  const vendor_id = id
    const sessionUser = useSelector(state => state.session.user)
    const myVendor = useSelector((state) => state.business[vendor_id])

    const [showModal, setShowModal] = useState(false);
    const [img, setImg] = useState(null);
    const [imgtype, setImgType] = useState("");
    const [errors, setErrors] = useState("");
    const [hasSubmitted, setHasSubmitted] = useState({})

    useEffect(()=> {
        let errors = {}
        if(!img) errors.img = "img is required";
        if (imgtype.length ===  0) {
            errors.title = "type is required"
          }
        setErrors(errors);
    }, [img])

    useEffect(() => {
        dispatch(getAllBusinesses())
      }, [dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();

    const formData = new FormData(); 
    formData.append("imgtype", imgtype)
    formData.append("img", img)
    formData.append("business_id", vendor_id)
    
    setHasSubmitted(true);
    if(Object.keys(errors).length > 0) return;
    setErrors({});
    
    let addBusiness = dispatch(fetchAddBusinessImage(formData));
    
    if(addBusiness){
        setImg(null);
        setImgType("")
        setShowModal(false);
        dispatch(getAllBusinesses())
    }
    }  
  
  if(sessionUser.type !== "admin") navigate('/');  
  if (!sessionUser && sessionUser.status == "verified") navigate("/");
  return (
   <>
      <button
        className="bg-gray-900 text-white active:bg-coral-red font-regular text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Add Profile Image
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold ">
                    Add Profile Image
                  </h3>
                  <button
                    type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <form onSubmit={handleSubmit}>
                  <div className="relative p-6 flex-auto">
                    <div className="grid gap-4 md:grid-cols-4 sm:gap-6 my-3 leading-relaxed">
                      <div className="sm:col-span-4">
                        <div><label>Image Type:</label></div>
                        <select name="imageType" id="imageType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={imgtype} required onChange={(e) => setImgType(e.target.value)}>
                        <option value=""></option>
                          <option value="logo">Logo</option>
                          <option value="businesscard">Business Card</option>
                        </select>
                      </div>
                      <div className="sm:col-span-4">
                <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload Image<img src={img} alt="" style={{width:'50px'}}></img></label><span className='text-coral-red'></span>
                <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" onChange={(e) => setImg(e.target.files[0])} />
              </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  )
}
