import React from 'react'
import { useState } from "react";
// import * as sessionActions from "../../store/session"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

export const Otp = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate()

    let OTP = location.state.otp;
    let email = location.state.email
   
    const sessionUser = useSelector((state)=> state.session.user);
    // const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const [number1, setNumber1] = useState('')
    const [number2, setNumber2] = useState('')
    const [number3, setNumber3] = useState('')
    const [number4, setNumber4] = useState('')
    const [number5, setNumber5] = useState('')

   

    if(sessionUser) return navigate('/')

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if(parseInt(number1 + number2 + number3 + number4 + number5) === OTP){
            setNumber1("")
            setNumber2("")
            setNumber3("")
            setNumber4("")
            setNumber5("")
            navigate('/resetpassword',  {state: email })
            
        } else {
            alert("The code you have entered is not correct, try again re-send the link");
        }
    };

    

  return (
    <div className="sm:mt-28">
    <div className="" >
        <div className="flex bg-white rounded-lg shadow-xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
            <div className="hidden lg:block lg:w-1/2 bg-cover bg-[url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')]">
            </div>
            <div className="w-full p-8 lg:w-1/2">
                <h2 className="text-2xl font-semibold text-gray-700 text-center">Enter OTP</h2>
                <p className="text-l pt-4 text-gray-600 text-center">Check your email for the OTP</p>
                <div className="mt-4 flex items-center justify-between">
                    
                </div>
                <form onSubmit={handleSubmit}>
                    <ul>
                        {errors.map((error, idx)=> (
                            <li style={{color:'white', listStyle:"none"}} key={idx}>{error}</li>
                        ))}
                    </ul>
                    <div className="flex justify-center">
                <span className="mt-4">
                   
                    <input 
                    value={number1}
                    onChange={(e)=> setNumber1(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 text-center mx-2 appearance-none w-10"  type="text" maxLength={1} />
                </span>
                <span className="mt-4">
                  
                    <input 
                    value={number2}
                    onChange={(e)=> setNumber2(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 text-center mx-2 appearance-none w-10" type="text" maxLength={1} />
                </span>
                <span className="mt-4">
                   
                    <input 
                    value={number3}
                    onChange={(e)=> setNumber3(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 mx-2 text-center appearance-none w-10" type="text"maxLength={1} />
                </span>
                <span className="mt-4">
                   
                    <input 
                    value={number4}
                    onChange={(e)=> setNumber4(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 text-center mx-2 appearance-none w-10" type="text" maxLength={1}/>
                </span>
                <span className="mt-4">
    
                    <input 
                    value={number5}
                    onChange={(e)=> setNumber5(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-2 text-center  mx-2 w-10 appearance-none" type="text" maxLength={1}/>
                </span>

                </div>
                <div className="mt-8">
                    <button className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600" type="submit">Submit</button>
                </div>
                </form>
                <div className="mt-4 flex items-center justify-between">
                    {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                    {/* <a href="#" className="text-xs text-gray-500 uppercase">or sign up</a> */}
                    {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
