import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer'
import { getAllBusinesses } from '../../store/businesses'
import {getAllCatagories} from "../../store/catagories";
import { getAllBusiCats } from '../../store/businesscatagory';
import { star } from '../../assets/icons';

const Vendors = () => {

  const dispatch = useDispatch()
  const catagoryId = useParams().Id
  const navigate = useNavigate()

  const vendorsList = useSelector(state=> Object.values(state.business))
  const catagories = useSelector(state=> Object.values(state.catagory))
  const sessionUser = useSelector(state => state.session.user)
 
  const [filteredCatagory] = catagories.filter(ele=> ele.id === Number(catagoryId))
  
  const vendors1 = vendorsList && vendorsList.filter(ele => ele.catagoryList && ele.catagoryList.includes(Number(catagoryId)) && (ele.status == "active" || ele.status == "pending"))

  vendors1.sort((a,b) => a.avgRating > b.avgRating ? -1 : a.avgRating < b.avgRating ? 1 : 0)
 

  useEffect(()=> {
    dispatch(getAllBusinesses())
}, [dispatch])

useEffect(()=> {
  dispatch(getAllCatagories())
}, [dispatch])

useEffect(()=> {
  dispatch(getAllBusiCats())
}, [dispatch])


if(!filteredCatagory) return null;
if(!vendors1) return null;
if(!catagories) return null;

  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
      <div>

     
     
                  <div className="font-palenquin mt-10 max-container px-5 flex flex-col justify-start">
                
                    <h1 className="text-left text-3xl text-center">{filteredCatagory && filteredCatagory.catagory}</h1>
                 {vendors1.length > 0  && <p className="pt-5 pb-10  text-md text-center">Click on a business to view details or to leave a review</p>}
                    </div>

                    {vendors1.length > 0 ? <div className="w-full max-w-5xl mx-auto">
    <div className="py-2 inline-block w-full md:px-6 lg:px-8  bg-gray-100">
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table className='min-w-full divide-y divide-gray-100 '>
      
          <tbody className="divide-y divide-gray-200 bg-white">
          {vendors1.map((vendor)=> (
            <tr key={vendor.name} className="cursor-pointer" onClick={()=> navigate(`/vendordetails/${vendor.id}`, {state:{catagory:filteredCatagory.catagory}})}>
             
              <td  className="w-full sm:w-[30%] max-w-0 whitespace-nowrap text-sm text-gray-900  font-light  sm:max-w-none truncate pl-4 pr-3 py-4 sm:pl-6">
                {vendor.contact?vendor.contact:vendor.name}
              </td>
              <td  className="hidden sm:block w-full max-w-0 whitespace-nowrap text-sm text-gray-900  font-light  sm:max-w-none truncate pl-4 pr-3 py-4 sm:pl-6">
                {vendor.contact ? vendor.name:null}
              </td>
              <td className=" text-sm text-gray-900 font-light  px-3 py-4 whitespace-nowrap">
              {vendor.reviewCount === Number(1) ? vendor.reviewCount + " Review": vendor.reviewCount + " Reviews"}
              </td>
              <td className="hidden sm:block text-sm text-gray-900  py-4 pl-3 pr-4 sm:pr-6 font-light whitespace-nowrap text-right">
            <div className="flex flex-row items-center gap-1 justify-end"><span><img src={star} width={"12px"} alt=""/></span><span> ({Number(vendor.avgRating).toFixed(1) || 0}) </span></div>
              </td>
            
            </tr>
            // </Link>
           
          ))}
          </tbody>
        </table>
      </div>
    </div>
  </div> :<div className="max-container1 text-lg flex flex-col items-center gap-10  justify-center px-5 py-5 ">
                  <p>There is no {filteredCatagory && filteredCatagory.title} currently listed. Sorry we are not able to help you. </p>
                 {sessionUser && sessionUser.verified === "active" && <p>If you find someone through other resources, please add them to this section and leave a review. It will help other residents when they are in need.</p>}
                 {sessionUser && sessionUser.verified === "active" && <div className="mt-5 px-5"><Link to={`/addvendor/${filteredCatagory.id}`}><button className="bg-gray-700 hover:bg-coral-red text-white text-sm py-2 px-4 rounded font-montssarat max-container">Add a {filteredCatagory && filteredCatagory.title}</button></Link></div>}
                  </div> }

                
                {vendors1.length > 0 ?<section className="max-container1 mb-20">
                {sessionUser && sessionUser.verified === "active" && <p className="px-5 pt-10">Do you know any {filteredCatagory && filteredCatagory.title} that you have worked with, add them to this list and leave a review. It will help other residents find a {filteredCatagory && filteredCatagory.title} when they are in need. </p>}
               {sessionUser && sessionUser.verified === "active" && <div className=" mt-5 px-5"><Link to={`/addvendor/${filteredCatagory.id}`}><button className="bg-gray-700 hover:bg-coral-red text-white font-bold py-2 px-4 rounded font-montssarat w-full sm:w-fit">Add a {filteredCatagory && filteredCatagory.title}</button></Link></div>}
                </section>:null}
                
  

           
</div>
            <Footer className="">Footer</Footer>
            </div>
  )
}

//  <tr key={vendor.name} className="cursor-pointer" onClick={()=> !sessionUser? navigate('/login') : sessionUser.verified === "pending" ? navigate('/pending') : sessionUser.verified === "inactive" ?  navigate('/inactive') : navigate(`/vendordetails/${vendor.id}`, {state:{catagory:filteredCatagory.catagory}})}>

export default Vendors