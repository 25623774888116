import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Link} from "react-router-dom"
import Footer from '../Footer'
import { getAllEventDetails } from '../../store/eventdetails';
import { EditEventModal } from './EditEventModal';
import { DeleteEventModal } from './DeleteEventModal';

export const MyEvents = () => {
  const dispatch = useDispatch()

  let myEvents = useSelector(state => Object.values(state.eventDetails))
  const sessionUser = useSelector(state => state.session.user)


  let userId;
  if(sessionUser){
    userId = sessionUser.id
  }

  myEvents = myEvents.filter(ele => ele.user_id === userId)

  useEffect(()=>{
    dispatch(getAllEventDetails())
  }, [dispatch])
  
  function getDate(startDate){
    // const dateObj = new Date(startDate);
    // const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
   
    // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November", "December"];
    // const month = dateObj.getMonth()
    // const day = dateObj.getDay()
    // const date = dateObj.getDate() + 1
    // const year = dateObj.getFullYear()

    // return dayNames[day] + ", " + "" + date + " " + monthNames[month] + " " + year
    return startDate.split('T')[0]

  }

  return (
    <div className="flex flex-col justify-between min-h-screen">
      
      <div className="max-container"> 
      
                <div className="w-full shadow-3xl mb-10">{myEvents.map((detail)=> (
                  <div className="flex flex-col md:flex-row items-center bg-white px-5 py-5 gap-5 border-b-gray-100 border-b-2">
                      <div><img src={detail.img} alt="" width="230" height="230"/></div>
                      <div className="flex flex-col">
                      <div className="font-bold text-xl">{detail.name}</div>
                      <div>{detail.title}</div>
                        <div>{detail.address}</div>
                        {/* <div>{detail.contact}</div> */}
                        <div>{detail.description}</div>
                        <div>{detail.email}</div>
                        <div>Phone: {detail.phone}</div>
                        <div>Date: {detail.date1 && getDate(detail.date1)}</div>
                        <div>Time: {detail.startTime1} to {detail.endTime1}</div>
                        <div>Time: {detail.startTime2} to {detail.endTime2}</div>
                        <div>Price: {detail.price}</div>
                        <div>MLS Listing: {<Link to={detail.weblink}>Click here</Link> }</div> 
                        <div className="flex justify-start gap-3">
                         <div className="mt-8">
                    {detail.id && <Link to={`/myevents/edit/${detail.id}`} state={{ id: detail.id }}id={detail.id} key={detail.id} className="bg-gray-800 hover:bg-coral-red text-white font-bold py-2 px-4 w-full rounded">Edit Event</Link>}
                </div>  
                <div className="mt-8">
                    <DeleteEventModal id={detail.id} key={detail.id} className="bg-gray-800  hover:bg-coral-red text-white font-bold py-2 px-4 w-full rounded" type="submit"/>
                </div>
                </div>
                        </div>
                  </div>
                ))}</div>
                

      
      
      
      </div>
     
    
    <Footer/>
    
    </div>
  )
}
