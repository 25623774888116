import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import { fetchAllProducts } from '../../store/products'
import { getAllOrderitems, postOrderItem } from '../../store/orderitem'
import { adsample } from '../../assets/images'
import { homePageAdSample } from '../../assets/images'
// import Footer from '../Footer'

export const Product = () => {
  const dispatch = useDispatch()
  const products = useSelector(state => Object.values(state.products))
  const ads = useSelector(state => Object.values(state.orderitem))
  


  useEffect(()=> {
    dispatch(fetchAllProducts())
  }, [dispatch])

  useEffect(()=> {
    dispatch(getAllOrderitems())
  }, [dispatch])
  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
    <div className="max-container1 px-5 my-10">
       <h1 className="font-semibold text-xl mt-10">My Ads (current and upcoming)</h1>
       {ads.map(ad=> (
        <div className="flex flex-col lg:flex-row gap-10 my-5 justify-evenly bg-white px-10 pt-10 pb-5">
          <div className="flex flex-col">
          <div>Month: {ad.month}</div>
          <div>Year: {ad.year}</div>
          <div>Ad duration: 1 month</div>
          <div>appears on: {ad.page} page</div>
          </div>
          <div>
          <img src={adsample} alt="" width={"500px"} height={"100px"}></img>
          <div className="text-sm pt-2">change image</div>
          </div>
        </div>
       ))}

    <h1 className="font-semibold text-xl mt-10">Choose Products</h1>
    {products.map((product)=> (
      <div className="flex flex-col lg:flex-row gap-5 my-5 justify-between bg-white px-5 py-10">
         <div className="flex flex-col gap-2">
        <h3 className="text-lg font-semibold">Name: {product.name} Ad</h3>
        <div>Price: ${product.price} per month</div>
        <div>Location: {product.description}</div>
        <Link to ="/profilepage/productdetails" className="bg-coral-red rounded  text-white text-sm py-2 px-4 max-w-fit" state={{ id: product.id }}>Choose month and year</Link>
        </div>
        <div className="border-solid border-2 border-gray-100"><img src={homePageAdSample} alt="" width={"500px"} height={"100px"} ></img></div>
      </div>
    ))}

<h1 className="font-semibold text-xl mt-10">Past Ads</h1>
       {ads.map(ad=> (
        <div className="flex flex-col lg:flex-row gap-10 my-5 justify-evenly bg-white px-10 pt-10 pb-5">
          <div className="flex flex-col">
          <div>Month: {ad.month}</div>
          <div>Year: {ad.year}</div>
          <div>Ad duration: 1 month</div>
          <div>appears on: {ad.page} page</div>
          </div>
          <div>
          <img src={adsample} alt="" width={"500px"} height={"100px"}></img>
          <div className="text-sm pt-2">change image</div>
          </div>
        </div>
       ))}
    </div>
    {/* <Footer/> */}
    </div>
  )
}
