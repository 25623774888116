import React, { useEffect } from 'react'
import Footer from '../Footer'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {getAllEventDetails} from "../../store/eventdetails"

export const EventDetails = () => {
  const dispatch = useDispatch()
  const eventId = useParams().id;
  const events = useSelector(state => Object.values(state.eventDetails))
  
  useEffect(()=> {
    dispatch(getAllEventDetails())
  }, [dispatch])
  
  const newEvents = events.filter(event => event.event_id == Number(eventId))

  // function getDates(startDate, endDate){
  //   const newArr = []
  //   const startArr = startDate.split(' ')
  //   const endArr = endDate.split(' ')

  //   for (let i = startArr[1]; i <= endArr[1]; i++ ){
  //     newArr.push(i)
  //   }

  //   return newArr.join(', ');
  // }

  // function getMonth(startDate){

  //   const newDate = new Date(startDate)
  //   const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November", "December"];
  //   const month = newDate.getMonth()
  //   return monthNames[month]
  // }

  function getDate(startDate){
    // const dateObj = new Date(startDate);
    // const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
   
    // const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October","November", "December"];
    // const month = dateObj.getMonth()
    // const day = dateObj.getDay()
    // const date = dateObj.getDate() + 1
    // const year = dateObj.getFullYear()

    // return dayNames[day] + ", " + "" + date + " " + monthNames[month] + " " + year
    return startDate.split('T')[0]

  }

  if(!newEvents) return null
  if(!events) return null

  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">
      <div className="px-5 sm:py-10 max-container  bg-gray-100">
      <section>
        <div className="flex flex-col md:flex-row md:py-5 items-center bg-white my-10 px-10 py-5 md:gap-10 gap-5 justify-center shadow-3xl ">
        <div>Do you have a open house event to list?</div>
        <div className=""><Link to={`/events/addevents/${eventId}`}><button className="bg-gray-700 hover:bg-coral-red text-white font-bold py-2 px-4 rounded font-montssarat max-container">Add an Open House Event</button></Link></div>
        </div>
        </section>

      <div className="w-full shadow-3xl mb-10">{newEvents.map((event)=> (
                  <div className="flex flex-col md:flex-row items-center bg-white px-5 py-5 gap-5">
                <div><img src={event.img} alt="" width="200" height="200"/></div>
                <div className="flex flex-col">
                <div className="font-bold text-xl break-all">{event.title}</div>
                <div>{event.description}</div>
                <div className="break-all">{event.address}</div>
                <div className="break-all">Contact: </div>
                <div>Phone: {event.phone}</div>
                <div>Price: Price</div>
                <div>{event.date1 && getDate(event.date1)} {event.startTime1} to {event.endTime1}</div>
                <div>MLS Listing: {<Link to={event.weblink}>Click here</Link> }</div>
                </div>
                </div>
                ))}</div>
      </div>
       <Footer/>
    </div>
  )
}
