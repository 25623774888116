const LOAD_BUSINESSFEATURES = "businessfeatures/LOAD_BUSINESSFEATURES"
const ADD_BUSINESSFEATURES = "businessfeatures/ADD_BUSINESSFEATURES"
const UPDATE_BUSINESSFEATURES = "businessfeatures/UPDATE_BUSINESSFEATURES"

// all businesses
const load_businessfeatures = payload => ({
    type: LOAD_BUSINESSFEATURES,
    payload
})

const add_businessfeatures = payload => ({
    type: ADD_BUSINESSFEATURES,
    payload
})

const update_businessfeatures = payload => ({
    type: UPDATE_BUSINESSFEATURES,
    payload
})

// thunks
export const getAllBusinessfeatures = () => async dispatch => {
    const response = await fetch(`/api/businessfeatures`);
    if(response.ok){
        const payload = await response.json();
       
        dispatch(load_businessfeatures(payload));
    }
}

export const fetchAddBusinessFeature = (formData) => async dispatch => {
    const response =await fetch(`/api/businessfeatures/new`, {
        method: 'POST',
        body: formData
    })
    const payload = await response.json();
    dispatch(add_businessfeatures(payload))
    return payload
}

export const fetchUpdateBusinessFeature = (formData, businessId) => async dispatch => {
    const response = await fetch(`/api/businessfeatures/update/${businessId}`, {
        method: "POST",
        body: formData
    })
    const payload = await response.json();
    dispatch(update_businessfeatures(payload))
    return payload;
}

const initialState = {};
export default function businessfeaturesReducer(state = initialState, action){
    switch(action.type){
        case LOAD_BUSINESSFEATURES:
            const allBusinessFeatures= {};
            action.payload.forEach(ele =>allBusinessFeatures[ele.id]=ele)
            return {...state, ...allBusinessFeatures}

            case ADD_BUSINESSFEATURES:
                return {...state, [action.payload.id]: action.payload}
    
            case UPDATE_BUSINESSFEATURES:
                return {...state, [action.payload.id]: action.payload}

        default: 
            return state;
    }
}