import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBusinesses } from '../../store/businesses'
import  EditVendorModal1  from './EditVendorModal'
import { getAllBusiCats } from '../../store/businesscatagory'
import { getAllCatagories } from "../../store/catagories";

export const ViewVendors1 = () => {
    const dispatch = useDispatch()

    const vendors = useSelector(state=> Object.values(state.business))
    let allBusinessCat = useSelector((state => Object.values(state.businessCatagory)))
    let allCatagories = useSelector((state => Object.values(state.catagory)))
   

    const [filterStatus, setFilterStatus] = useState('')
    const [filterType, setFilterType] = useState('')
    const [filterResidency, setFilterResidency] = useState('')
    const [render, setRender] = useState([])
    const [showModal, setShowModal] = useState(false);

    
    
    let filteredVendor;
    if(!filterType && !filterStatus && !filterResidency){
        filteredVendor = vendors;
    }
    if(!filterResidency && !filterType && filterStatus){
        filteredVendor = vendors.filter(vendor => vendor.status === filterStatus)
    }
    if(!filterResidency && filterType && !filterStatus){
        filteredVendor = vendors.filter(vendor => vendor.type === filterType)
    }
    if(filterResidency && !filterType && !filterStatus){
        filteredVendor = vendors.filter(vendor => String(vendor.isresident) === filterResidency)
    }
    if(filterType && filterStatus && !filterResidency){
        filteredVendor = vendors.filter(vendor => vendor.type === filterType && vendor.status === filterStatus)
    }
    if(filterType && !filterStatus && filterResidency){
        filteredVendor = vendors.filter(vendor => vendor.type === filterType && String(vendor.isresident) === filterResidency)
    }
    if(filterType && filterStatus && filterResidency){
        filteredVendor = vendors.filter(vendor => vendor.type === filterType && vendor.status === filterStatus && String(vendor.isresident) === filterResidency)
    }

    

    useEffect(()=> {
      dispatch(getAllBusinesses())
  }, [dispatch])
  
  useEffect(()=> {
    dispatch(getAllCatagories())
  }, [dispatch])
  
  useEffect(()=> {
    dispatch(getAllBusiCats())
  }, [dispatch])
   
      const renderDispatch=(data)=> {
        setShowModal(false)
      }

     
    
  return (
    <div className=" flex flex-col min-h-screen justify-between">
        <div className="max-container">
        <div className="flex flex-row gap-10">
          <div>{render}</div>
        <div>Filter by Status: 
        <select name="filterStatus" id="filterStatus" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={filterStatus}  required onChange={(e) => setFilterStatus(e.target.value)}>
                  <option value=""></option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">Pending</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
        </div>
        <div>Filter by type: 
        <select name="filterType" id="filterType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={filterType}  required onChange={(e) => setFilterType(e.target.value)}>
                  <option value=""></option>
                  <option value="vendor">Vendor</option>
                  <option value="service">Service</option>
                  <option value="business">Business</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
        </div>
        <div>Filter by residency: 
        <select name="filterResidency" id="filterResidency" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={filterResidency}  required onChange={(e) => setFilterResidency(e.target.value)}>
                  <option value=""></option>
                  <option value="true">true</option>
                  <option value="false">false</option>
                 
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
        </div>
        </div>
            {/* <div classname="">{filteredVendor.map(vendor => (
                <div className="flex sm:flex-row border-b-2 pt-2 gap-5 justify-between">
                <div>{vendor.name}</div>
                <div>{vendor.type}</div>
                <div>{vendor.status}</div>
                <div>In Riverstone:{ String(vendor.inriverstone)}</div>
                <div>Is Resident: {String(vendor.isresident)}</div>
                <div>User id: {vendor.user_id}</div>
               
                <div className="mt-5 flex flex-row gap-2"><EditVendorModal id={vendor.id} key={vendor.id}/></div>

                </div>
            ))}</div> */}

        </div>
        <div className="flex flex-col overflow-x-auto max-container">
      <div className="sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
          <div className="overflow-x-auto">
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b font-medium dark:border-neutral-500">
                <tr>
                  <th scope="col" className="px-6 py-4">Company</th>
                  <th scope="col" className="px-6 py-4">Contact</th>
                  <th scope="col" className="px-6 py-4">Type</th>
                  <th scope="col" className="px-6 py-4">Status</th>
                  <th scope="col" className="px-6 py-4">In Riverstone</th>
                  <th scope="col" className="px-6 py-4">Is Resident</th>
                  <th scope="col" className="px-6 py-4">User id</th>
                  {/* <th scope="col" className="px-6 py-4">Edit</th> */}
                 
                </tr>
              </thead>
              <tbody>
              {filteredVendor.map((vendor, idx) => (
                <tr key={vendor.id} className="border-b dark:border-neutral-500">
                  <td className="whitespace-nowrap px-6 py-4" >{vendor.name}</td>
                  <td className="whitespace-nowrap px-6 py-4">{vendor.contact}</td>
                  <td className="whitespace-nowrap px-6 py-4">{vendor.type}</td>
                  <td className="whitespace-nowrap px-6 py-4">{vendor.status}</td>
                  <td className="whitespace-nowrap px-6 py-4">{ String(vendor.inriverstone)}</td>
                  <td className="whitespace-nowrap px-6 py-4">{String(vendor.isresident)}</td>
                  <td className="whitespace-nowrap px-6 py-4">{vendor.user_id}</td>
                  <td className="whitespace-nowrap px-6 py-4"><div><EditVendorModal1 key={vendor.id} id={vendor.id}  renderDispatch={renderDispatch} showModal={showModal} setShowModal={setShowModal}/></div></td>
                </tr>
                
               
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  
        <Footer/>
    </div>
  )

}
