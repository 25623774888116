import React, { useEffect, useState } from 'react'
import Footer from '../Footer';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'

import { EditDisplayNameModal } from './EditDisplayNameModal';
import * as sessionActions from "../../store/session"
import { EditProfileImageModal } from './EditProfileImageModal';
import { avatars } from '../../constants'
import { profile } from '../../assets/icons';
import { avatar1, avatar2,avatar3 } from '../../assets/icons';


export const ProfilePage = () => {

    const navigate = useNavigate()

    const sessionUser = useSelector(state => state.session.user)
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false)
    
    const handleClose = () => {
        setShow(false)
        sessionActions.restoreUser()
    }
    const handleShow = () => setShow(true)

    // useEffect(()=> {
    //     setAvatar(sessionUser.avatar)
    // },[avatar])

    const handleClose1 = () => {
        setShow1(false)
        sessionActions.restoreUser()
    }

    const handleShow1 = () => setShow1(true)


    const months = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];

    function findImage(value) {
        let result = avatars.find(ele => ele.name == value)
        return result.img;
    }

    function getDate(date) {
        const date1 = new Date(date)
        const monthNum = date1.getMonth()
        const month = months[monthNum]
        const year = date1.getFullYear()

        return month + " " + year
    }

    if (!sessionUser) navigate('/')
    return (

        <div className="bg-gray-100 min-h-screen flex flex-col justify-between">

            {sessionUser.verified === 'active' && <div className="max-container1">
                <div className="container mx-auto my-5 p-5">
                    <div className="md:flex no-wrap md:-mx-2 ">
                        <div className="w-full md:w-3/12 md:mx-2 w-fit">
                            <div className="bg-white p-3 border-t-4 border-coral-red">
                                <div className="image overflow-hidden">
                                    {sessionUser.avatar==="ava1" && <img className="h-auto w-full mx-auto"
                                        src={avatar1} 
                                        width={200} height={200}
                                        alt="" />}
                                         {sessionUser.avatar==="ava2" && <img className="h-auto w-full mx-auto"
                                        src={avatar2} 
                                        width={200} height={200}
                                        alt="" />}
                                         {sessionUser.avatar==="ava3" && <img className="h-auto w-full mx-auto"
                                        src={avatar3} 
                                        width={200} height={200}
                                        alt="" />}
                                </div>
                                <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">{sessionUser.first_name} {sessionUser.last_name}</h1>
                                {/* <h3 className="text-gray-600 font-lg text-semibold leading-6">Owner at Her Company Inc.</h3> */}
                                {/* <p className="text-sm text-gray-500 hover:text-gray-600 leading-6">Lorem ipsum dolor sit amet
                        consectetur adipisicing elit.
                        Reprehenderit, eligendi dolorum sequi illum qui unde aspernatur non deserunt</p> */}
                                <ul
                                    className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                                    <li className="flex items-center py-3">
                                        <span>Status</span>
                                        <span className="ml-auto"><span
                                            className="bg-coral-red py-1 px-2 rounded text-white text-sm">{sessionUser.verified}</span></span>
                                    </li>
                                    <li className="flex items-center py-3">
                                        <span>Member since</span>
                                        <span className="ml-auto">{getDate(sessionUser.createdAt)}</span>
                                    </li>
                                </ul>
                            </div>

                            <div className="my-4"></div>


                        </div>

                        <div className="w-full md:w-9/12">

                            <div className="bg-white p-3 shadow-sm rounded-sm">
                                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                                    <span clas="text-green-500">
                                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                    </span>
                                    <span className="tracking-wide">About</span>
                                </div>
                                <div className="text-gray-700">
                                    <div className="grid md:grid-cols-2 text-sm">
                                        <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">First Name</div>
                                            <div className="px-4 py-2">{sessionUser && sessionUser.first_name}</div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Last Name</div>
                                            <div className="px-4 py-2">{sessionUser && sessionUser.last_name}</div>
                                        </div>
                                        {/* <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Gender</div>
                                <div className="px-4 py-2">Female</div>
                            </div> */}
                                        {/* <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Contact No.</div>
                                <div className="px-4 py-2">+11 998001001</div>
                            </div> */}
                                        {/* <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Current Address</div>
                                <div className="px-4 py-2">Beech Creek, PA, Pennsylvania</div>
                            </div> */}
                                        {/* <div className="grid grid-cols-2">
                                <div className="px-4 py-2 font-semibold">Permanant Address</div>
                                <div className="px-4 py-2">Arlington Heights, IL, Illinois</div>
                            </div> */}
                                        <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Email</div>
                                            <div className="px-4 py-2">
                                                <a className="text-blue-800" href="mailto:jane@example.com">{sessionUser && sessionUser.email}</a>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <div className="px-4 py-2 font-semibold">Display name</div>
                                            <div className="px-4 py-2 truncate">{sessionUser && sessionUser.username}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <button
                        className="block w-full text-blue-800 text-sm font-semibold rounded-lg hover:bg-gray-100 focus:outline-none focus:shadow-outline focus:bg-gray-100 hover:shadow-xs p-3 my-4">Show
                        Full Information</button> */}
                            </div>


                            <div className="my-4"></div>


                            <div className="bg-white p-3 shadow-sm rounded-sm">

                                <div className="grid grid-cols-2 md:grid-cols-3">
                                    <div>
                                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                            {/* <span clas="text-green-500">
                                    <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>
                                </span> */}
                                            {/* <span className="tracking-wide">Experience</span> */}
                                        </div>
                                        <ul className="list-inside space-y-2">
                                           
                                            <li>
                                                <div className="text-gray-800 text-sm font-normal">Change Password</div>

                                            </li>
                                            {/* <li>
                                <div className="text-gray-800 text-sm font-normal"><Link to ="/profilepage/myevents">My Events</Link></div>
                                  
                                </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal">My Services</div>
                                   
                                </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal"><Link to ="/profilepage/mybusinesses">My Businesses</Link></div>
                                   
                                </li> */}
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                            {/* <span clas="text-green-500">
                                    <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                        <path fill="#fff"
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                    </svg>
                                </span> */}
                                            {/* <span className="tracking-wide">Education</span> */}
                                        </div>
                                        <ul className="list-inside space-y-2">
                                            {/* <li>
                                                <div className="text-gray-800 text-sm font-normal">Change Password</div>

                                            </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal">My Job Postings</div>
                                  
                                </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal"><Link to ="/profilepage/myopenhouse">My Open House Listing</Link></div>
                                   
                                </li> */}
                                 <li>
                                                {sessionUser && sessionUser.verified === "active" && <div className="text-gray-800 text-sm font-normal"><Link to="/profilepage/myreviews">My Reviews</Link></div>}
                                                {/* <div className="text-gray-500 text-xs">March 2020 - Now</div> */}
                                            </li>
                                            {/* <li>
                                                <div className="text-gray-800 text-sm font-normal"><Link to="/profilepage/myads">My Ads</Link></div>

                                            </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal">My Open Houses</div> */}

                                            {/* </li> */}
                                        </ul>
                                    </div>
                                    <div>
                                        <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                                            {/* <span clas="text-green-500">
                                    <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke="currentColor">
                                        <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                        <path fill="#fff"
                                            d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2"
                                            d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                    </svg>
                                </span> */}
                                            {/* <span className="tracking-wide">Education</span> */}
                                        </div>
                                        <ul className="list-inside space-y-2">
                                            <li>
                                                {sessionUser && sessionUser.verified === "active" && <div className="text-gray-800 text-sm font-normal"><EditDisplayNameModal show={show}  setShow={setShow} handleClose={handleClose} handleShow={handleShow} /></div>}
                                                {/* <div className="text-gray-500 text-xs">March 2020 - Now</div> */}
                                            </li>
                                            <li>
                                                {sessionUser && sessionUser.verified === "active" && <div className="text-gray-800 text-sm font-normal"><EditProfileImageModal show1={show1} setShow1={setShow1} handleClose1={handleClose1} handleShow1={handleShow1} /></div>} 
                                                 {/* <div className="text-gray-500 text-xs">March 2020 - Now</div> */}
                                            </li>
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal"><Link to="/profilepage/mysalepostings">My Sale Postings</Link></div>
                                   
                                </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal">Change Password</div>
                                   
                                </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal">My Ads</div>
                                   
                                </li> */}
                                            {/* <li>
                                    <div className="text-gray-800 text-sm font-normal">My Open Houses</div>
                                   
                                </li> */}
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>}
            {sessionUser.verified === 'pending' && <div className="max-container1">
                <div className="mx-auto my-10 p-5 bg-white">
                    <div className="md:flex no-wrap md:-mx-2 bg-white">

                        <div className="w-full md:w-full md:mx-2 bg-white">


                            <div className="bg-white p-3 ">
                                This account is in pending status. once, the account is verified, the user will have full access to all features.
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
           
            <Footer />
        </div>
    )
}
