const LOAD_EVENTS = "events/LOAD_EVENTS"

// all events
const load_events = payload => ({
    type: LOAD_EVENTS,
    payload
})


// Thunks
// get all events
export const getAllEvents = () => async dispatch => {
    const response = await fetch(`/api/events`);
    if (response.ok){
        const payload = await response.json();
        dispatch(load_events(payload))
    }
}

const initialState = {};
export default function eventReducer(state = initialState, action){
    switch(action.type){
        case LOAD_EVENTS:
            const allEvents = {}
            action.payload.forEach(ele=> allEvents[ele.id]=ele)
            return {...state, ...allEvents}

        default: 
            return state;    

    }
}