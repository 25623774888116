import { csrfFetch } from "./csrf";
const LOAD_ORDER = "order/LOAD_ORDER"
const ADD_ORDER = "order/ADD_ORDER"


// all products
const load_order = payload => ({
    type: LOAD_ORDER,
    payload
})

const add_order = payload => ({
    type:ADD_ORDER,
    payload
})

export const getAllOrders = (userId) => async dispatch => {
    const response = await fetch(`/api/order/${userId}`);
   
    if (response.ok) {
        const payload = await response.json();
        
        dispatch(load_order(payload));
    }
}

export const postOrder = (user_id) => async dispatch => {
    
    const response = await csrfFetch('/api/order/new', {
        method:"POST",
        headers: { "Content-Type": "application/json"},
        body:JSON.stringify(user_id),
    });
    const payload = await response.json();
    dispatch(load_order(payload));
    return payload;
}

const initialState = {};
export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ORDER:
            return {...state, ...action.payload}
        case ADD_ORDER:
            return {...state, ...action.payload}    
        default:
            return state;
    }
}