import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getAllEventDetails} from '../../store/eventdetails';
import Datepicker from "react-tailwindcss-datepicker";
import { useLocation } from 'react-router-dom'
import Footer from "../Footer";


export const EditEvent = (id) => {

  let { state } = useLocation();
  let event_id = state.id

  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [showModal, setShowModal] = useState(false);

  

  const event = useSelector((state => state.eventDetails[event_id]))
  const sessionUser = useSelector((state)=> state.session.user)

  let user_id;
  if(sessionUser){
    user_id=sessionUser.id
  }

  useEffect(()=>{
    dispatch(getAllEventDetails())
  }, [dispatch])


  const [date1, setDate1] = useState(event && event.date1);
  const [date2, setDate2] = useState({ startDate: null, endDate: new Date().setMonth(1)});
  const [date3, setDate3] = useState({ startDate: null, endDate: new Date().setMonth(1)});
    const [title, setTitle] = useState(event && event.title)
    const [address, setAddress] = useState(event && event.address)
    const [contact, setContact] = useState(event && event.contact)
    const [phone, setPhone] = useState(event && event.phone)
    const [email, setEmail] = useState(event && event.email)
    const [price, setPrice] = useState(event && event.price)
    const [weblink, setWeblink] = useState(event && event.weblink)
    // const [date1, setDate1] = useState("")
    const [hours, setHours] = useState(1)
    const [minutes, setMinutes] = useState('00')
    const[ampm, setAmpm] = useState('pm')
    const [hours1, setHours1] = useState(1)
    const [minutes1, setMinutes1] = useState(30)
    const[ampm1, setAmpm1] = useState('pm')
    const [img, setImg] = useState("")
    const [description, setDescription] = useState("")
    const [validationErrors, setValidationErrors] = useState({});
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);

    const onSubmit = async (e) => {

    }

    const handleValueChange = (newValue) => {
      setDate1(newValue);
  }

  return (

    
    <div className="flex flex-col h-screen justify-between">
      {/* <div className="px-5 sm:py-10 max-container"> */}
        <section className="w-full max-container">
            <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
                <h2 className="mb-4 text-xl font-bold text-gray-900">Add a new Event</h2>
                <form onSubmit={onSubmit} className="bg-white border border-gray-300 rounded p-5">
               
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}
                 <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                        <div className="sm:col-span-2">
                            <span><label for="title" className="block mb-2 text-sm font-medium text-gray-900">Property Title</label></span><span className='text-coral-red'> {hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
                            <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" required value = {title} onChange={(e) => setTitle(e.target.value)}/>
                        </div>
                        <div className="sm:col-span-2">
                            <label for="address" className="block mb-2 text-sm font-medium text-gray-900">Address</label><span className='text-coral-red'> {hasSubmitted && validationErrors.address && `${validationErrors.address}`}</span>
                            <input type="text" name="address" id="address" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="123 Street, Sugar Land, Tx 77479" required value = {address} onChange={(e) => setAddress(e.target.value)}/>
                        </div>
                        <div className="w-full">
                            <label for="contact" className="block mb-2 text-sm font-medium text-gray-900">Owner/ Realtor name</label><span className='text-coral-red'> {hasSubmitted && validationErrors.contact && `${validationErrors.contact}`}</span>
                            <input type="text" name="contact" id="contact" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Name of Realtor or Home owner" required value = {contact} onChange={(e) => setContact(e.target.value)}/>
                        </div>
                        <div className="w-full">
                            <label for="phone" className="block mb-2 text-sm font-medium text-gray-900">Phone</label><span className='text-coral-red'> {hasSubmitted && validationErrors.phone && `${validationErrors.phone}`}</span>
                            <input type="text" name="phone" id="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="832-000-0000" required value = {phone} onChange={(e) => setPhone(e.target.value)}/>
                        </div>
                        <div className="w-full">
                            <label for="email" className="block mb-2 text-sm font-medium text-gray-900">Email</label><span className='text-coral-red'> {hasSubmitted && validationErrors.email && `${validationErrors.email}`}</span>
                            <input type="text" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="name@email.com" required value = {email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>

                        <div className="w-full">
                            <label for="price" className="block mb-2 text-sm font-medium text-gray-900">Price</label><span className='text-coral-red'> {hasSubmitted && validationErrors.price && `${validationErrors.price}`}</span>
                            <input type="number" name="price" id="price" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="499,999" required value = {price} onChange={(e) => setPrice(e.target.value)}/>
                        </div>

                        <div className="flex sm:col-span-2 gap-3">
                        <div className="w-full">
                            <label for="date1" className="block mb-2 text-sm font-medium text-gray-900">Date 1</label><span className='text-coral-red'> {hasSubmitted && validationErrors.date1 && `${validationErrors.date1}`}</span>
                            <Datepicker
                                asSingle={true}
                                containerClassName="relative bg-gray-50 border border-gray-300  text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full"
                                displayFormat={"MM/DD/YY"}
                                // maxDate={new Date()}
                                minDate={new Date()}
                                valueDefault={null}
                                primaryColor={"gray-900"}
                                // startFrom={minDate()}
                                useRange={false}
                                value={date1}
                                onChange={handleValueChange}
                                required
                            />
                        </div>

                        <div className="w-full">
                            <label for="startTime1" className="block mb-2 text-sm font-medium text-gray-900"> From Time</label>
                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                <div className="flex">
                                    <select
                                        name="hours"
                                        className="bg-transparent text-sm appearance-none outline-none"
                                        required
                                        defaultValue="00"
                                        value = {hours}
                                        onChange={(e)=> setHours(e.target.value)}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <span class="text-sm mr-2">:</span>
                                    <select
                                        name="minutes"
                                        class="bg-transparent text-sm appearance-none outline-none mr-2"
                                        value={minutes}
                                        onChange={(e)=> setMinutes(e.target.value)}
                                    >
                                        <option value="0">00</option>
                                        <option value="30">30</option>
                                    </select>
                                    <select
                                        name="ampm"
                                        class="bg-transparent text-sm appearance-none outline-none"
                                        value={ampm}
                                        onChange={(e)=> setAmpm(e.target.value)}
                                    >
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="w-full">
                            <label for="endTime1" className="block mb-2 text-sm font-medium text-gray-900"> To Time</label>

                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                <div className="flex">
                                    <select
                                        name="hours"
                                        className="bg-transparent text-sm appearance-none outline-none"
                                        required
                                        
                                        value = {hours1}
                                        onChange={(e)=> setHours1(e.target.value)}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <span class="text-sm mr-2">:</span>
                                    <select
                                        name="minutes"
                                        class="bg-transparent text-sm appearance-none outline-none mr-2"
                                        value={minutes1}
                                        onChange={(e)=> setMinutes1(e.target.value)}

                                    >
                                        <option value="0">00</option>
                                        <option value="30">30</option>
                                    </select>
                                    <select
                                        name="ampm"
                                        class="bg-transparent text-sm appearance-none outline-none"
                                        value={ampm1}
                                        onChange={(e)=> setAmpm1(e.target.value)}
                                    >
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="sm:col-span-2"><span className='text-coral-red'> {hasSubmitted && validationErrors.ampm1 && `${validationErrors.ampm1}`}</span></div>
                    {/* <div className="flex sm:col-span-2 gap-3">
                        <div className="w-full">
                            <label for="date2" className="block mb-2 text-sm font-medium text-gray-900">Date 2</label><span className='error'> {hasSubmitted && validationErrors.date2 && `${validationErrors.date2}`}</span>
                            <Datepicker
                                asSingle={true}
                                containerClassName="relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-.5"
                                displayFormat={"DD/MM/YY"}
                                // maxDate={new Date()}
                                minDate={new Date()}
                                primaryColor={"gray-900"}
                                // startFrom={minDate()}
                                useRange={false}
                                value={value}
                                onChange={handleValueChange}
                                required=""
                            />
                        </div> */}

                        {/* <div className="w-full">
                            <label for="startTime2" className="block mb-2 text-sm font-medium text-gray-900"> From Time</label><span className='error'> {hasSubmitted && validationErrors.startTime2 && `${validationErrors.startTime2}`}</span>

                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                <div className="flex">
                                    <select
                                        name="hours"
                                        className="bg-transparent text-sm appearance-none outline-none"
                                        required=""
                                        defaultValue="00"
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <span class="text-sm mr-2">:</span>
                                    <select
                                        name="minutes"
                                        class="bg-transparent text-sm appearance-none outline-none mr-2"
                                    >
                                        <option value="0">00</option>
                                        <option value="30">30</option>
                                    </select>
                                    <select
                                        name="ampm"
                                        class="bg-transparent text-sm appearance-none outline-none"
                                    >
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="w-full">
                            <label for="item-weight" className="block mb-2 text-sm font-medium text-gray-900"> To Time</label><span className='error'> {hasSubmitted && validationErrors.name && `${validationErrors.name}`}</span>

                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                <div className="flex">
                                    <select
                                        name="hours"
                                        className="bg-transparent text-sm appearance-none outline-none"
                                        required=""
                                        defaultValue="00"
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <span class="text-sm mr-2">:</span>
                                    <select
                                        name="minutes"
                                        class="bg-transparent text-sm appearance-none outline-none mr-2"
                                    >
                                        <option value="0">00</option>
                                        <option value="30">30</option>
                                    </select>
                                    <select
                                        name="ampm"
                                        class="bg-transparent text-sm appearance-none outline-none"
                                    >
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div> */}
                    {/* <div className="flex sm:col-span-2 gap-3">
                        <div className="w-full">
                            <label for="item-weight" className="block mb-2 text-sm font-medium text-gray-900">Date 3</label><span className='error'> {hasSubmitted && validationErrors.name && `${validationErrors.name}`}</span>
                            <Datepicker
                                asSingle={true}
                                containerClassName="relative bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-.5"
                                displayFormat={"MM/DD/YY"}
                                // maxDate={new Date()}
                                minDate={new Date()}
                                primaryColor={"gray-900"}
                                // startFrom={minDate()}
                                useRange={false}
                                value={value}
                                onChange={handleValueChange}
                                required=""
                            />
                        </div>

                        <div className="w-full">
                            <label for="item-weight" className="block mb-2 text-sm font-medium text-gray-900"> From Time</label><span className='error'> {hasSubmitted && validationErrors.name && `${validationErrors.name}`}</span>

                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                <div className="flex">
                                    <select
                                        name="hours"
                                        className="bg-transparent text-sm appearance-none outline-none"
                                        required=""
                                        defaultValue="00"
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <span class="text-sm mr-2">:</span>
                                    <select
                                        name="minutes"
                                        class="bg-transparent text-sm appearance-none outline-none mr-2"
                                    >
                                        <option value="0">00</option>
                                        <option value="30">30</option>
                                    </select>
                                    <select
                                        name="ampm"
                                        class="bg-transparent text-sm appearance-none outline-none"
                                    >
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="w-full">
                            <label for="item-weight" className="block mb-2 text-sm font-medium text-gray-900"> To Time</label><span className='error'> {hasSubmitted && validationErrors.name && `${validationErrors.name}`}</span>

                            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                                <div className="flex">
                                    <select
                                        name="hours"
                                        className="bg-transparent text-sm appearance-none outline-none"
                                        required=""
                                        defaultValue="00"
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <span class="text-sm mr-2">:</span>
                                    <select
                                        name="minutes"
                                        class="bg-transparent text-sm appearance-none outline-none mr-2"
                                    >
                                        <option value="0">00</option>
                                        <option value="30">30</option>
                                    </select>
                                    <select
                                        name="ampm"
                                        class="bg-transparent text-sm appearance-none outline-none"
                                    >
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div> */}

                        <div className="sm:col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-900 " for="img">Upload file</label><span className='text-coral-red'> {hasSubmitted && validationErrors.img && `${validationErrors.img}`}</span>
                            <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none p-2.5" aria-describedby="user_avatar_help" id="img" type="file" accept=".jpg, .jpeg, .png, .webp" required onChange={(e) => setImg(e.target.files[0])}/>
                            <div className="mt-1 text-sm text-gray-500" id="user_avatar_help">Add a front exterior picture of your property</div>
                        </div>
                        <div className="sm:col-span-2">
                            <label for="weblink" className="block mb-2 text-sm font-medium text-gray-900">Web address of the Listing</label><span className='text-coral-red'> {hasSubmitted && validationErrors.weblink && `${validationErrors.weblink}`}</span>
                            <input type="text" name="weblink" id="weblink" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="123 Street, Sugar Land, Tx 77479" required value = {weblink} onChange={(e) => setWeblink(e.target.value)}/>
                        </div>
                        {/* <div>
                  <label for="category" className="block mb-2 text-sm font-medium text-gray-900">Category</label>
                  <select id="category" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5">
                      <option selected="">Select category</option>
                      <option value="TV">TV/Monitors</option>
                      <option value="PC">PC</option>
                      <option value="GA">Gaming/Console</option>
                      <option value="PH">Phones</option>
                  </select>
              </div> */}

                        <div className="sm:col-span-2">
                            <label for="description" className="block mb-2 text-sm font-medium text-gray-900">Description</label><span className='error'> {hasSubmitted && validationErrors.description && `${validationErrors.description}`}</span>
                            <textarea id="description" rows="8" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500" placeholder="Maximum 500 words. List important features of the property" value = {description} onChange={(e) => setDescription(e.target.value)}></textarea>
                        </div>
                    </div>
                    <button type="submit" className="w-full items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-gray-900 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                       Edit Open House Event
                    </button>
                    <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>    
                     {(imageLoading)&& <p>Loading...</p>}
               
                  </form>
                  </div>
        </section>
         {/* </div> */}
        <Footer/>
    </div>
  )
}
