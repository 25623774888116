import React, { useEffect, useState } from 'react'
import Footer from '../Footer'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllProducts } from '../../store/products'
import { getAllCatagories } from "../../store/catagories"
import { getAllCart, postCart } from '../../store/cart'
import { postCartItem } from '../../store/cartitem'
import { getAllBusinesses } from '../../store/businesses'
import { homePageAdSample } from '../../assets/images'
import {Button1} from '../Button/Button1'
import Button2 from '../Button/Button2'

export const Productdetails = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id = location.state.id;

  const product = useSelector(state => state.products[id])
  const catagories = useSelector(state => Object.values(state.catagory))
  const cart = useSelector(state => Object.values(state.cart))
  const sessionUser = useSelector(state => state.session.user)
  const [myCart] = cart
  
  let userId;
  if(sessionUser){
    userId = sessionUser.id
  }
  
  const business = useSelector(state=> Object.values(state.business))
  const myBusiness = business.filter(id => id.user_id === userId)

  let myPages = [];
  myBusiness.forEach(business => {
    business.Catagories.forEach(catagory=> {
        if(catagory.type === 'vendor' || catagory.type === 'service'){
          myPages.push(catagory.catagory)
        }
    })
  })

 myPages = myPages.filter((value, index) => myPages.indexOf(value)=== index)

  const thisYear = new Date().getFullYear();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const years = [thisYear, thisYear+1, thisYear+2]
  const thisMonth = months[new Date().getMonth()]
  const monthsThisYear = months.slice(new Date().getMonth() + 1)

  const pagelist = catagories.filter(item => item.type === "vendor" || item.type === "service")
  const arr = []
  pagelist.forEach(ele => arr.push(ele.catagory))

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [page, setPage] = useState("")
  const [errors, setErrors] = useState({})
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const count = 10

  useEffect(()=> {
    let errors = {};
    if(page.toLowerCase() == "painter" && year == 2024 && month == 'February') errors.invalid = "A slot is not available for the page for the month and year selected"
    if(product && product.name === "homepage" && year == 2024 && month == 'February' && count >= 10  ) errors.max = "Maximum slots for the month is reached. Please choose another month"

    setErrors(errors)
  },[page, year, month] )

  useEffect(() => {
    dispatch(fetchAllProducts())
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllCatagories())
  }, [dispatch])

  useEffect(()=> {
    dispatch(getAllCart(userId))
  }, [dispatch])

  useEffect(()=> {
    dispatch(getAllBusinesses());
}, [dispatch])

  const onSubmit = (e) => {
    e.preventDefault();

    if(myCart.id){
      const newAd = {
        year,
        month,
        page:page || 'homepage',
        cart_id:myCart.id,
        product_id: product.id,
        user_id:userId
      }

      setHasSubmitted(true)
      if(errors.length) return;
      const newCartItem = dispatch(postCartItem(newAd))
      if(newCartItem){
        setMonth("")
        setYear("")
        setPage("")
      }
      navigate(`/profilepage/cart/${myCart.id}`)
    } else {
      const cart = dispatch(postCart({userId}))
      if(cart){
        const newAd = {
          year,
          month,
          page:page || 'homepage',
          cart_id:cart.id,
          product_id: product.id,
          user_id:userId
        }
        const newCartItem = dispatch(postCartItem(newAd))
        if(newCartItem){
          setMonth("")
          setYear("")
          setPage("")
        }
        navigate(`/profilepage/cart/${cart.id}`)

      }
    }
  }

  if(!product) return null;

  return (
    <div className="flex flex-col min-h-screen justify-between bg-gray-100">

      <div className="max-container1">
        <h1 className="font-semibold text-xl text-left mt-10">Ad Submission Form</h1>
        <div className="flex flex-col lg:flex-row  bg-white p-5 mt-5 gap-5">
    
          

           <div className="flex flex-col lg:flex-row gap-5">
            <div className="flex flex-col gap-3">
            <div>Page: {product.name}</div>
            <div>Price: ${product.price} per month</div>
            {/* <div>{product.description}</div> */}
            { product.name === "homepage" && <div> The homepage advertising space features a maximum of 10 ads, guaranteeing industry exclusivity. This means, for example, no more than one realtor advertisement can be displayed at a time. </div>}
            <form onSubmit={onSubmit}>
            {errors && <p className="text-red-500">{errors.invalid}</p>}
            {errors && <p className="text-red-500">{errors.max}</p>}
              <label>Choose a year
                <select
                  name="year"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 mb-5"
                  value={year}
                  defaultValue={"placeholder"}
                  onChange={(e) => setYear(e.target.value)}
                >
                   <option selected={"placeholder"}>Select</option>

                  {/* <option value={thisYear + 1}>{thisYear + 1}</option>
                  <option value={thisYear + 2}>{thisYear + 2}</option>
                  <option value={thisYear}>{thisYear}</option> */}
                  {years.map(year => (
                    <option value ={year}>{year}</option>
                  ))}

                </select>
                  </label>
                <label>Choose a month
                <select
                  name="month"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 mb-5"
                  value={month}
                  defaultValue={"placeholder"}
                  required
                  onChange={(e) => setMonth(e.target.value)}
                >
                   <option value={"placeholder"}>Select</option>
                  {year == thisYear ? monthsThisYear.map(month => (
                    
                    <option value={month}>{month}</option>

                  )) : months.map(month => (
                    <option value={month}>{month}</option>

                  ))}
                </select>
              </label>
              { product.name === "vendorpage" && <label>Choose a page</label>}

              {product.name === "vendorpage" && <select
                name="page"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 mb-5"
                value={page}
                onChange={(e) => setPage(e.target.value)}
              >
                 <option value={"placeholder"}>Select</option>
                {myPages.map(catagory => (
                  <option value={catagory}>{catagory}</option>

                ))}
              </select>}
              <Button1 type='onSubmit'
              // disabled={errors && true}
              label="Add to Cart"
              />
            </form>
            </div>
              <div className="border-solid border-2 border-gray-100"><img src={homePageAdSample} alt="" width={"500px"} height={"100px"} ></img></div>
              </div>

          
        </div>
              <div className="text-coral-red pt-5">note* due to a limited number of ad slots and high demand, slots are not confirmed unless purchased. Items in cart will be deleted if not purchased within 24 hours</div>


      </div>

      <Footer />
    </div>
  )
}
