import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import sessionReducer from "./session";
import catagoryReducer from "./catagories";
import businessReducer from './businesses';
import reviewReducer from './review';
import businesscatagoryReducer from "./businesscatagory";
import eventReducer from "./events";
import eventdetailReducer from "./eventdetails";
import saleReducer from "./sale";
import businessImagesReducer from './businessimages';
import businesshoursImagesReducer from './businesshours';
import businessfeaturesReducer from './businessfeatures';
import userListReducer from "./userlist";
import productReducer from "./products";
import cartitemReducer from "./cartitem";
import cartReducer from "./cart";
import orderReducer from "./order";
import orderitemReducer from "./orderitem";
import searchReducer from "./search";
 
const rootReducer = combineReducers({
  session: sessionReducer,
  catagory: catagoryReducer,
  business: businessReducer,
  review:reviewReducer,
  businessCatagory: businesscatagoryReducer,
  event:eventReducer,
  eventDetails:eventdetailReducer,
  sales:saleReducer,
  businessImages:businessImagesReducer,
  businessHours: businesshoursImagesReducer,
  businessFeatures: businessfeaturesReducer,
  userList:userListReducer,
  products:productReducer,
  cartitems: cartitemReducer,
  cart:cartReducer,
  orderitem:orderitemReducer,
  order:orderReducer,
  search:searchReducer
});

let enhancer;

if (process.env.NODE_ENV === "production") {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require("redux-logger").default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
