import { shieldTick, support, truckFast, customer1, customer2  } from "../assets/images";
import { avatar1, avatar2, avatar3 } from "../assets/icons";
export const services = [
    {
        imgURL: truckFast,
        label: "100+ Catagories",
        subtext: "From cleaners to handymen, plumbers to electricians, we have a wide list of professionals for your next project."
    },
    {
        imgURL: shieldTick,
        label: "500+ Vendors",
        subtext: "We connect you with professionals so you can focus on other things in your life."
    },
    {
        imgURL: support,
        label: "1000+ Reviews",
        subtext: "Find out what other residents have to say before choosing the right person for your next project."
    },
];

export const reviews = [
    {
        imgURL: avatar1,
        customerName: 'Emily J.',
        rating: 5,
        feedback: "With a vast list of services ad vendors, it takes care of all my needs!"
    },
    {
        imgURL: avatar2,
        customerName: 'Jonathan S.',
        rating: 5,
        feedback: "This site deserves 10 out of 5 stars. My favorite place to be. !"
    },
    {
        imgURL: avatar3,
        customerName: 'Sarah M.',
        rating: 5,
        feedback: "I absolutely love using this website! The user interface is intuitive. Highly recommended!"
    }
];

export const catNotes = {
"Concrete": "Concrete includes Driveways, Patios, Porches, Sidewalks, Pathways",
"Tree Removal": "Removal of trees may require premits and also permission from the Riverstone management. Please check directly with them",
"Home Insurance": "This page lists home insurance companies and their services, mainly claim handling and prices. If you wish to compare rates and get the best HIC, browse for Insurance Agents under location services",
"Hindi": "Do not add any online/virtual language classes that is not located in Houston or within a reasonable driving distance from Riverstone. This will prevent overcrowding on the page and ensure visibility for local businesses.Our aim is to connect residents with local businesses.",
"Malayalam": "Do not add any online/virtual language classes that is not located in Houston or within a reasonable driving distance from Riverstone. This will prevent overcrowding on the page and ensure visibility for local businesses.Our aim is to connect residents with local businesses.",
"Tamil": "Do not add any online/virtual language classes that is not located in Houston or within a reasonable driving distance from Riverstone. This will prevent overcrowding on the page and ensure visibility for local businesses.Our aim is to connect residents with local businesses.",
"Telugu": "Do not add any online/virtual language classes that is not located in Houston or within a reasonable driving distance from Riverstone. This will prevent overcrowding on the page and ensure visibility for local businesses. Our aim is to connect residents with local businesses."
} 

export const avatars = [{img:avatar1, name:"ava1", id:1}, {img:avatar2, name:"ava2", id:2}, {img:avatar3, name:"ava3", id:3}]