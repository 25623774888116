import React from 'react'
import {Link} from 'react-router-dom'
import { Button1 } from '../Button/Button1'
import { check } from '../../assets/icons'

export const ChangePasswordSuccess = () => {
  return (
    <div className="sm:mt-28">
    <div className="" >
        <div className="flex bg-white rounded-lg shadow-xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
            <div className="hidden lg:block lg:w-1/2 bg-cover bg-[url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')]">
            </div>
            <div className="w-full flex flex-col items-center p-8 lg:w-1/2">
            <div className="px-2"><img src={check} alt="hero image" width={100} height={100}/></div>
                <h2 className="text-2xl font-semibold text-gray-700 text-center">Congratulations</h2>
                {/* <p className="text-l pt-4 text-gray-600 text-center">Please enter your email address and we'll send you instructions on how to reset your password</p> */}
                <div className="mt-4 flex items-center justify-between">
                    
                </div>
                <div className="pb-5">Your password has been changed successfully. Use your new password to log in.</div>
                <Link to='/login' className="full-width"><Button1 label="Log in" fullWidth="sm:col-span-3"/></Link>
                <div className="mt-4 flex items-center justify-between">
                    {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                    {/* <a href="#" className="text-xs text-gray-500 uppercase">or sign up</a> */}
                    {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                </div>
            </div>
        </div>
    </div>
    </div>

  )
}
