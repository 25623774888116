import React from 'react'
import Footer from '../Footer'

export const AboutUs = () => {
    return (
        <div className="h-screen flex flex-col justify-between">
            <div className="mt-10 xl:w-10/12 xl:mx-auto">
                <div className="flex flex-wrap justify-between overflow-hidden bg-white  text-slate-900  max-container1 border-b-2 border-gray-100 w-[90%]">
                    <label className="grow px-4 py-3 font-medium  hover:text-coral-red" for="collapse">Who are we?</label>
                    <input className="peer mx-4 my-3 h-0 w-0 appearance-none rounded border text-slate-800 accent-slate-600 opacity-0" type="checkbox" name="collapse" id="collapse" />
                    <div className="-transparent absolute -translate-y-full scale-75 scale-y-0 px-4 py-3 opacity-0 transition-all duration-200 peer-checked:relative peer-checked:translate-y-0 peer-checked:scale-100 peer-checked:scale-y-100 peer-checked:bg-white peer-checked:opacity-100">
                        We are residents of Riverstone who are passionate about this community and would like to help the residents find help with their needs from hiring a reliable plumber to enrolling their children in various activities and everything in between. We are open to suggestions and would like to make this website a hub to find solutions and make our lives better.
                    </div>
                </div>



                <div className="flex flex-wrap justify-betwwen overflow-hidden bg-white text-slate-900 max-container1 border-b-2 border-gray-100 w-[90%]">
                    <label className="grow px-4 py-3 font-medium  hover:text-coral-red" for="collapse2">Why do we need this website?</label>
                    <input className="peer mx-4 my-3 h-0 w-0 appearance-none rounded border text-slate-800 accent-slate-600 opacity-0" type="checkbox" name="collapse2" id="collapse2" />
                    <div className="-transparent absolute -translate-y-full scale-75 scale-y-0 px-4 py-3 opacity-0 transition-all duration-200 peer-checked:relative peer-checked:translate-y-0 peer-checked:scale-100 peer-checked:scale-y-100 peer-checked:bg-white peer-checked:opacity-100">Every subdivision within Riverstone has a whatsapp group where residents are constantly seeking recommendations for their home projects or for an emergency repair. This gave us an inspiration to put together a web app where residents can find skilled workers and also leave reviews which will help other residents choose the right person for their projects. This website is solely for  residents and businesses located within Riverstone.</div>
                </div>

                <div className="flex flex-wrap justify-betwwen overflow-hidden bg-white text-slate-900 max-container1 border-b-2 border-gray-100 w-[90%]">
                    <label className="grow px-4 py-3 font-medium  hover:text-coral-red" for="collapse3">What is the goal of this website?</label>
                    <input className="peer mx-4 my-3 h-0 w-0 appearance-none rounded border text-slate-800 accent-slate-600 opacity-0" type="checkbox" name="collapse3" id="collapse3" />
                    <div className="-transparent absolute -translate-y-full scale-75 scale-y-0 px-4 py-3 opacity-0 transition-all duration-200 peer-checked:relative peer-checked:translate-y-0 peer-checked:scale-100 peer-checked:scale-y-100 peer-checked:bg-white peer-checked:opacity-100">The goal of this website is to serve as a dynamic and engaging platform that showcases our passion and dedication to this commmunity by providing an array of choices and services for the residents.</div>
                </div>

                <div className="flex flex-wrap justify-betwwen overflow-hidden bg-white text-slate-900 max-container1 border-b-2 border-gray-100 w-[90%]">
                    <label className="grow px-4 py-3 font-medium  hover:text-coral-red" for="collapse4">Who are the target audience?</label>
                    <input className="peer mx-4 my-3 h-0 w-0 appearance-none rounded border text-slate-800 accent-slate-600 opacity-0" type="checkbox" name="collapse4" id="collapse4" />
                    <div className="-transparent absolute -translate-y-full scale-75 scale-y-0 px-4 py-3 opacity-0 transition-all duration-200 peer-checked:relative peer-checked:translate-y-0 peer-checked:scale-100 peer-checked:scale-y-100 peer-checked:bg-white peer-checked:opacity-100">This site is exclusively built for the current residents of Riverstone and businesses who operate in Riverstone.</div>
                </div>

                <div className="flex flex-wrap justify-betwwen overflow-hidden bg-white text-slate-900 max-container1 border-b-2 border-gray-100 w-[90%]">
                    <label className="grow px-4 py-3 font-medium  hover:text-coral-red" for="collapse5">Are we affiliated with the RS Management?</label>
                    <input className="peer mx-4 my-3 h-0 w-0 appearance-none rounded border text-slate-800 accent-slate-600 opacity-0" type="checkbox" name="collapse5" id="collapse5" />
                    <div className="-transparent absolute -translate-y-full scale-75 scale-y-0 px-4 py-3 opacity-0 transition-all duration-200 peer-checked:relative peer-checked:translate-y-0 peer-checked:scale-100 peer-checked:scale-y-100 peer-checked:bg-white peer-checked:opacity-100">No, we are not affiliated with the Riverstone management in any way. We are residents who abide and live by the rules and policies of this community</div>
                </div>
            </div>
            <Footer />

        </div>
    )
}
