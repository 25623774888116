import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { getAllBusinesses } from '../../store/businesses';
import Footer from '../Footer';
import { Button1 } from '../Button/Button1';
import { getAllCatagories } from '../../store/catagories';
import { fetchAddBusinessCatagory } from '../../store/businesscatagory';

export const AddSkills = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const businessId = location.state.id;
  const catagoryId = location.state.catagory_id;
  

  const business = useSelector(state => state.business[businessId]);
  let catagories = useSelector(state => Object.values(state.catagory));
  catagories = catagories.filter(ele => ele.type == business.type);
  let myCatagory = useSelector(state => state.catagory[catagoryId])



  let test1 = []
  catagories.forEach(ele => {
    test1.push({ id: ele.id, catagory: ele.catagory })
  })

  let test2 = []
  business && business.Catagories.forEach(ele => {
    test2.push({ id: ele.id, catagory: ele.catagory })
  })

  const test3 = test1.filter((el) => {
    return test2.some((f) => {
      return f.id !== el.id;
    });
  });

  let newArr = [];
  test3.forEach(item => newArr.push(item.catagory))

  const [toggle, setToggle] = useState(false)
  let [wordState, setWordState] = useState([]);
  const [errors, setErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false)


  useEffect(() => {
    let errors = {}
    if (test2.length + wordState.length >= 5) errors.wordState = "A maximum of five skills can be added for a vendor/business";
    setErrors(errors)
  }, [wordState])


  const changeHandler = (e) => {
    if (wordState.includes(e.target.value)) {
      let x = e.target.value;
      setWordState(wordState.filter(items => items !== x))
    } else {
      setWordState([...wordState, e.target.value])
    }
  }


  useEffect(() => {
    dispatch(getAllBusinesses());
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllCatagories());
  }, [dispatch])

  if (!business) navigate('/')

  const onSubmit = async (e) => {
    e.preventDefault();
    setHasSubmitted(true);

    if (Object.values(errors).length > 0) return;
    for (let i = 0; i < wordState.length; i++) {
      let catagory = catagories.find(ele => ele.catagory == wordState[i])

      await dispatch(fetchAddBusinessCatagory({ business_id: business.id, catagory_id: catagory.id })).then(results => {
        return results;
      })
        .catch(error => {
          setErrors(error)
        });
    }
    setWordState([]);
    navigate(`/vendordetails/${businessId}`)

  }

  if (!business) return null;
  if (!business.Catagories) return null;
  if (!catagories) return null
  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div className="max-container px-5">
      {business.type == "service" && 
      <>
       <h2 className="text-lg font-semibold my-10">A person / business with the entered phone number or email already exists in our system. </h2>
       <div className="flex flex-col md:flex-row gap-5 items-center">
         
          {business.contact && <div>Contact: {business.contact}</div>}
          {business.name && <div>Business name: {business.name}</div>}
          {business.phone1 && <div>Primary Phone: {business.phone1}</div>}
          {business.phone2 && <div>Phone 2: {business.phone2}</div>}
          {business.email && <div>Email: {business.email}</div>}
          <span>Type of Business: {business.Catagories && business.Catagories.map((catagory) => (
            <span>{catagory.catagory}, </span>
          ))}</span>
          <Link to={`/vendordetails/${business.id}`} className="bg-gray-900 rounded py-1 px-3 my-3  text-white">View Details</Link>
        </div>
        </>}

        {business.type == "vendor" && 
      <>
       <h2 className="text-lg font-semibold my-10">A technician/business with the entered name, business name, phone number or email already exists in our system.</h2>
       <div className="flex flex-col md:flex-row gap-5">
         
          {business.contact && <div>Name: {business.contact}</div>}
          {business.name && <div>Business name: {business.name}</div>}
          {business.phone1 && <div>Phone 1: {business.phone1}</div>}
          {business.phone2 && <div>Phone 2: {business.phone2}</div>}
          {business.email && <div>Email: {business.email}</div>}
          <span>Skills: {business.Catagories && business.Catagories.map((catagory) => (
            <span>{catagory.catagory}, </span>
          ))}</span>
        </div>
        <Link to={`/vendordetails/${business.id}`} className="bg-gray-900 rounded py-1 px-3 my-3  text-white">View Details</Link>

        {/* {toggle == false && <button className="bg-gray-900 rounded py-1 px-3 my-3  text-white" label="Add Skills" onClick={() => setToggle(!toggle)}>Add Skill</button>} */}
        {/* {toggle == true &&
          <div>
            <div className="mt-10 mb-3">Add more skills to {business.contact},  {business.name} </div>
            <form onSubmit={onSubmit}>
              <div className="border rounded flex flex-wrap">
                {newArr.map((word, idx) => (
                  <div className="flex flex-row mx-5 my-5">
                  <div className=" border rounded border-gray-300 pr-5" key={idx} ><label>
                    <input type="checkbox"
                    
                      value={word}
                      disabled= {  !word && test2.length + wordState.length >= 5 && true}
                      className="mx-3"
                      onChange={changeHandler}
                    />
                    {word}
                  </label></div>
                  </div>
                ))}
              </div>
              {setHasSubmitted && <p className="text-red-500">{errors.wordState && errors.wordState}</p>}
              {setHasSubmitted && errors.error && <p>errors.error</p>}
              <div className="flex gap-x-5 items-center">
                <button className="bg-gray-900 rounded py-1 px-3 my-3  text-white" type="submit">Submit</button>
                <button className="bg-gray-900 rounded py-1 px-3 my-3  text-white" type="button" onClick={()=> setWordState(wordState && wordState.splice(0, wordState.length))}>Reset</button>
                <Link to={`/vendors/${catagoryId}`} className="text-coral-red">Cancel</Link>
              </div>
            </form>
          </div>
        } */}
        </>}
      </div>
        
      <Footer />
    </div>
  )
}
