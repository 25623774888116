const LOAD_BUSINESSHOURS = "businesshours/LOAD_BUSINESSHOURS"
const ADD_BUSINESSHOURS = "businesshours/ADD_BUSINESSHOURS"
const UPDATE_BUSINESSHOURS = "businesshours/UPDATE_BUSINESSHOURS"


// all businesses
const load_businesshours = payload => ({
    type: LOAD_BUSINESSHOURS,
    payload
})

const add_businesshours = payload => ({
    type: ADD_BUSINESSHOURS,
    payload
})
const update_businesshours = payload => ({
    type: UPDATE_BUSINESSHOURS,
    payload
})


// thunks
export const getAllBusinesshours = () => async dispatch => {
    const response = await fetch(`/api/businesshour`);
    if(response.ok){
        const payload = await response.json();
       
        dispatch(load_businesshours(payload));
    }
}

export const fetchPostBusinessHours = (BusinessHoursForm) => async dispatch => {
    
    const response = await fetch('/api/business/businesshours/new', {
        method:"POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify(BusinessHoursForm)
    });
    if(response.ok){
        const payload = await response.json()
        dispatch(add_businesshours(payload))
        return payload
    }
}
export const fetchUpdateBusinessHours = (businessHoursUpdateform, businessid) => async dispatch => {

    const response = await fetch(`/api/business/businesshours/update/${businessid}`, {
        method:"POST",
        headers: { "Content-Type": "application/json",
    },
        body: JSON.stringify(businessHoursUpdateform)
    })
    if (response.ok) {
        const payload = await response.json()
        dispatch(update_businesshours(payload))
       
    }
 }


const initialState = {};
export default function businesshoursImagesReducer(state = initialState, action){
    switch(action.type){
        case LOAD_BUSINESSHOURS:
            const allBusinessImages = {};
            action.payload.forEach(ele =>allBusinessImages[ele.id]=ele)
            return {...state, ...allBusinessImages}

        case ADD_BUSINESSHOURS:
            return {...state, [action.payload.id]: action.payload} 
            
        case UPDATE_BUSINESSHOURS:
            return {...state, [action.payload.id]: action.payload}    
        
        default: 
            return state;
    }
}