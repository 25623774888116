import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUpdateUserList, getAllUsers } from "../../store/userlist";
// import './Review/addreview.css'

export const EditUserModal = (id) => {

    const navigate = useNavigate();
  const dispatch = useDispatch();

  const user_id = id.id

  
  
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state.userList[user_id])
  const sessionUser = useSelector((state)=> state.session.user)

  const images = ['ava1', 'ava2', 'ava3', 'ava0']
 
  const [verified, setVerified] = useState(user && user.verified);
  const [type, setType] = useState(user && user.type);
  const [avatar, setAvatar] = useState(user && user.avatar);
  const [validationErrors, setValidationErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(()=> {
    dispatch(getAllUsers())
}, [dispatch])

  useEffect(() => {
    let errors = {};
    if (verified.length === 0) {
      errors.verified = "verified is required"
    }
    if (type.length ===  0) {
      errors.type= "type is required"
    }
    setValidationErrors(errors);
  }, [verified, type])

  const onSubmit = async (e) => {
    e.preventDefault();

    const EditUserForm = 
    {verified, type, avatar}
 
    setHasSubmitted(true);
    if (Object.keys(validationErrors).length > 0) return;
    
    setValidationErrors({});

    let updateuser = dispatch(fetchUpdateUserList(EditUserForm, user_id))

    if (updateuser){
        setType('')
        setVerified('')
        setShowModal(false)
    }
  }
 
  if(sessionUser.type !== 'admin') navigate('/')
  if (!user) return null
  return (
    <>
    <button
      className="bg-gray-900 text-white active:bg-coral-red font-regular text-sm px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
      type="button"
      onClick={() => setShowModal(true)}
    >
      Edit
    </button>
    {showModal ? (
      <>
        <div
          className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        >
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex justify-between items-center p-5 border-b border-solid border-blueGray-200 rounded-t">
                <h3 className="text-3xl font-semibold ">
                 Edit User
                </h3>
               

                <button
                  type="submit" className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
               
              </div>
              {/*body*/}
              <form onSubmit={onSubmit}>
              <div className="relative p-6 flex-auto">
                {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                  I always felt like I could do anything. That’s the main
                  thing people are controlled by! Thoughts- their perception
                  of themselves! They're slowed down by their perception of
                  themselves. If you're taught you can’t do anything, you
                  won’t do anything. I was taught I could do everything.
                </p> */}
               <div className="grid gap-4 sm:grid-cols-2 sm:gap-6 my-3 leading-relaxed">
                  {/* <div className="sm:col-span-4 md:w-[32rem]">
                      <span><label for="verified" className="block mb-2 text-sm font-medium text-gray-900">Verified</label></span>
                      <span className='text-coral-red'> {hasSubmitted && validationErrors.verified && `${validationErrors.verified}`}</span>
                      <input type="text" name="verified" id="verified" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Good price. Great Service" required value = {verified} onChange={(e) => setVerified(e.target.value)}/>
                  </div>
                  <div className="sm:col-span-4">
                      <label for="type" className="block mb-2 text-sm font-medium text-gray-900">type</label>
                      <span className='text-coral-red'> {hasSubmitted && validationErrors.type && `${validationErrors.type}`}</span>
                      <input type="text" name="type" id="type" className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Describe your experience" required value = {type} onChange={(e) => setType(e.target.value)}/>
                  </div> */}
                   <div className="sm:col-span-4">
                    <div>{user.first_name} {user.last_name}</div>
                   </div>

                  <div className="sm:col-span-4">
                  <select name="filterVerified" id="filterVerified" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={verified}  required onChange={(e) => setVerified(e.target.value)}>

                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="pending">Pending</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
                    </div>
                    <div className="sm:col-span-4">
                    <select name="filterType" id="filterType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="4 bed 2.5+ bat 2,374 square feet 9,588 sqft lot" value={type}  required onChange={(e) => setType(e.target.value)}>
                  <option value=""></option>
                  <option value="admin">Admin</option>
                  <option value="owner">Owner</option>
                  <option value="tenant">Tenant</option>
                  {/* <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option> */}
                </select>
                    </div>
                    <div>
                      <select name = "avatar" id = "avatar" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" value={avatar} required onChange={(e) => setAvatar(e.target.value)}>
                        <option value={avatar}>{avatar}</option>
                        {images.map(image=> (
                          <option value={image}>{image}</option>
                        ))}

                      </select>
                    </div>
              </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-gray-800 text-white active:bg-gray-800 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  // type="button"
                  // onClick={() => setShowModal(false)}
                >
                  Edit User
                </button>
              </div>
                </form>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    ) : null}
  </>
  )
}
