import React from 'react'
import { useState, useEffect } from "react";
// import * as sessionActions from "../../store/session"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { csrfFetch } from "../../store/csrf";


export const ForgotPass = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const sessionUser = useSelector((state) => state.session.user);
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [OTP, setOTP] = useState("")
    const [hasSubmitted, setHasSubmitted] = useState(false)

    useEffect(() => {
        let newError = {};
        if (!(email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g))) newError.email = 'Not a valid email address'
        if (!email) newError.email = 'Email address is required'
        setErrors(newError)
    }, [email])

    // if (sessionUser) return <Navigate to="/" />;

    // function sendOtp() {
    //     if (email) {
    //       fetch(`http://localhost:5000/check_email?email=${email}`).then((response) => {
    //         if (response.status === 200) {
    //           const OTP = Math.floor(Math.random() * 9000 + 10000);
    //           
    //           setOTP(OTP);
    //         //   setEmail(email);

    //           csrfFetch("api/otp/send_email", {
    //             method:'POST',
    //             body: JSON.stringify({
    //                 email,
    //                 OTP
    //               }),
    //           })
    //         //   .then(() => setPage("otp"))
    //           .catch(console.log);
    //         } else {
    //           alert("User with this email does not exist!");
    //       
    //         }}).catch(console.log);
    //     } else {
    //       alert("Please enter your email");
    //     }}

    const handleCancel = () => {
        navigate('/')
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setHasSubmitted(true)
        if (Object.keys(errors).length > 0) return;

        if (email) {
            const response = await fetch(`/api/otp/check_email?email=${email}`)
            if (response.status === 200) {
                const OTP = Math.floor(Math.random() * 9000 + 10000);
                
                setOTP(OTP);

                await csrfFetch("api/otp/send_email", {
                    method: 'POST',
                    body: JSON.stringify({
                        recipient_email: email,
                        OTP
                    }),
                })
                //   const response = await data.json();
              
                // if (data) {
                // return <Link to={{ pathname: "/OTP", {state:  OTP }}></Link>
                navigate("/OTP",  {state:  {otp:OTP, email:email} }) 
                // }
            } else {
                alert("User with this email does not exist!");
            }
        } else {
            alert("Please enter your email");

            // setHasSubmitted(true)
            // return dispatch(sessionActions.checkEmail({email, OTP}))
            // .catch(async (res) => {
            //     const data = await res.json();
            //     if (data && data.errors) {
            //       setErrors(data.errors);

            //     } else {
            //         const OTP = Math.floor(Math.random()*9000 + 1000);
            //         setOTP(OTP);

            //         return <Link to={{pathname:"/OTP", state:{OTP}}}></Link>
            //     }
            //   });
        };
    }



    return (
        <div className="sm:mt-28">
            <div className="" >
                <div className="flex bg-white rounded-lg shadow-xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
                    <div className="hidden lg:block lg:w-1/2 bg-cover bg-[url('https://source.unsplash.com/0yfWDwHOB0g')]">
                    </div>
                    <div className="w-full p-8 lg:w-1/2">
                        <h2 className="text-2xl font-semibold text-gray-700 text-center">Reset Your Password</h2>
                        <p className="text-l pt-4 text-gray-600 text-center">Please enter your email address and we'll send you instructions on how to reset your password</p>
                        <div className="mt-4 flex items-center justify-between">

                        </div>
                        <form onSubmit={handleSubmit}>
                            <ul>
                                {errors.length > 0 && errors.map((error, idx) => (
                                    <li style={{ color: 'red', listStyle: "none" }} key={idx}>{error}</li>
                                ))}
                            </ul>
                            <div className="mt-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Email Address</label>
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                            </div>
                            <span className="text-coral-red">{hasSubmitted && errors.email && errors.email}</span>
                            <div className="mt-8">
                                <button className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600" type="submit">Send Reset Email</button>
                                <button className="bg-coral-red text-white font-bold py-2 px-4 mt-3 w-full rounded hover:bg-gray-600" onClick={handleCancel}>Cancel</button>
                            
                            </div>
                        </form>
                        <div className="mt-4 flex items-center justify-between">
                            {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                            {/* <a href="#" className="text-xs text-gray-500 uppercase">or sign up</a> */}
                            {/* <span className="border-b w-1/5 md:w-1/4"></span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
