import { useState, useEffect, useRef } from "react";
import * as sessionActions from "../../store/session"
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';

export const LoginPage = () => {
    const dispatch = useDispatch();
    const sessionUser = useSelector((state)=> state.session.user);
    const [credential, setCredential] = useState("");
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [hasSubmitted, setHasSubmitted] = useState(false);


    const captchaRef = useRef(null);

    const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;

    

    useEffect(()=>{
        let errors = {}
           if(credential.length < 4) errors.credential = "email must be atleast 4 characters";
           if (password.length < 6) errors.password = "Password must be 6 characters long";
           if(credential.length === 0) errors.credential = "email is required"
           setErrors(errors)
       }, [credential, password])

  
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const captchaValue = captchaRef.current.getValue();
        const captchaValue = await captchaRef.current.executeAsync()
        captchaRef.current.reset()
      
            setHasSubmitted(true);
         if(Object.keys(errors).length > 0) return;
     
             return dispatch(sessionActions.login({ credential, password, captchaValue }))
               .catch(async (res) => {
                 const data = await res.json();
                 if (data && data.errors) {
                   setErrors(data.errors);
                 }
               });
        // }


      };

    if(sessionUser) return <Navigate to="/"/>;

  return (
    <div className="sm:mt-28">
    <div className="" >
        <div className="flex bg-white rounded-lg shadow-xl overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
            <div className="hidden lg:block lg:w-1/2 bg-cover bg-[url('https://source.unsplash.com/0yfWDwHOB0g')]">
            </div>
            <div className="w-full p-8 lg:w-1/2">
                <h2 className="text-2xl font-semibold text-gray-700 text-center">Residents</h2>
                <p className="text-xl text-gray-600 text-center">Welcome back!</p>
               
                <div className="mt-4 flex items-center justify-between">
                  
                </div>
                <form onSubmit={handleSubmit}>
                <ul>
                       {errors.length > 0 && errors.map((error, idx)=> (
                            <li style={{color:'black', listStyle:"none"}} key={idx}>{error}</li>
                        ))}
                    </ul>
                <div className="mt-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2"><span>Email Address </span><span className="text-coral-red">{hasSubmitted && errors.credential && errors.credential}</span></label>
                    <input 
                    value={credential}
                    maxLength={100}
                    onChange={(e)=> setCredential(e.target.value)} required className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" type="text" />
                </div>
                <div className="mt-4">
                    <div className="flex justify-between">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                        <Link to={'/forgotpassword'}><a href="#" className="text-xs text-gray-500">Forget Password?</a></Link>
                    </div>
                    <input 
                    className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none" 
                    type={
                        showPassword ? "text" : "password"
                    }
                    value={password}
                    required
                    maxLength={100}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="flex flex-row gap-2 py-2">
                    <label for="check" className="text-xs text-gray-500">Show Password</label>
                <input
                className="text-xs text-gray-500"
                    id="check"
                    type="checkbox"
                    value={showPassword}
                    onChange={() =>
                        setShowPassword((prev) => !prev)
                    }
                />
                </div>
                </div>
                {/* {errors.credential && <p>{errors.credential}</p>} */}
                <ReCAPTCHA
              className="recaptcha"
              sitekey={SITE_KEY}
              ref={captchaRef}
              size="invisible"
            />
                <div className="mt-8">
                    <button 
                    className="bg-gray-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600" 
                    type="submit"
                    disabled= {errors.credential || errors.password? true : false}
                    >Login</button>
                </div>
                </form>
                <div className="mt-4 flex items-center justify-between">
                    <span className="border-b w-1/5 md:w-1/4"></span>
                    <Link to={'/signup'}><a href="#" className="text-xs text-gray-500 uppercase">or sign up</a></Link>
                    <span className="border-b w-1/5 md:w-1/4"></span>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
