import React from 'react'
import Footer from '../Footer'
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from 'react';
import {Link } from 'react-router-dom'
import {getAllEvents} from "../../store/events"

export const Events = () => {
  const dispatch = useDispatch()

const events = useSelector(state=> Object.values(state.event))



  useEffect(()=> {
    dispatch(getAllEvents())
}, [dispatch])

if(!events) return null

  return (
   
    <div className="flex flex-col h-screen justify-between bg-gray-100">
                {/* <div className="max-container"> */}
                <div className="px-10 py-10 max-container">
                <h1 className='py-20 text-2xl'>What are you looking for?</h1>
                <section className="max-container flex flex-row justify-center flex-wrap gap-4 mb-10">
                {/* {services.map((service)=> (
                  <ServiceCard key={service.label} {...service}/>
                ))} */}

                {events.map((event)=> (
                  <div className="flex-1 sm:w-[350px] 
              sm:min-w-[200px] w-full rounded-
              [20px] shadow-3xl px-5 py-5"><Link to ={`/events/eventdetails/${event.id}`}>{event.name}</Link></div>
                ))}
              
                </section>
                <p>If you do not see the Catagory you are looking for, email us using the Contact Us form and we will add it for you.</p>
                </div>
              {/* </div> */}
            <Footer/>
</div>
  )
}
