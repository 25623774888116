import React, {useEffect, useState} from 'react'
import Footer from '../../Footer'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchPostReview } from '../../../store/review'
import StarRatingInput from './starAnimate'

export const AddReview = () => {

    const business_id = useParams().id

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sessionUser = useSelector(state=> state.session.user)

    let user_id;
    if(sessionUser){
        user_id = sessionUser.id
    }

    const [review, setReview] = useState('')
    const [rating, setRating] = useState('')
    const [title, setTitle] = useState('')
    const [validationErrors, setValidationErrors] = useState({})
    const [hasSubmitted, setHasSubmitted] = useState()
    
    useEffect(()=> {
        const errors = {}
        if(title.length === 0) errors.title = 'Title is required';
        if(title.length > 50) errors.title = 'Title must be 50 characters or less'
        if(review.length < 10) errors.review = 'Review must be atleast 10 characters';
        if(review.length > 500) errors.review = 'Review must be less than 500 characters';
        if(rating === 0 || rating > 5 || rating < 1) errors.rating = 'Rating must be between 1 and 5';
        
        setValidationErrors(errors)
    }, [title, review, rating])

    const handleCancel = () => {
        navigate('/admin/dashboard')
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const newReview = {
            user_id,
            business_id,
            title,
            review,
            rating,
            status:'active'
        }

        setHasSubmitted(true);
        if(Object.keys(validationErrors).length > 0) return;
       

        let newCatagory = await dispatch(fetchPostReview(newReview))

        if(newCatagory){
            setTitle('')
            setReview('')
            setRating('')
            navigate(`/vendordetails/${business_id}`);
        }
    }

    const onChange = (number) => {
        setRating(parseInt(number));
      };

  return (
    <div className="flex flex-col h-screen justify-between" >
    <div className="max-container mt-20 w-full md:w-[700px]">
    <h3 className="text-3xl font-semibold ml-10">
                   Add your Review
                  </h3>
    <form onSubmit={onSubmit} className="bg-white border border-gray-300 rounded p-5 mx-5 mb-10">
                <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                    <div className="sm:col-span-2">
                        <span><label for="title" className="block mb-2 text-sm font-medium text-gray-900">Title</label></span><span className='text-coral-red'> {hasSubmitted && validationErrors.title && `${validationErrors.title}`}</span>
                        <input type="text" name="title" id="title" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Good price. Great Service" required value = {title} onChange={(e) => setTitle(e.target.value)}/>
                    </div>
                    <div className="sm:col-span-2">
                        <label for="review" className="block mb-2 text-sm font-medium text-gray-900">Review</label><span className='text-coral-red'> {hasSubmitted && validationErrors.review && `${validationErrors.review}`}</span>
                        <textarea type="text" rows={10} name="review" id="review" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="Describe your experience" required value = {review} onChange={(e) => setReview(e.target.value)}/>
                    </div>
    
                    <div className="sm:col-span-2">
                        <label for="rating" className="block mb-2 text-sm font-medium text-gray-900">Rating</label>
                        <div ><StarRatingInput disabled={false} onChange={onChange} stars={rating} /></div>
                <div className="text-coral-red">{hasSubmitted && validationErrors.rating && `${validationErrors.rating}`}</div>
                </div>
                </div>
                <button type="submit" className="w-full items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-gray-900 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">
                    Add Review
                </button>
                <button
                onClick={handleCancel}
                 className="w-full items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-coral-red rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800">Cancel</button>    
            </form>
            </div>
            <Footer/>

</div>

)
}
