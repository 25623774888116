import { csrfFetch } from "./csrf";
const LOAD_ORDERITEMS = "orderitems/LOAD_ORDERITEMS"
const ADD_ORDERITEM = "orderitem/LOAD_ORDERITEM"

// all products
const load_orderitems = payload => ({
    type: LOAD_ORDERITEMS,
    payload
})

const add_orderitem = payload => ({
    type: ADD_ORDERITEM,
    payload
})

export const getAllOrderitems = () => async dispatch => {
    const response = await fetch(`/api/orderitem`);
    if (response.ok) {
        const payload = await response.json();

        dispatch(load_orderitems(payload));
    }
}

export const postOrderItem =(item) => async dispatch => {
    const response = await csrfFetch(`/api/orderitems/new`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify( item ),
    });
    const payload = await response.json();
    dispatch(add_orderitem(payload));
    return payload;
}

const initialState = {};
export default function orderitemsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ORDERITEMS:
            const allCartitems = {};
            action.payload.forEach(ele => allCartitems[ele.id] = ele)
            return { ...state, ...allCartitems }
        case ADD_ORDERITEM:
            return {...state, [action.payload.id]: action.payload}     
        default:
            return state;
    }
}