import { csrfFetch } from "./csrf";

const ALL_REVIEWS = "reviews/ALL_REVIEWS"
const ADD_REVIEW = "review/LOAD_REVIEW"

const DELETE_REVIEW = "review/DELETE_REVIEW"
const UPDATE_REVIEW = "review/UPDATE_REVIEW"

// all reviews
const all_reviews = payload => ({
    type: ALL_REVIEWS,
    payload
})

// add review
const add_review = payload => ({
    type:ADD_REVIEW,
    payload
})

// delete single review
const delete_review = payload => ({
    type: DELETE_REVIEW,
    payload
})

// update single review
const update_review = payload => ({
    type: UPDATE_REVIEW,
    payload
})

// Thunk

// get all reviews
export const fetchAllReviews = () => async dispatch => {
    const response = await fetch(`/api/reviews`);
    if(response.ok){
        const payload = await response.json();
        dispatch(all_reviews(payload))
        
    }
}


// get all reviews of user
// export const getAllReviews = (userId) => async dispatch => {
//     const response = await fetch(`/api/review/user/${userId}`);
//     if (response.ok){
//         const payload = await response.json();
        
//         dispatch(user_reviews(payload));
//     }
// }

// get single vendor review
// export const getVendorReview = (vendorId) => async dispatch => {
//     const response = await fetch(`/api/review/vendor/${vendorId}`)
//     if (response.ok){
//         const payload = await response.json();
//         dispatch(vendor_review(payload))
//     }
// }

// post review
export const fetchPostReview = (postReviewForm) => async dispatch => {
   
    const response = await csrfFetch('/api/reviews/new', {
        method:"POST",
        headers: { "Content-Type": "application/json",
    },
        body: JSON.stringify(postReviewForm)
    })
    if(response.ok){
        const payload = await response.json()
        dispatch(add_review(payload))

        return payload
    }
}

// delete single review
export const fetchDeleteReview = (reviewId) => async dispatch => {
    const response = await csrfFetch(`/api/reviews/delete/${reviewId}`, {
        method:"DELETE",
    });
    if (response.ok){
        dispatch(delete_review(reviewId))
        return response;
    }

}

// update single review
export const fetchUpdateReview = (reviewUpdateform, reviewId) => async dispatch => {
    
       const response = await csrfFetch(`/api/reviews/update/${reviewId}`, {
        method:"PUT",
        headers: { "Content-Type": "application/json",
    },
        body: JSON.stringify(reviewUpdateform)
       })
       if (response.ok) {
        const payload = await response.json()
        dispatch(update_review(payload))
        return payload
       }
}

// const initialState = {}

export default function reviewReducer(state = {}, action){
    switch(action.type){

        case ALL_REVIEWS:
            const allReviews = {}
            action.payload.forEach(ele => allReviews[ele.id] = ele)
           
            return {...state, ...allReviews}

        case ADD_REVIEW:
            return {...state, [action.payload.id]: action.payload}    

        case UPDATE_REVIEW:
            return {...state, [action.payload.id]: action.payload}    
           

        case DELETE_REVIEW:
            const delReview = {...state};
            delete delReview[action.payload];
            return delReview

        default: 
            return state;
    }
}